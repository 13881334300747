import { Grid } from "@mui/material";
import * as React from "react";
import ForgetPassword from "./ForgetPassword";

const Index = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "90vh" }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#4C9F6F",
          height: 60,
          position: "fixed",
          top: 0,
          zIndex: 1,
        }}
      >
        <div>
          <img
            style={{ width: 220, height: 100, margin: -20 }}
            src={require("../../../assets/logo1.png")}
            alt="logo"
          />
        </div>
      </div>
      <ForgetPassword />
    </Grid>
  );
};
export default Index;

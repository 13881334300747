import * as React from "react";
import "./style/Navbar.css";
import Colors from "../constants/Colors";
import Counter from "./Counter";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { getCartTotal } from "../ContextApi/reducer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStateValue } from "../ContextApi/StateProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import AuthContext from "../context/AuthContext";
import { commafy } from "../utils/price";
import Location from "../components/Location";
import LocContext from "../context/Locationcontext";
import { DealPrice } from "./DealPrice";
import { MenuItem, Menu, Grid, Drawer, Divider, Box } from "@mui/material";
import grabLocation from "../utils/getLocation";
import axios from "../axios";
import DashBoard from "./DashBoard";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import LogoutIcon from "@mui/icons-material/Logout";
import SignInModal from "../screens/Authentication/SignIn/SignInModal";

function useIsMounted() {
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  return isMounted;
}

const NavbarComponent = ({
  storeData,
  data,
  cat,
  anchorEl,
  mobileMoreAnchorEl,
  handleMenuClose,
  handleMobileMenuOpen,
  onMobileUi,
  handleMobileMenuClose,
}) => {
  const isMountedRef = useIsMounted();

  const [vendorGet, setVendorGet] = useState();

  const [{ cart }, dispatch] = useStateValue();

  const VendorCartId = cart[0]?.vendorId;

  const userLocation = React.useContext(LocContext);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const authUser = React.useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  let loc = userLocation.loc;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [vendor, setVendor] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const TotalValue = getCartTotal(cart);

  const roundOfValue = parseFloat(TotalValue.toFixed(2));

  const handleLocationModalEvent = (event) => {
    setIsOpen(event.detail);
  };

  // Add an event listener for the custom event
  React.useEffect(() => {
    document.addEventListener("locationModalEvent", handleLocationModalEvent);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener(
        "locationModalEvent",
        handleLocationModalEvent
      );
    };
  }, []);

  function success(pos) {
    var crd = pos.coords;

    fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${crd.latitude}&longitude=${crd.longitude}&localityLanguage=en`
    )
      .then((res1) => res1.json())
      .then((res) => {
        let l = localStorage.getItem("location");

        if (l) {
          localStorage.removeItem("location");
          userLocation.setLoc("");
        }
        let data = {
          lng: res.longitude,
          lat: res.latitude,
          area: res.locality,
        };
        localStorage.setItem("location", JSON.stringify(data));
        userLocation.setLoc(JSON.stringify(data, null, 4));
        let k = localStorage.getItem("location");

        window.location.reload();
      });
  }
  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const getLocation = () => {
    // })
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
          }
          result.onchange = function () {};
        });
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      let d = [];
      setShow(true);
      const req = await axios.get("/api/v1/vendor");
      let g = await grabLocation(userLocation?.loc, req.data.vendor);

      if (g) {
        setVendor(g);
      }
      setShow(false);
    }
    if (userLocation.loc !== "undefined" && isMountedRef.current) {
      fetchData();
    }
  }, [userLocation, isMountedRef]);

  //for checkout store info
  React.useEffect(() => {
    const fetchVendor = async () => {
      axios.get(`/api/v1/vendor/${VendorCartId}`).then((res) => {
        setVendorGet(res.data.vendorId);
      });
    };
    if (VendorCartId) {
      fetchVendor();
    }
  }, [VendorCartId]);

  const user = authUser?.user?.userName;
  const userCreated = authUser?.user?.createdAt;
  const dateObj = new Date(userCreated);
  const monthName = dateObj.toLocaleDateString("es-ES", { month: "long" });

  const year = dateObj.getUTCFullYear();
  function openModal() {
    setIsOpen(true);
  }
  React.useEffect(() => {
    let backListener = () => {
      if (!!userLocation.loc || userLocation.loc === "undefined") {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };
    if (isMountedRef.current) {
      backListener();
    }
  }, [userLocation.loc]);

  function closeModal() {
    setIsOpen(false);
  }

  const navigation = useNavigate();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const removeFromBasket = (id) => {
    dispatch({
      type: "REMOVE_FROM_CART",
      id: id,
    });
  };
  const handleLocation = (data) => {
    let l = localStorage.getItem("loaction");
    if (l) {
      localStorage.removeItem("location");
    }

    localStorage.setItem("location", JSON.stringify(data));
    userLocation.setLoc(JSON.stringify(data, null, 4));
    window.location.reload(false);
    closeModal();
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogin = (anchor) => {
    if (user) {
      navigation("/UserCheckOut", {
        state: { storeData: vendorGet, totalPrice: roundOfValue },
      });
    } else {
      setOpen(true);
      setState(false);
    }
  };

  const logout = async () => {
    await signOut(auth);
    window.localStorage.removeItem("user");
    window.location.reload("/");
  };
  const [activeTabData, setActiveTabData] = useState({});
  const [activeTab, setActiveTabs] = useState(activeTabData.text);
  const listMainMenu = (anchor) => (
    <>
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 330 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {user && (
            <>
              <div style={{ marginRight: 30 }}>
                <div
                  style={{ marginBottom: 20, fontWeight: "700", fontSize: 16 }}
                >
                  {user}.
                </div>
                <div
                  style={{ fontSize: 12, fontWeight: "600", color: "#606163" }}
                >
                  {`Cliente Basimart desde ${monthName} ${year}`}
                </div>
              </div>
            </>
          )}
        </div>
        <Divider style={{ marginBottom: 20 }} />
        <DashBoard
          activeTab={activeTab}
          setActiveTabs={setActiveTabs}
          setActiveTabData={setActiveTabData}
        />
        <Divider style={{ marginBottom: 10, marginTop: 20 }} />
        {user && (
          <>
            <div
              className="logOut"
              style={{
                cursor: "pointer",
                marginLeft: 10,
                display: "flex",
                alignItems: "center",
                width: "90%",
                borderRadius: 6,
                padding: 8,
                fontWeight: "600",
              }}
              onClick={logout}
            >
              <LogoutIcon style={{ marginRight: 10 }} />
              Salir
            </div>
            <Divider style={{ marginTop: 10 }} />
          </>
        )}
      </Box>
    </>
  );
  const listCart = (anchor) => (
    <Box
      sx={{ width: onMobileUi ? 380 : 500 }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {cart?.length <= 0 ? (
        <div style={{ height: "100vh" }}>
          <div style={Styles.emptyPersonalCartHeader}>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer(anchor, false)}
            />
            <div style={Styles.personalTextContainer}>
              <div style={Styles.storeAddress}>
                Estas comprando en +{userLocation?.loc?.area}
              </div>
            </div>
            <div />
          </div>
          <div style={Styles.emptyPersonalCartBody}>
            <div style={Styles.shopNowContainer}>
              <img
                alt="emptyCart"
                style={{ width: 230, height: 150 }}
                src={require("../assets/emptycart.png")}
              />
              <div style={Styles.personalText1}>
                Su carrito personal está vacío{" "}
              </div>
              <div
                onClick={toggleDrawer(anchor, false)}
                style={Styles.personalText2}
              >
                Compra ahora{" "}
              </div>
            </div>
          </div>
          <div style={Styles.emptyCheckOut}>
            <div />
            <div>
              <div>Finalizar compra</div>
            </div>
            <div style={Styles.emptyPrice}>
              <div>₡0.00</div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: onMobileUi ? "80vh" : "100vh" }}>
          <div style={Styles.personalCartHeader}>
            <CloseIcon
              style={{ cursor: "pointer", marginLeft: 10 }}
              onClick={toggleDrawer(anchor, false)}
            />
            <div style={Styles.personalTextContainer}>
              <div style={Styles.storeName}>Tu Carrito</div>
              <div style={Styles.storeAddress}>
                Estas comprando en +{userLocation?.loc?.area}
              </div>
            </div>
            <div />
          </div>
          <div style={Styles.personalCartBody}>
            <div style={Styles.storeInfoContainer}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  {vendorGet?.image ? (
                    <img
                      style={{
                        width: 55,
                        height: 55,
                        marginTop: 5,
                        marginBottom: 5,
                        borderRadius: "50%",
                        borderWidth: 1,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                      src={vendorGet?.image}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: 55,
                        height: 55,
                        marginTop: 5,
                        marginBottom: 5,
                        borderWidth: 1,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    />
                  )}
                </div>
                <div style={{ marginLeft: 5 }}>
                  <div style={Styles.storeTitle}>{vendorGet?.storeName}</div>
                  <div style={Styles.storeDeliveryDate}>{storeData?.date}</div>
                  <div style={Styles.storeGuarantee}>
                    100% garantía de satisfacción
                  </div>
                </div>
              </div>
              <div>
                <div style={{ fontWeight: "700", fontSize: 13 }}>
                  {/* ₡{roundOfValue} */}₡{commafy(roundOfValue)}
                </div>
              </div>
            </div>

            {cart.map((item) => {
              return (
                <div style={Styles.product}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div>
                      <img
                        alt="productImg"
                        style={Styles.productImg}
                        src={item?.images}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: 5,
                      }}
                    >
                      <div style={Styles.productTitle}>{item?.name}</div>
                      <div
                        style={{
                          fontSize: 12,
                          // fontWeight: "600",
                          color: "#AAACB1",
                        }}
                      >
                        {item?.size}
                      </div>

                      <div style={Styles.productDeliverDate}>
                        {item?.createdAt}
                      </div>

                      <div
                        onClick={() => removeFromBasket(item?.id)}
                        style={Styles.productRemove}
                      >
                        <DeleteIcon style={{ fontSize: 17, paddingTop: 1 }} />
                        <div
                          style={{
                            color: "rgb(114, 118, 126",
                            fontWeight: "600",
                            fontSize: 12,
                          }}
                        >
                          Eliminar
                        </div>
                      </div>
                      <Counter
                        counterStyle={{
                          display: "flex",
                          backgroundColor: "#52AE11",
                          justifyContent: "space-between",
                          borderRadius: 18,
                          padding: 4,
                          color: "white",
                          width: 120,
                        }}
                        id={item?.id}
                        price={item?.price}
                        date={item?.date}
                        img={item?.images}
                        title={item?.name}
                        qty={item?.qty}
                        stock={item?.stock}
                        basePrice={item?.basePrice}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: 70,
                        display: "flex",
                        flexDirection: "row-reverse",
                        fontSize: 12,
                        marginRight: 3,
                      }}
                    >
                      <DealPrice
                        price={parseFloat(item?.price).toFixed(2)}
                        deal={item.deal}
                        dealStatus={item.dealStatus}
                        compact
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            onClick={() => handleLogin(anchor, roundOfValue)}
            style={Styles.personalCartFooter}
          >
            <div />
            <div>
              <div
                style={{
                  fontSize: 16,
                  color: "white",
                  fontWeight: "600",
                  marginRight: -30,
                }}
              >
                Finalizar compra
              </div>
            </div>
            <div style={Styles.cartTotalPrice}>
              {/* ₡{roundOfValue} */}₡{commafy(roundOfValue)}
            </div>
          </div>
        </div>
      )}
    </Box>
  );
  //
  const customStyles = {
    content: {
      top: "55%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      height: "80%",
    },
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    ></Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {!user && (
        <MenuItem>
          <div
            onClick={() => {
              setOpen(true);
              setState(false);
            }}
            style={Styles.mobileLoginText}
          >
            Entrar
          </div>
        </MenuItem>
      )}
      <MenuItem>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* icon place  */}
            <div onClick={toggleDrawer(anchor, true)}>
              <div
                style={{
                  backgroundColor:
                    cart?.length > 0 ? "lightgreen" : "lightgray",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 24,
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 10,
                  paddingRight: 10,
                  cursor: "pointer",
                }}
              >
                <ShoppingCartIcon style={{ color: "#343538" }} />
                <div style={{ color: "black" }}>{cart?.length}</div>
              </div>
            </div>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {listCart(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </MenuItem>
    </Menu>
  );
  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" }, marginTop: 5 }}>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12 }}
          style={Styles.gridContainer}
        >
          <Grid style={Styles.menuContainer} item md={0.5} xs={1} xl={2}>
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                {/* icon place  */}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <MenuIcon style={{ color: "white" }} />
                </div>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {listMainMenu(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Grid>

          <Grid style={Styles.imgContainer} item md={1.5} xs={2.5} xl={2}>
            <img
              onClick={() => {
                navigation("/");
              }}
              alt="basimart"
              style={{ margin: -20, width: 200, height: 90 }}
              src={require("../assets/logo1.png")}
            />
          </Grid>
          <Grid item md={6.5} xs={4} xl={4}>
            <input
              type="text"
              id="inputID"
              style={Styles.inputText}
              placeholder="Encuentra todos los productos del super"
              onChange={(e) => {
                setSearchParams({
                  q: e.target.value,
                });
              }}
              value={searchParams.get("q") || ""}
            />
          </Grid>

          <Grid style={Styles.addressContainer} item md={2.3} xs={3} xl={2}>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => openModal()}
            >
              <div>
                <LocationOnIcon style={Styles.webcolorWhite} />
              </div>
              <div style={{ color: "white" }}>{userLocation?.loc?.area}</div>
            </div>
          </Grid>
          <Grid
            style={{ display: "flex", flexDirection: "row-reverse" }}
            md={1}
            xs={1}
            item
          >
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {!user && (
                <div
                  onClick={() => {
                    setOpen(true);
                    setState(false);
                  }}
                  style={Styles.webLoginText}
                >
                  Entrar
                </div>
              )}
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* icon place  */}
                  <div onClick={toggleDrawer(anchor, true)}>
                    <div
                      style={{
                        backgroundColor:
                          cart?.length > 0 ? "lightgreen" : "#F6F7F8",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: 24,
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 20,
                        paddingRight: 20,
                        cursor: "pointer",
                      }}
                    >
                      <ShoppingCartIcon style={{ color: "black" }} />
                      <div style={{ color: "black" }}>{cart?.length}</div>
                    </div>
                  </div>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {listCart(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ display: { xs: "flex", md: "none" }, backgroundColor: "#2D634C" }}
      >
        <Grid
          style={{ display: "flex", justifyContent: "space-between" }}
          container
          xs={12}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={2}
          >
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                {/* icon place  */}
                <div
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <MenuIcon />
                </div>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {listMainMenu(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Grid>

          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={4}
          >
            <img
              onClick={() => {
                navigation("/");
              }}
              alt="basimart"
              style={{ margin: -10, width: 200, height: 75 }}
              src={require("../assets/logo1.png")}
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={2}
          >
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                {/* icon place  */}
                <div onClick={toggleDrawer(anchor, true)}>
                  <div
                    style={{
                      backgroundColor:
                        cart?.length > 0 ? "lightgreen" : "#EFF0F1",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 24,
                      paddingTop: 6,
                      paddingBottom: 6,
                      paddingLeft: 15,
                      paddingRight: 15,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ShoppingCartIcon
                      style={{
                        color: "#343538",
                        fontSize: 16,
                      }}
                    />
                    <div style={{ color: "black", fontSize: 13 }}>
                      {cart?.length}
                    </div>
                  </div>
                </div>

                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {listCart(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10,
            }}
            item
            xs={12}
          >
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
              onClick={() => openModal()}
            >
              <div>
                <LocationOnIcon style={Styles.colorWhite} />
              </div>
              <div style={{ color: "white", fontSize: 14 }}>
                {userLocation?.loc?.area}
              </div>
              <KeyboardArrowDownIcon style={{ fontSize: 15, color: "white" }} />
            </div>
            <Modal
              // isOpen={modalIsOpen}
              // onRequestClose={closeModal}
              open={modalIsOpen}
              onClose={closeModal}
              center
              styles={{
                overlay: Styles.customOverlay,
                modal: Styles.customModal,
              }}
            >
              <div style={{ paddingBottom: 40 }}>
                <Location
                  handleTab={(e) => handleLocation(e)}
                  getLocation={getLocation}
                />
              </div>
            </Modal>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "#F1F1F1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 10,
              }}
            >
              <input
                className="input"
                type="text"
                id="inputID"
                style={Styles.inputText2}
                placeholder="Encuentra todos los productos del super"
                onChange={(e) => {
                  setSearchParams({
                    q: e.target.value,
                  });
                }}
                value={searchParams.get("q") || ""}
              />

              <SearchIcon />
            </div>
          </Grid>
          <Grid style={Styles.addressContainer} item md={2.5} xs={3} xl={2}>
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              styles={{
                modal: {
                  width: onMobileUi ? undefined : 500,
                },
              }}
              center
            >
              <SignInModal setOpen={setOpen} />
            </Modal>
          </Grid>
          <Grid
            xs={12}
            style={{ width: "100", backgroundColor: "lightgray", height: 0.5 }}
          />
        </Grid>
      </Box>
    </>
  );
};
export default React.memo(NavbarComponent);

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

const Styles = {
  mobileLoginText: {
    display: "flex",
    backgroundColor: "#52AE11",
    borderRadius: 24,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 13,
    cursor: "pointer",
    color: "white",
    fontWeight: "600",
    marginTop: 10,
  },
  mobileCartContainer: {
    backgroundColor: Colors.lightgray,
    display: "flex",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },
  gridContainer: {
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#2D634C",
    top: 0,
    position: "fixed",
    zIndex: 100,
    borderBottomWidth: 1,
  },
  menuContainer: {
    display: "flex",
    justifyContent: "center",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputText: {
    width: "100%",
    height: 45,
    borderRadius: 5,
    fontSize: 13,
    fontWeight: "600",
    paddingInlineStart: 10,
  },
  inputText2: {
    height: 45,
    borderRadius: 10,
    backgroundColor: "#F1F1F1",
    fontSize: 13,
    fontWeight: "600",
    paddingInlineStart: 10,
    width: "100%",
  },
  addressContainer: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
  },
  colorWhite: { color: "white", fontSize: 17, marginTop: -2 },
  webcolorWhite: { color: "white", fontSize: 20, marginTop: -5 },

  webCartContainer: {
    backgroundColor: Colors.lightgray,
    display: "flex",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },

  webLoginText: {
    display: "flex",
    backgroundColor: "#52AE11",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    cursor: "pointer",
    color: "white",
    marginLeft: -100,
    position: "absolute",
  },
  emptyPersonalCartBody: {
    backgroundColor: "#F6F7F8",
    height: "78vh",
    display: "flex",
    justifyContent: "center",
  },
  personalTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyPersonalCartHeader: {
    height: "11vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  personalText1: { color: "#72767E", fontSize: 16, fontWeight: "600" },
  personalText2: {
    color: "#58B42F",
    fontSize: 16,
    fontWeight: "600",
    cursor: "pointer",
  },
  emptyCheckOut: {
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F6F7F8",
    margin: 5,
    alignItems: "center",
    borderRadius: 10,
  },
  emptyPrice: {
    backgroundColor: "lightgray ",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
  },

  personalCartHeader: {
    height: "10vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    width: "100%",
    position: "sticky",
    borderBottomWidth: 1,
  },
  personalCartBody: {
    backgroundColor: "#F6F7F8",
    height: "77vh",
    overflowY: "scroll",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
  },
  personalCartFooter: {
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#52AE11",
    margin: 5,
    alignItems: "center",
    borderRadius: 10,
    cursor: "pointer",
    position: "absolute",
    width: "96%",
    // bottom: 0,
  },
  storeName: {
    fontSize: 14,
    fontWeight: "600",
    color: "black",
    marginLeft: -35,
  },
  storeAddress: {
    fontSize: 12,
    fontWeight: "600",
    color: "gray",
    marginLeft: -35,
  },
  shopNowContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 50,
  },
  cartTotalPrice: {
    backgroundColor: "#418B24",
    color: "white",
    fontWeight: "600",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
  },
  storeInfoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    backgroundColor: "white",
    padding: 5,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    marginBottom: 10,
  },
  storeImg: { width: 70, height: 70, borderRadius: 50 },
  storeTitle: { fontWeight: "600", color: "black", fontSize: 14 },
  storeDeliveryDate: {
    color: "rgb(10, 173, 10)",
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 2,
  },
  storeGuarantee: {
    fontWeight: "600",
    color: "rgb(43, 120, 198)",
    fontSize: 12,

    cursor: "pointer",
    marginLeft: 2,
  },
  firstDeliveryContainer: {
    display: "flex",
    position: "relative",
    backgroundColor: "rgb(250, 241, 229)",
    marginTop: 10,
  },
  firstDeliveryText: {
    fontSize: 15,
    fontWeight: "600",
    color: " rgb(117, 0, 70)",
    padding: 8,
  },
  product: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    padding: 5,
  },
  productImg: { width: 80, height: 55 },
  productTitle: {
    color: "rgb(52, 53, 56)",
    width: 230,
    whiteSpace: "pre-line",
    fontSize: 14,
  },
  productDeliverDate: {
    color: "rgb(10, 173, 10)",
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 2,
  },
  productRemove: {
    cursor: "pointer",
    marginLeft: 2,
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  customOverlay: {
    // background: "rgba(36, 123, 160, 0.7)"
    borderRadius: 10,
  },
  customModal: {
    borderRadius: 10,
    width: 340,
  },
};

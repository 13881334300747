import React from "react";
import { Box } from "@mui/material";
//@ts-ignore
import UserDeliveryTab from "../../../user/UserDeliveryTab";
import Modal from "react-responsive-modal";
import useScreenSize from "../../../hooks/useScreenSize";

const DeliveryModal = ({ store, open, onClose }: any) => {
  const { isMobile } = useScreenSize();
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{
        overlay: Styles.customOverlay,
        modal: {
          ...Styles.customModal,
          width: isMobile ? "80%" : 500,
        },
        root: Styles.customRoot,
      }}
    >
      <Box>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <div
              style={{
                fontSize: 18,
                fontWeight: "600",
                color: "#53B02C",
                paddingBottom: 10,
              }}
            >
              Tiempos de entrega
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={store?.image}
              style={{ width: 80, height: 80, borderRadius: "50%" }}
              alt=""
            />
            <div>{store?.storeName}</div>
          </div>
          <div style={{}}>
            <UserDeliveryTab data={store} widthMax={true} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeliveryModal;

const Styles = {
  customOverlay: {
    // background: "rgba(36, 123, 160, 0.7)"
    borderRadius: 10,
  },
  customModal: {
    borderRadius: 10,
  },
  customRoot: {
    zIndex: 10000000,
  },
};

export const basiCardStyle = {
  cardContainer: {
    backgroundColor: "#003d28",
    background:
      "linear-gradient(184deg, rgba(0,61,40,1) 28%, rgba(11,105,72,1) 65%, rgba(0,61,40,1) 100%)",
    height: "165px",
    width: "300px",
    borderRadius: "10px",
    padding: "15px",
    margin: "auto",
    marginTop: "15px",
    color: "white",
  },
  cardContainerMobile: {
    backgroundColor: "#003d28",
    background:
      "linear-gradient(184deg, rgba(0,61,40,1) 28%, rgba(11,105,72,1) 65%, rgba(0,61,40,1) 100%)",
    height: "160px",
    width: "260px",
    borderRadius: "10px",
    padding: "15px",
    margin: "auto",
    color: "white",
  },
  tittle: {
    fontSize: "14px",
  },
  bigTittle: {
    fontSize: "17px",
    fontWeight: "bold",
  },
  addIcon: {
    fontSize: "40px",
    marginTop: "5px",
    color: "white",
    marginLeft: "-25px",
  },
  confirmButton: {
    fontSize: "12px",
    marginTop: "40px",
    borderRadius: "5px",
    color: "white",
    padding: "12px",
    fontWeight: "bold",
  },
  continueButton: {
    fontSize: "12px",
    marginTop: "10px",
    borderRadius: "5px",
    color: "white",
    padding: "12px",
    fontWeight: "bold",
  },
  continueButtonMobile: {
    fontSize: "12px",
    marginTop: "20px",
    borderRadius: "5px",
    color: "white",
    padding: "12px",
    fontWeight: "bold",
  },
  box: {
    display: "flex",
    alignItems: "stretch",
    marginTop: "10px",
  },
  instructions: {
    width: "360px",
    height: "180px",
  },
  instructionsMobile: {
    width: "240px",
    height: "265px",
  },
  add: { width: "300px", height: "140px" },
  addMobile: { width: "240px", height: "200px" },
};

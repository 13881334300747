import { Box, Grid } from "@mui/material";
import React from "react";
//@ts-ignore
import loadingIcon from "../../assets/Basimart_Loader.gif";

export default function ScreenLoader() {
  return (
    <Grid item md={10} lg={10} sm={12} xs={12}>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            width: "100vw",
            height: "100vh",
            alignContent: "center",
            justifyItems: "center",
            paddingTop: 20,
            justifySelf: "center",
          }}
        >
          <img src={loadingIcon} height={500} width={350} alt="loading" />
        </div>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            width: "100vw",
            height: "50vh",
            alignContent: "center",
            justifyItems: "center",
            justifySelf: "center",
          }}
        >
          <img src={loadingIcon} height={500} width={350} alt="loading" />
        </div>
      </Box>
    </Grid>
  );
}

import { React, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useStateValue } from "../../ContextApi/StateProvider";
import RemoveIcon from "@mui/icons-material/Remove";

export default function ModalCounter({
  currentItem,
  storeId,
  counterStyle,
  dealApplied,
  handleClose,
}) {
  const [counter, setCounter] = useState(1);
  const [{ cart }, dispatch] = useStateValue();
  const existItem = cart.find((x) => x.id === currentItem._id?.concat(storeId));
  const [qty, setQty] = useState(existItem?.qty ? existItem?.qty : 1);
  const [addCount, setAddCout] = useState(0);
  const TYPE = "ADD_TO_CART";

  const addToCart = () => {
    try {
      if (qty <= currentItem?.stock && qty > 0) {
        dispatch({
          type: TYPE,
          item: {
            _id: currentItem?._id,
            pId: currentItem?.pId,
            id: currentItem?._id.concat(storeId),
            name: currentItem?.name,
            images: currentItem?.images,
            date: currentItem?.date,
            price: currentItem?.price * Number(qty),
            stock: currentItem?.stock,
            size: currentItem?.size,
            description: currentItem?.description,
            basePrice: currentItem?.price,
            qty: Number(qty),
            vendorId: storeId,
            deal:
              dealApplied === currentItem?.deal?.type
                ? currentItem?.deal
                : undefined,
            dealApplied,
            dealStatus: currentItem.dealStatus,
          },
        });
      }
      setAddCout(addCount + 1);
    } catch (error) {
      console.error(error);
    }
  };

  const removeFromBasket = () => {
    dispatch({
      type: "REMOVE_FROM_CART",
      id: currentItem._id.concat(storeId),
    });
  };
  const IncreaseQty = (id) => {
    if (qty < currentItem.stock) {
      setQty(qty + 1);
    }
  };
  const DecreaseQty = (id) => {
    if (existItem ? qty >= 1 : qty >= 2) {
      setQty(qty - 1);
    }
  };

  const handleChange = (id) => {
    if (counter >= 2) {
      setCounter((count) => count - 1);
    } else {
      removeFromBasket(id);
    }
  };

  return (
    <>
      <div style={counterStyle}>
        <div style={{ color: "black" }} onClick={() => DecreaseQty()}>
          <RemoveIcon />
        </div>
        <span style={{ color: "black" }}>{qty}</span>
        <div style={{ color: "black" }} onClick={() => IncreaseQty()}>
          <AddIcon />
        </div>
      </div>
      {qty === existItem?.qty && (
        <div
          onClick={addToCart}
          style={{
            display: "flex",
            backgroundColor: "#F6F7F8",
            borderRadius: 10,
            padding: 8,
            color: "#C7C8CD",
            width: "49%",
            justifyContent: "center",
          }}
        >
          agregado
        </div>
      )}
      {qty !== existItem?.qty && qty > 0 && existItem && (
        <div
          onClick={addToCart}
          style={{
            display: "flex",
            backgroundColor: "#499B0F",
            borderRadius: 10,
            padding: 8,
            color: "white",
            width: "49%",
            justifyContent: "center",
            fontWeight: "600",
            fontSize: 13,
            alignItems: "center",
          }}
        >
          Actualizar Cantidad
        </div>
      )}
      {!existItem && qty !== 0 && (
        <div
          onClick={addToCart}
          style={{
            display: "flex",
            backgroundColor: "#499B0F",
            borderRadius: 10,
            padding: 8,
            color: "white",
            width: "49%",
            justifyContent: "center",
            fontWeight: "700",
            fontSize: 13,
            alignItems: "center",
          }}
        >
          Agregar al carrito
        </div>
      )}
      {qty === 0 && (
        <div
          onClick={removeFromBasket}
          style={{
            display: "flex",
            backgroundColor: "#499B0F",
            borderRadius: 10,
            padding: 8,
            color: "white",
            width: "49%",
            fontSize: 13,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Quitar del carrito
        </div>
      )}
    </>
  );
}

import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Stories from "stories-react";
import "stories-react/dist/index.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import clsx from "clsx";
import Modal from "react-responsive-modal";
import AuthContext from "../../context/AuthContext";
import { Product } from "../../types";
import useScreenSize from "../../hooks/useScreenSize";
import axios from "../../axios";
import { useStateValue } from "../../ContextApi/StateProvider";
import { DealPrice } from "../../components/DealPrice";
import { useNavigate, useParams } from "react-router-dom";
import ScreenLoader from "../../components/screenLoader/ScreenLoader";

type Story = {
  name: string;
  type: "offer" | "basimax" | "aisle";
  products: Product[];
};

const storyImages = {
  basimax: "story-basimax.png",
  offer: "story-offer.png",
  FRUTAS: "story-fruits.png",
  VERDURAS: "story-veg.png",
};

export default function StoryProducts({ store, updateHeight }: any) {
  const { user } = useContext(AuthContext);
  const { isMobile } = useScreenSize();
  const [stories, setStories] = useState<Story[]>([]);
  const [selectedStory, setSelectedStory] = useState<Story | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);

  const getStories = useCallback(async () => {
    if (!store) return;

    try {
      const { data } = await axios.post("/api/v1/products/stories", {
        vendorId: store?.storeName,
        userId: user?._id,
      });
      setStories(data.stories);
      if (!isMobile && data.stories?.length) {
        setSelectedStory(data.stories[0]);
      }
      if (updateHeight) {
        setTimeout(() => {
          updateHeight();
        }, 100);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }, [store, user?._id, isMobile, updateHeight]);

  useEffect(() => {
    getStories();
  }, [getStories]);

  if (!isMobile && loading) {
    return <ScreenLoader />;
  }

  if (isMobile && stories.length === 0) {
    return null;
  }

  return (
    <ResponsiveLayout isMobile={isMobile}>
      {stories.length === 0 && <p>No se encontraron historias</p>}
      <RenderStories
        isMobile={isMobile}
        stories={stories}
        selectedStory={selectedStory}
        setSelectedStory={setSelectedStory}
      />
      {isMobile && selectedStory ? (
        <Modal
          open={true}
          center
          classNames={{
            overlay: "fixed inset-0 bg-black opacity-50",
            modal: "w-[90%] overflow-hidden",
          }}
          onClose={() => setSelectedStory(undefined)}
        >
          <RenderStories
            isMobile={isMobile}
            stories={stories}
            selectedStory={selectedStory}
            setSelectedStory={setSelectedStory}
          />
          <StoryModal
            key={selectedStory.name}
            store={store}
            story={selectedStory}
            onClose={() => setSelectedStory(undefined)}
          />
        </Modal>
      ) : (
        <>
          {selectedStory && (
            <StoryModal
              key={selectedStory.name}
              store={store}
              story={selectedStory}
              onClose={() => setSelectedStory(undefined)}
            />
          )}
        </>
      )}
    </ResponsiveLayout>
  );
}

const ResponsiveLayout = ({ children, isMobile }: any) => {
  const { storeName } = useParams();
  const navigation = useNavigate();

  if (isMobile) return <div>{children}</div>;
  return (
    <Modal
      open={true}
      center
      classNames={{
        overlay: "fixed inset-0 bg-black opacity-50",
        modal: "bg-white w-[30vw] h-[80vh] overflow-hidden",
      }}
      onClose={() => navigation(`/tiendas/${storeName}`)}
    >
      {children}
    </Modal>
  );
};

const StoryModal = ({
  store,
  story,
}: {
  store: any;
  story: Story;
  onClose: () => void;
}) => {
  const [storyIndex, setStoryIndex] = useState(0);

  const stories = story.products.map((f) => ({
    key: f._id,
    product: f,
    store,
    type: "component",
    component: RenderStory,
    duration: 5000,
  }));

  return (
    <Stories
      currentIndex={storyIndex}
      onStoryChange={(index: number) => {
        setStoryIndex(index);
      }}
      className="mt-4"
      style={{ height: "unset" }}
      classNames={{
        main: "!bg-white !h-[unset]",
      }}
      stories={stories}
    />
  );
};

function RenderStories({
  isMobile,
  stories,
  selectedStory,
  setSelectedStory,
}: {
  isMobile: boolean;
  stories: Story[];
  selectedStory: Story | undefined;
  setSelectedStory: Dispatch<React.SetStateAction<Story | undefined>>;
}) {
  const getImage = (name: string) => {
    try {
      return require(`../../assets/${storyImages[name as never]}`);
    } catch (error) {
      return null;
    }
  };

  return (
    <div className="mb-2 flex gap-2 overflow-y-hidden overflow-x-auto">
      {stories.map((story) => (
        <div
          key={story.name}
          onClick={() => setSelectedStory(story)}
          className={clsx("cursor-pointer rounded-full p-[3px]", {
            "w-16 h-16": !isMobile,
            "w-12 h-12": isMobile,
            "bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500":
              selectedStory?.name !== story.name,
            "bg-[#52AE11]": selectedStory?.name === story.name,
          })}
        >
          <div className="flex h-full w-full rounded-full items-center justify-center bg-white back">
            <img width={50} src={getImage(story.name)} alt={story.name} />
          </div>
        </div>
      ))}
    </div>
  );
}

function RenderStory({ story: { store, product }, isMobile }: any) {
  const [qty, setQty] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dispatch] = useStateValue();

  const addToCart = () => {
    try {
      dispatch({
        type: "ADD_TO_CART",
        item: {
          id: product._id.concat(store._id),
          ...product,
          price: product.price * qty,
          basePrice: product.price,
          qty,
          vendorId: store._id,
          dealApplied: product?.deal?.type,
        },
      });
      localStorage.setItem("cart", JSON.stringify(product, null, 4));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // const existItem = cart?.find(
    //   (item: any) => item?.id === product._id.concat(store._id)
    // ) as any;
    // if (existItem?.qty) {
    //   setQty(existItem?.qty);
    // } else {
    //   setQty(1);
    // }
  }, []);

  return (
    <div className="bg-white h-full mt-2 relative">
      <div className="">
        <div className="flex justify-center w-full">
          <img
            className="w-64 h-full object-contain"
            src={product?.images}
            alt=""
          />
        </div>
        <div className="mt-2">
          <h4 className="font-semibold text-xl">{product?.name}</h4>
          <div
            style={{
              fontWeight: "700",
              color: "rgb(52, 53, 56)",
              fontSize: 18,
            }}
          >
            <DealPrice
              price={product?.price}
              deal={product?.deal}
              dealStatus={product?.dealStatus}
            />
          </div>
          <p className="text-sm text-[#606163]">{product?.size}</p>
          <p className="text-sm">{product?.description}</p>
        </div>
        <div className="my-5 flex justify-center gap-3 z-[2] relative">
          <div className="w-1/2 flex justify-between items-center px-2 bg-gray-100 rounded-full">
            <button disabled={qty <= 1} onClick={() => setQty(qty - 1)}>
              <RemoveIcon />
            </button>
            <span>{Number(qty)}</span>
            <button
              disabled={qty >= product.stock}
              onClick={() => setQty(qty + 1)}
            >
              <AddIcon />
            </button>
          </div>
          <div className="w-1/2">
            <button
              onClick={() => addToCart()}
              className="w-full rounded p-2 bg-[#52AE11] text-white text-sm font-medium"
            >
              Agregar al carrito
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

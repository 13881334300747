import React, { createContext, useContext, useReducer } from "react";
import { PriceChop, Product } from "../types";
//Preparse the data layout

type State = {
  priceChops?: PriceChop[];
  cart?: Product[];
  store?: { storeName: string };
};

export const StateContext = createContext<[State, (action: any) => void]>([
  {},
  () => {},
]);

export const StateProvider = ({ reducer, initialState, children }: any) => (
  <StateContext.Provider value={useReducer(reducer, initialState) as any}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);

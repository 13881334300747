import React, { useContext, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Divider, Drawer } from "@mui/material";
import AuthContext from "../../../context/AuthContext";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";

export default function MainMenuDrawer({
  children,
}: {
  children?: React.ReactNode;
}) {
  const navigate = useNavigate();
  const { storeName } = useParams<{
    storeName?: string;
  }>();
  const [open, setOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [activeTabData, setActiveTabData] = useState<any>({});
  const [activeTab, setActiveTabs] = useState(activeTabData?.text);

  const getUserCreateDate = () => {
    const userCreated = user?.createdAt as any;
    const dateObj = new Date(userCreated);
    const monthName = dateObj.toLocaleString("es-ES", { month: "long" });
    const year = dateObj.getUTCFullYear();
    return `${monthName} ${year}`;
  };

  const onLogout = async () => {
    await signOut(auth);
    window.localStorage.removeItem("user");

    if (storeName) {
      navigate(`/tiendas/${storeName}`);
    } else {
      navigate("/");
    }
    window.location.reload();
  };

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
        <MenuIcon style={{ color: "black" }} />
      </div>
      <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
        <Box sx={{ width: 330 }} role="presentation">
          {children}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {user?._id && (
              <>
                <div style={{ marginRight: 30 }}>
                  <div
                    style={{
                      marginBottom: 20,
                      fontWeight: "700",
                      fontSize: 18,
                    }}
                  >
                    {user.userName}.
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: "600",
                      color: "#606163",
                    }}
                  >
                    {`Cliente Basimart desde ${getUserCreateDate()}`}
                  </div>
                </div>
              </>
            )}
          </div>
          <Divider style={{ marginBottom: 20 }} />

          <div>
            <HeaderButton
              text="Tiendas"
              btnColor="black"
              textColor="white"
              activeTab={activeTab}
              setActiveTabs={setActiveTabs}
              setActiveTabData={setActiveTabData}
              img={require("../../../assets/shop.png")}
            />
            {user && (
              <HeaderButton
                text="Tus órdenes"
                btnColor="white"
                textColor="black"
                activeTab={activeTab}
                setActiveTabs={setActiveTabs}
                setActiveTabData={setActiveTabData}
                img={require("../../../assets/deals.png")}
              />
            )}
            {user && (
              <HeaderButton
                text="Configuración de tu cuenta"
                btnColor="white"
                textColor="black"
                activeTab={activeTab}
                setActiveTabs={setActiveTabs}
                setActiveTabData={setActiveTabData}
                img={require("../../../assets/retry.png")}
              />
            )}
            {!user && (
              <HeaderButton
                text="Entrar"
                btnColor="white"
                textColor="black"
                activeTab={activeTab}
                setActiveTabs={setActiveTabs}
                setActiveTabData={setActiveTabData}
                icon={<LoginIcon />}
              />
            )}
          </div>

          <Divider style={{ marginBottom: 10, marginTop: 20 }} />
          {user && (
            <>
              <div
                className="logOut"
                style={{
                  cursor: "pointer",
                  marginLeft: 10,
                  display: "flex",
                  alignItems: "center",
                  width: "90%",
                  borderRadius: 6,
                  padding: 8,
                  fontWeight: "600",
                }}
                onClick={onLogout}
              >
                <LogoutIcon style={{ marginRight: 10 }} /> Salir
              </div>
              <Divider style={{ marginTop: 10 }} />
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
}

const HeaderButton = (props: any) => {
  const navigation = useNavigate();
  const handleChange = (props: any) => {
    props.setActiveTabs(props.text);
    props.setActiveTabData(props);
    if (props.text === "Tiendas") {
      navigation("/");

      props.setActiveTabData(props);
    } else if (props.text === "Tus órdenes") {
      navigation("/UserAllOrders");
      props.setActiveTabs(props.text);
      props.setActiveTabData(props);
    } else if (props.text === "Entrar") {
      navigation("/Login");
      props.setActiveTabs(props.text);
      props.setActiveTabData(props);
    } else {
      navigation("/UserAccountSetting");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: props.activeTab === props.text ? "#343538" : "white",
        marginLeft: 9,
        marginRight: 9,
        paddingLeft: 15,
        paddingTop: 9,
        paddingBottom: 9,
        borderRadius: 6,
        marginBottom: 2,
        width: "90%",
        cursor: "pointer",
      }}
      onClick={() => {
        handleChange(props);
      }}
    >
      <div style={{ marginRight: 5 }}>
        {props.img && (
          <img
            style={{
              width: 20,
              height: 20,
            }}
            src={props.img}
            alt=""
          />
        )}
        <div style={{ marginLeft: -4 }}>{props.icon}</div>
      </div>
      <div
        style={{
          color: props.activeTab === props.text ? "white" : "black",
          fontSize: 14,
          fontWeight: "900",
        }}
      >
        {props.text}
      </div>
    </div>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';
import axios from "../../axios";

const initialState = {
  data: [],
  dataDetail: [],
  vendorProductId: [],
  isLoading: true,
  isSuccess: false,
  isError: false,
  message: "",
  filters: {},
  isBaseMeScreen: false,
  exploreScreen: true,
  bitesScreen: false,
};

export const storeProduct = createAsyncThunk(
  "storeProduct/storeProduct",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post("/api/v1/product/getVendorProduct", {
        ...data,
      });
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const storeProductSlice = createSlice({
  name: "storeProduct",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    setIsBaseMeScreen: (state) => {
      state.exploreScreen = false;
      state.bitesScreen = false;
      state.isBaseMeScreen = true;
      state.data = [];
      state.filters = {};
    },
    setExploreScreen: (state) => {
      state.isBaseMeScreen = false;
      state.bitesScreen = false;
      state.exploreScreen = true;
      state.data = [];
      state.filters = {};
    },
    setBitesScreen: (state) => {
      state.bitesScreen = true;
      state.isBaseMeScreen = false;
      state.exploreScreen = false;
      state.data = [];
      state.filters = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // store
      .addCase(storeProduct.pending, (state, action) => {
        const {
          meta: {
            arg: { data },
          },
        } = action;
        state.isLoading = true;
        state.data = [];
        state.filters = data;
        state.isBaseMeScreen = false;
        state.exploreScreen = false;
        state.bitesScreen = false;
      })
      .addCase(storeProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
        state.vendorProductId = action.payload;
      })
      .addCase(storeProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.data = [];
      });
  },
});

export const { reset, setIsBaseMeScreen, setExploreScreen, setBitesScreen } =
  storeProductSlice.actions;
export default storeProductSlice.reducer;

import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from "react";
import { Balance } from "../types";
import axios from "../axios";
import AuthContext from "./AuthContext";

type Context = {
  fetchBalance: () => Promise<void>;
} & Balance;

const BalanceContext = createContext<Context>({
  points: 0,
  balance: 0,
  pending: 0,
  fetchBalance: () => Promise.resolve(),
});

export const BalanceProvider: FC = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [pending, setPending] = useState(0);
  const [balance, setBalance] = useState(0);
  const [points, setPoints] = useState(0);

  const fetchBalance = useCallback(async () => {
    await axios
      .get(`/api/v1/basiCard/get-balance/${user?._id}`)
      .then((res) => {
        setPending(res.data.pending);
        setBalance(res.data.balance);
        setPoints(res.data.points);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user?._id]);

  return (
    <BalanceContext.Provider value={{ pending, balance, points, fetchBalance }}>
      {children}
    </BalanceContext.Provider>
  );
};

export const useBalance = () => {
  const ctx = useContext(BalanceContext);
  return ctx;
};

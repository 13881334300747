import React, { FC } from "react";
import { ModalProvider } from "styled-react-modal";
import useIsMobile from "../../hooks/useIsMobile";
import { Product } from "../../types";
//@ts-ignore
import ProductDetailModalDesktop from "./ProductDetailModal";
//@ts-ignore
import ProductDetailModalMobile from "./ProductDetailModalMobile";

type Props = {
  open: boolean;
  currentItem: Product;
  storeId: string;
  onClose: () => void;
};

const ProductDetailModal: FC<Props> = (props) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <ModalProvider>
      <ProductDetailModalMobile {...props} />
    </ModalProvider>
  ) : (
    <ProductDetailModalDesktop {...props} />
  );
};

export default ProductDetailModal;

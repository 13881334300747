import React, { FC } from "react";
import "../../components/style/UserProfile.css";
import DailyCheckIn from "./components/DailyCheckIn";
import BasiCard from "./components/BasiCard";
import WheelOfDiscount from "./components/WheelOfDiscount";
import PriceChopProducts from "./components/PriceChopProducts";
import { Grid } from "@mui/material";

const MyBasi: FC = () => {
  return (
    <Grid
      style={{ backgroundColor: "rgb(245 245 245)", padding: "10px" }}
      container
      md={10}
      lg={10}
      sm={12}
      xs={12}
    >
      <Grid sm={12} md={6} padding={1} width={"100%"}>
        <div style={Styles.container}>
          <DailyCheckIn />
        </div>
      </Grid>

      <Grid sm={12} md={6} padding={1} width={"100%"}>
        <div style={Styles.container}>
          <Grid xs={12}>
            <BasiCard />
          </Grid>
        </div>
      </Grid>

      <Grid xs={12} md={6} padding={1} width={"100%"}>
        <div style={Styles.bigContainer}>
          <PriceChopProducts />
        </div>
      </Grid>

      <Grid xs={12} md={6} padding={1} width={"100%"}>
        <div style={Styles.bigContainer}>
          <WheelOfDiscount />
        </div>
      </Grid>
    </Grid>
  );
};

export default MyBasi;

const Styles = {
  container: {
    backgroundColor: "white",
    height: "260px",
    borderRadius: "10px",
    padding: "3px",
  },
  bigContainer: {
    backgroundColor: "white",
    height: "460px",
    borderRadius: "10px",
    padding: "3px",
  },
};

import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
//@ts-ignore
import loadingIcon from "../../assets/Basimart_Loader.gif";
//@ts-ignore
import LocContext from "../../context/Locationcontext";
import axios from "../../axios";
//@ts-ignore
import grabLocation from "../../utils/getLocation";
import Colors from "../../constants/Colors";

export default function Home() {
  const [searchParams] = useSearchParams();
  const searchStore = searchParams.get("q");
  const navigate = useNavigate();
  const userLocation = useContext<any>(LocContext);
  const [show, setShow] = useState(true);

  const [vendor, setVendor] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setShow(true);
      //@ts-ignore
      if (userLocation?.loc) {
        const req = await axios.post("/api/v1/vendor/getVendorsForDoc");
        let g = await grabLocation(userLocation.loc, req.data.vendor);

        if (g) {
          setVendor(g);
          setShow(false);
        }
      }
    }
    fetchData();
  }, [userLocation]);

  const openLocationModal = () => {
    const customEvent = new CustomEvent("locationModalEvent", {
      detail: true,
    });
    document.dispatchEvent(customEvent);
  };

  return (
    <Box display={"flex"} justifyContent="center">
      <Grid
        container
        spacing={0}
        md={11}
        xs={12}
        sm={8}
        sx={{
          marginTop: 5,
          // marginLeft: 50
        }}
      >
        {show ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              alignSelf: "center",
              alignContent: "center",
            }}
          >
            <img src={loadingIcon} height={500} width={350} alt="" />
          </div>
        ) : (
          <>
            {vendor.length !== 0 && !show ? (
              vendor
                ?.filter((f: any) => {
                  if (searchStore) {
                    return f.storeName
                      .toLowerCase()
                      .includes(searchStore.toLowerCase());
                  }
                  return true;
                })
                ?.map((item: any) => (
                  <>
                    <Grid
                      key={item._id}
                      item
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      lg={3}
                      md={4}
                    >
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          localStorage.setItem("currentStore", item.storeName);
                          navigate(`/tiendas/${item.storeName}`);
                        }}
                        to={`/tiendas/${item.storeName}`}
                        style={{
                          display: "flex",
                          marginBottom: 20,
                          margin: 10,
                          boxShadow: "3px 3px 10px 3px rgba(0, 0, 0, 0.06)",
                          padding: 10,
                          borderRadius: 10,
                        }}
                      >
                        <div>
                          {item?.image ? (
                            <img
                              style={Styles.imgContainer}
                              src={item?.image}
                              alt=""
                            />
                          ) : (
                            <Avatar style={Styles.imgContainer} />
                          )}
                        </div>
                        <div style={{ marginLeft: 3 }}>
                          <div style={Styles.titleContainer as any}>
                            {item.storeName}
                          </div>
                          <div style={Styles.distanceContainer}>
                            {(item?.distance * 1000).toFixed(0)}m de tu
                            ubicación
                          </div>
                        </div>
                      </Link>
                    </Grid>
                  </>
                ))
            ) : (
              <>
                {vendor.length === 0 && !show && (
                  <div className="flex items-center flex-col w-full">
                    <p
                      style={{
                        fontSize: 18,
                        color: Colors.lightGreen,
                        fontWeight: "bold",
                      }}
                      className="p-4"
                    >
                      No hay tiendas cerca de tu ubicación actual; intenta
                      cambiando tu ubicación.
                    </p>
                    <Button
                      onClick={openLocationModal}
                      variant="outlined"
                      className="!rounded-full"
                    >
                      Seleccionar ubicación
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}

const Styles = {
  GridContainer: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
    justifyItems: "center",
    border: "1px solid black",
    // width: "50%"
  },
  mainContainer: {
    boxShadow: "3px 3px 10px 3px rgba(0, 0, 0, 0.06)",
  },
  distanceContainer: {
    fontSize: 12,
    fontWeight: "600",
    maxWidth: 170,
    minWidth: 100,
    height: 20,
    marginLeft: 20,

    color: Colors.lightGreen,
  },
  textImgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "20%",
    // backgroundColor: Colors.seeGreen,
    // marginLeft: 10,
    // marginRight: 10,
    // marginBottom: 10,
    // borderRadius: "50%",
    borderWidth: 1,
    // height: 230
  },
  titleContainer: {
    fontSize: 14,
    fontWeight: "600",
    maxWidth: 170,
    minWidth: 100,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    textAlign: "center",
  },
  dateContainer: { color: "green", fontWeight: "600" },
  desContainer: { fontSize: 12, fontWeight: "600" },
  imgContainer: {
    width: 70,
    height: 70,
    marginBottom: 5,
    borderRadius: "50%",
  },
};

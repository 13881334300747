import React, { FC, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Wheel } from "react-custom-roulette";
import Colors from "../../../../constants/Colors";
import axios from "../../../../axios";
import "./component.css";
import { Settings } from "../../../../types";
import AuthContext from "../../../../context/AuthContext";
import { commafy } from "../../../../utils/price";
import { useBalance } from "../../../../context/BalanceContext";
import InfoModal from "../InfoModal";

const availableColors = [
  Colors.buttonGreen,
  Colors.lightGreen,
  Colors.seaGreen,
  Colors.skyBlue,
  Colors.darkBlue,
];

const WheelOfDiscount: FC = () => {
  const authUser = useContext(AuthContext);
  const { fetchBalance } = useBalance();
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState<number>();
  const [settings, setSettings] = useState<Settings>();
  const [run, setRun] = useState(true);

  useEffect(() => {
    axios.get(`/api/v1/admin/settings`).then((res) => {
      setSettings(res.data.settings);
    });
  }, []);

  const data = settings?.prizes.map((m) => ({ option: commafy(m) })) || [];

  const getPrize = () => {
    setRun(true);
    axios
      .get(`/api/v1/user/prize/${authUser?.user?._id}`)
      .then((res) => {
        if (res.data.success) {
          const prize = settings?.prizes?.indexOf(res.data.prize.value);
          if (prize === undefined) {
            setErrorCode("unknown");
            return;
          }
          setMustSpin(true);
          setPrizeNumber(prize);
          setErrorCode("");
          setRun(true);
        } else {
          setErrorCode(res.data.code);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSpinClick = () => {
    setLoading(true);
    getPrize();
  };

  useEffect(() => {
    const prize = () => {
      axios
        .post(`api/v1/allOrdersDetail/buyer`, {
          buyerId: `${authUser?.user?._id}`,
        })
        .then((res) => {
          const { buyerOrderDetails = [] } = res.data || {};
          axios
            .get(`/api/v1/user/lastWheel/${authUser?.user?._id}`)
            .then((res) => {
              const { minimumPrizePurchases, lastWheel } = res.data;
              if (res.data.success) {
                if (
                  minimumPrizePurchases > 0 &&
                  buyerOrderDetails.length - lastWheel >= minimumPrizePurchases
                ) {
                  setRun(false);
                } else {
                  setRun(true);
                }
              }
            });
        });
    };
    prize();
  }, [authUser?.user?._id]);

  useEffect(() => {
    if (prizeNumber !== undefined && !mustSpin) {
      fetchBalance();
    }
  }, [fetchBalance, mustSpin, prizeNumber]);

  return (
    <Box className="relative">
      <h1 style={{ paddingTop: "5px", paddingLeft: "5px" }}>
        Prueba tu suerte
      </h1>
      <Typography component="p" color="gray" variant="caption">
        Cada {settings?.minimumPrizePurchases} compras que realices tendrás el
        chance de girar la rueda y ganar premios.
      </Typography>
      <Box
        className="flex flex-col"
        alignItems="center"
        justifyContent="center"
        padding={4}
      >
        <Box className="wheelContainer mb-2">
          <Wheel
            mustStartSpinning={mustSpin && prizeNumber !== undefined}
            prizeNumber={prizeNumber || 0}
            data={data}
            backgroundColors={Array(data?.length)
              .fill(0)
              .map(
                (_, i) =>
                  availableColors[
                    ((i % availableColors?.length) + availableColors?.length) %
                      availableColors?.length
                  ]
              )}
            textColors={["white"]}
            radiusLineColor="lightgray"
            innerBorderColor="lightgray"
            outerBorderColor="lightgray"
            onStopSpinning={() => {
              setMustSpin(false);
              setRun(true);
            }}
          />
        </Box>
        <LoadingButton
          variant="contained"
          disabled={run}
          loading={loading}
          style={{
            background: run ? "#f0f0f0" : "green",
            color: "white",
            borderRadius: "10px",
          }}
          onClick={handleSpinClick}
        >
          Girar
        </LoadingButton>
        {prizeNumber !== undefined && !mustSpin && (
          <Typography marginTop={1} textAlign="center">
            ¡Has ganado {commafy(settings?.prizes[prizeNumber] || 0)} Puntos! 🎉
          </Typography>
        )}
        {!!errorCode && (
          <Typography
            marginTop={1}
            variant="caption"
            color="red"
            textAlign="center"
          >
            {errorCode === "no_candidate" && "Aun no puedes redimir un premio"}
            {errorCode === "unknown" && "Ha ocurrido un error"}
          </Typography>
        )}
      </Box>
      <InfoModal
        title="Gira y Gana"
        content={`Basi Gira y Gana, las compras que realices en Basimart te brindan la oportunidad de girar la rueda y ganar puntos Basi.

        Estos puntos los puedes utilizar para pagar tu compra total o parcial en Basimart y son tu boleto para beneficios exclusivos y descuentos especiales durante el checkout.
        
        ¡Aprovecha la emoción de girar y gana con cada compra para maximizar tus beneficios en cada visita a Basimart!`}
      />
    </Box>
  );
};

export default WheelOfDiscount;

import NavbarComponent from "../components/NavbarComponent";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import toast, { Toaster } from "react-hot-toast";
import { useContext } from "react";
import axios from "../axios";
import Modal from "@mui/material/Modal";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import {
  updatePassword,
  getAuth,
  reauthenticateWithCredential,
  updateEmail,
} from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 370,
  bgcolor: "background.paper",
  borderRadius: 2,
  p: 2,
};

function UserAccountSetting() {
  const [passwordNotMatch, setPassordNotMatch] = useState("");
  const [firebaseError, setFirebaseError] = useState("");
  const [openModal, setModalOpen] = React.useState(false);
  const [defaultInfo, setDefaultInfo] = React.useState();
  const notify = (e) => toast(e);

  const [values, setValues] = React.useState({
    password: "",
    currentPassword: "",
    showPassword: false,
  });
  const [newValues, setNewValues] = React.useState({
    newPassword: "",
    showNewPassword: false,
  });

  const [newName, setNewName] = React.useState("");
  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };
  const [newPhone, setNewPhone] = React.useState("");
  const handlePhoneChange = (e) => {
    setNewPhone(e.target.value);
  };
  const [newEmail, setNewEmail] = React.useState("");

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };
  const [currentPassword, setCurrentPassword] = React.useState("");

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  const authUser = useContext(AuthContext);
  let id = authUser?.user?._id;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();

  const handleUpdateUserName = async () => {
    await axios
      .put(`/api/v1/user/userSettingUpdate/${authUser?.user?._id}`, {
        userName: newName,
      })
      .then((res) => {
        localStorage.removeItem("user");
        authUser.setUser(JSON.stringify(res.data.updateName, null, 4));
        localStorage.setItem(
          "user",
          JSON.stringify(res.data.updateName, null, 4)
        );
        window.location.reload();
      });
  };
  useEffect(() => {
    const getUserById = () => {
      axios
        .get(`/api/v1/user/${id}`)
        .then((res) => {
          setDefaultInfo(res.data, "datareturns");
        })
        .catch((err) => {
          console.log(err, "error");
        });
    };
    return getUserById();
  }, [id]);

  const handleUpdateUserNumber = async () => {
    await axios
      .put(`/api/v1/user/userSettingUpdate/${authUser?.user?._id}`, {
        phoneNumber: newPhone,
      })
      .then((res) => {
        localStorage.removeItem("user");
        authUser.setUser(JSON.stringify(res.data.updateName, null, 4));
        localStorage.setItem(
          "user",
          JSON.stringify(res.data.updateName, null, 4)
        );
        window.location.reload();
      });
  };
  const userAuth = getAuth();

  const userLogin = userAuth.currentUser;
  const handleUpdateUserEmail = async () => {
    const cred = firebase.auth.EmailAuthProvider.credential(
      userLogin.email,
      currentPassword
    );
    reauthenticateWithCredential(userLogin, cred)
      .then((res) => {
        updateEmail(userLogin, newEmail)
          .then(() => {
            axios
              .put(`/api/v1/user/userSettingUpdate/${authUser?.user?._id}`, {
                email: newEmail,
              })
              .then((res) => {
                localStorage.removeItem("user");
                authUser.setUser(JSON.stringify(res.data.updateName, null, 4));
                localStorage.setItem(
                  "user",
                  JSON.stringify(res.data.updateName, null, 4)
                );
                setEmailModal(!EmailModal);
                window.location.reload();
              });
          })
          .catch((error) => {
            console.log(error, "error");
          });
      })
      .catch((err) => {
        console.log(err);
        setFirebaseError("");
        setPassordNotMatch(err.message);
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleNewChange = (prop) => (event) => {
    setNewValues({ ...newValues, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowNewPassword = () => {
    setNewValues({
      ...newValues,
      showNewPassword: !newValues.showNewPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const reAuthenticateVendor = () => {
    const cread = firebase.auth.EmailAuthProvider.credential(
      userLogin.email,
      values.currentPassword
    );
    return reauthenticateWithCredential(userLogin, cread);
  };
  const handleSubmitting = async () => {
    if (newValues.newPassword == values.password) {
      reAuthenticateVendor()
        .then(() => {
          updatePassword(userLogin, values.password)
            .then(() => {
              setPassordNotMatch("");
              setFirebaseError("");
              notify("password updated successfully");
            })
            .catch((error) => {
              setFirebaseError("");
              setPassordNotMatch(error.message);
              console.log(error);
            });
        })
        .catch((error) => {
          setPassordNotMatch("");
          setFirebaseError(error.message);
          console.log(error);
        });
    } else {
      setFirebaseError("");
      setPassordNotMatch("password does not match");
    }
  };
  const [activeToggle, setActiveToggle] = useState(false);

  console.log(userLogin, "userin");
  const handleToggleChange = async () => {
    setActiveToggle(!activeToggle);
  };
  console.log(activeToggle, "activeToggleactiveToggle");

  function handleModalClose() {
    setModalOpen(false);
  }
  function handleModalOpen() {
    setModalOpen(true);
  }

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [nameModal, setNameModal] = React.useState(false);
  const [phoneModal, setPhoneModal] = React.useState(false);
  const [EmailModal, setEmailModal] = React.useState(false);

  function closeEmailModal() {
    setEmailModal(false);
    setPassordNotMatch("");
    setFirebaseError("");
  }
  function openEmailModal() {
    setEmailModal(true);
  }
  function closeNameModal() {
    setNameModal(false);
    setPassordNotMatch("");
    setFirebaseError("");
  }
  function openNameModal() {
    setNameModal(true);
  }
  function closePhoneModal() {
    setPhoneModal(false);
    setPassordNotMatch("");
    setFirebaseError("");
  }
  function closeModal() {
    setIsOpen(false);
    setValues(null);
    setNewValues(null);
    setPassordNotMatch("");
    setFirebaseError("");
  }
  function handleModalOpen() {
    setIsOpen(true);
  }
  function openPhoneModal() {
    setPhoneModal(true);
  }
  return (
    <div className="mt-[4rem]">
      <Toaster />
      <div>
        <Modal
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ fontSize: 16, fontWeight: "700" }}>
                Rest your password
              </div>
              <div>
                <CloseIcon style={{ cursor: "pointer" }} onClick={closeModal} />
              </div>
            </div>
            <FormControl sx={{ m: 1, width: "90%" }} variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                Current Password
              </InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={"password"}
                value={values?.currentPassword}
                onChange={handleChange("currentPassword")}
              />
            </FormControl>
            <div style={{ color: "red" }}>{firebaseError}</div>
            <FormControl sx={{ m: 1, width: "90%" }} variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                New password
              </InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={values?.showPassword ? "text" : "password"}
                value={values?.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values?.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl sx={{ m: 1, width: "90%" }}>
              <InputLabel htmlFor="filled-adornment-password">
                Confirm password
              </InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={newValues?.showNewPassword ? "text" : "password"}
                value={newValues?.newPassword}
                onChange={handleNewChange("newPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {newValues?.showNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div style={{ color: "red" }}>{passwordNotMatch}</div>

            <div
              onClick={handleSubmitting}
              style={{
                cursor: "pointer",
                backgroundColor: "#2D634C",
                width: 150,
                borderRadius: 10,
                padding: 5,
                marginTop: 10,
                color: "white",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Update password
            </div>
          </Box>
        </Modal>
        <Modal
          open={nameModal}
          onClose={closeNameModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ fontSize: 16, fontWeight: "700" }}>
              Update your name
            </div>
            <TextField
              style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
              onChange={handleNameChange}
              defaultValue={defaultInfo?.userId?.userName}
              id="outlined-search"
              label="First Name"
              type="search"
            />
            <div
              style={{
                fontWeight: "600",
                backgroundColor: "#F6F7F8",
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                width: 100,
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={handleUpdateUserName}
            >
              Save
            </div>
          </Box>
        </Modal>
        <Modal
          open={phoneModal}
          onClose={closePhoneModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ fontSize: 16, fontWeight: "700" }}>
              Update your number
            </div>
            <TextField
              style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
              onChange={handlePhoneChange}
              defaultValue={defaultInfo?.userId?.phoneNumber}
              id="outlined-search"
              label="Number"
              type="search"
            />
            <div
              style={{
                fontWeight: "600",
                backgroundColor: "#F6F7F8",
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                width: 100,
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={handleUpdateUserNumber}
            >
              Save
            </div>
          </Box>
        </Modal>
        <Modal
          open={EmailModal}
          onClose={closeEmailModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ fontSize: 16, fontWeight: "700" }}>
              Update your email
            </div>
            <TextField
              style={{ width: "100%", marginTop: 20 }}
              onChange={handleEmailChange}
              id="outlined-search"
              label="New E-mail"
              type="search"
            />
            <TextField
              style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
              onChange={handleCurrentPassword}
              id="outlined-search"
              label="Your current password"
              type="search"
            />
            <div style={{ color: "red" }}>{passwordNotMatch}</div>
            <div
              style={{
                fontWeight: "600",
                backgroundColor: "#F6F7F8",
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                width: 100,
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={handleUpdateUserEmail}
            >
              Save
            </div>
          </Box>
        </Modal>
      </div>
      <div
        className="ml-4 md:ml-[6rem]"
        style={{
          fontSize: 23,
          fontWeight: "700",
          marginBottom: 40,
        }}
      >
        Configuración de tu cuenta
      </div>
      <Grid
        container
        style={{ display: "flex", justifyContent: "center", padding: 10 }}
        md={12}
        xs={12}
      >
        <Grid
          item
          md={10}
          xs={12}
          style={{ paddingLeft: 30, fontWeight: "700" }}
        >
          Información de tu cuenta
        </Grid>
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
        }}
        container
        md={12}
        xs={12}
      >
        <Grid item md={3} xs={6}>
          <div
            style={{
              color: "rgb(52, 53, 56)",
              fontWeight: "normal",
              fontSize: 15,
            }}
          >
            Correo electrónico
          </div>
          <div
            style={{
              color: " rgb(114, 118, 126)",
              fontWeight: "normal",
              fontSize: 15,
            }}
          >
            {authUser?.user?.email}
          </div>
        </Grid>
        <Grid
          style={{ display: "flex", flexDirection: "row-reverse" }}
          item
          md={6}
          xs={6}
        >
          <div
            onClick={openEmailModal}
            style={{
              color: "rgb(10, 173, 10)",
              fontWeight: "600",
              fontSize: 15,
              cursor: "pointer",
            }}
          >
            Cambiar
          </div>
        </Grid>
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
        }}
        container
        md={12}
        xs={12}
      >
        <Grid
          style={{ borderBottomWidth: 1, paddingBottom: 20 }}
          item
          md={3}
          xs={6}
        >
          <div
            style={{
              color: "rgb(52, 53, 56)",
              fontWeight: "normal",
              fontSize: 15,
            }}
          >
            Contraseña
          </div>
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            borderBottomWidth: 1,
            paddingBottom: 20,
          }}
          item
          md={6}
          xs={6}
        >
          <div
            onClick={handleModalOpen}
            style={{
              color: "rgb(10, 173, 10)",
              fontWeight: "600",
              fontSize: 15,
              cursor: "pointer",
            }}
          >
            Cambiar
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ display: "flex", justifyContent: "center", padding: 10 }}
        md={12}
        xs={12}
      >
        <Grid
          item
          md={10}
          xs={12}
          style={{ paddingLeft: 30, fontWeight: "700" }}
        >
          Información Personal
        </Grid>
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
        }}
        container
        md={12}
        xs={12}
      >
        <Grid item md={3} xs={6}>
          <div
            style={{
              color: "rgb(52, 53, 56)",
              fontWeight: "normal",
              fontSize: 15,
            }}
          >
            Nombre
          </div>
          <div
            style={{
              color: " rgb(114, 118, 126)",
              fontWeight: "normal",
              fontSize: 15,
            }}
          >
            {defaultInfo?.userId?.userName}
          </div>
        </Grid>
        <Grid
          style={{ display: "flex", flexDirection: "row-reverse" }}
          item
          md={6}
          xs={6}
        >
          <div
            onClick={openNameModal}
            style={{
              color: "rgb(10, 173, 10)",
              fontWeight: "600",
              fontSize: 15,
              cursor: "pointer",
            }}
          >
            Cambiar
          </div>
        </Grid>
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
        }}
        container
        md={12}
        xs={12}
      >
        <Grid style={{ paddingBottom: 20 }} item md={3} xs={6}>
          <div
            style={{
              color: "rgb(52, 53, 56)",
              fontWeight: "normal",
              fontSize: 15,
            }}
          >
            Número de teléfono
          </div>
          <div
            style={{
              color: " rgb(114, 118, 126)",
              fontWeight: "normal",
              fontSize: 15,
            }}
          >
            {defaultInfo?.userId?.phoneNumber}
          </div>
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row-reverse",

            paddingBottom: 20,
          }}
          item
          md={6}
          xs={6}
        >
          <div
            onClick={openPhoneModal}
            style={{
              color: "rgb(10, 173, 10)",
              fontWeight: "600",
              fontSize: 15,
              cursor: "pointer",
            }}
          >
            Cambiar
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default UserAccountSetting;

import axios from "../axios";

type InteractionType =
  | "hover"
  | "click"
  | "addToCart"
  | "purchased"
  | "promoted";

export const addProductInteraction = async (
  userId: string,
  productId: string,
  interactionType: InteractionType
) => {
  try {
    axios.post("/api/v1/products/interaction", {
      userId,
      productId,
      interactionType,
    });
  } catch (error) {}
};

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import CopyToClipboard from "react-copy-to-clipboard";
import { PriceChop, Product } from "../../../../types";
import GenerateLinkModal from "./GenerateLinkModal";
import { DealCountdown } from "../../../../components/DealCountdown";
import { add, parseISO } from "date-fns";

type Props = {
  product: Product;
  priceChop?: PriceChop;
  onSelect: (p: Product) => void;
  onPriceChopGenerated: (p: PriceChop) => void;
  setOpenCopySnack: (open: boolean) => void;
};

const ProductCard: FC<Props> = ({
  product,
  priceChop,
  onSelect,
  onPriceChopGenerated,
  setOpenCopySnack,
}) => {
  const { images, name, size } = product;
  const [generating, setGenerating] = useState(false);

  const handleClick = useCallback(() => {
    onSelect(product);
  }, [onSelect, product]);

  const handleCloseGenerating = useCallback(
    (p?: PriceChop) => {
      setGenerating(false);
      if (p) onPriceChopGenerated(p);
    },
    [onPriceChopGenerated]
  );

  const handleGenerate = useCallback(() => {
    setGenerating(true);
  }, []);

  return (
    <Box>
      <Card
        variant="outlined"
        style={{
          minWidth: 190,
          minHeight: 240,
          border: "none",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <CardMedia
          component="img"
          image={images}
          style={{ height: 150, objectFit: "contain" }}
        />
        <CardContent>
          <Typography
            component="div"
            style={{
              color: "rgb(52, 53, 56)",
              whiteSpace: "pre-line",
            }}
          >
            {name}
          </Typography>
          <Typography component="div" variant="body2" color="text.secondary">
            {size}
          </Typography>
          {!!priceChop && (
            <>
              <Box className="flex" alignItems="center">
                <Box marginRight={1} flexGrow={1}>
                  <LinearProgress
                    variant="determinate"
                    value={
                      (priceChop.usersEmails.length /
                        (product?.priceChopDifficulty || 1)) *
                      100
                    }
                  />
                </Box>
                <Box minWidth="35px">
                  <Typography variant="body2" color="text.secondary">
                    {priceChop.usersEmails.length} /{" "}
                    {product?.priceChopDifficulty}
                  </Typography>
                </Box>
              </Box>
              <DealCountdown
                label="Tiempo para completar:"
                expirationDate={add(parseISO(priceChop.createdAt), {
                  hours: 24,
                }).toISOString()}
              />
              {(product?.priceChopDifficulty || 0) <=
                priceChop.usersEmails.length && (
                <Typography variant="body2" color="text.secondary">
                  ¡Has ganado! puedes agregar este producto a tu carrito gratis
                  🎉
                </Typography>
              )}
            </>
          )}
        </CardContent>
      </Card>
      <Box className="flex" justifyContent="center">
        {priceChop ? (
          (product?.priceChopDifficulty || 0) >
            priceChop.usersEmails.length && (
            <CopyToClipboard
              text={`${window.location.origin}/CreateAccount?priceChop=${priceChop._id}`}
              onCopy={() => setOpenCopySnack(true)}
            >
              <Button startIcon={<LinkIcon />} color="primary">
                Copiar enlace
              </Button>
            </CopyToClipboard>
          )
        ) : (
          <Button
            startIcon={<LinkIcon />}
            color="primary"
            onClick={handleGenerate}
          >
            Generar enlace
          </Button>
        )}
      </Box>
      <GenerateLinkModal
        product={generating ? product : undefined}
        onClose={handleCloseGenerating}
      />
    </Box>
  );
};

export default ProductCard;

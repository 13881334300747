import React, { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GuaranteeModal from "./components/GuaranteeModal";
import DeliveryModal from "./components/DeliveryModal";
import { Avatar } from "@mui/material";

export default function StoreInfo({ store }: any) {
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [guaranteeModal, setGuaranteeModal] = useState(false);

  return (
    <>
      {store && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 20,
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {store?.image ? (
                <img
                  style={{
                    width: 85,
                    height: 85,
                    marginTop: 10,
                    borderRadius: "50%",
                    borderWidth: 1,
                    borderColor: "lightgray",
                  }}
                  alt=""
                  src={store?.image}
                />
              ) : (
                <Avatar
                  style={{
                    width: 70,
                    height: 70,
                    marginTop: 10,
                  }}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{ fontWeight: "600", fontSize: 15, marginBottom: 5 }}
              >
                {store?.storeName}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                onClick={() => setDeliveryModal(true)}
                style={{
                  fontWeight: "600",
                  color: "gray",
                  fontSize: 12,
                  cursor: "pointer",
                  marginBottom: 5,
                }}
              >
                Tiempos de entrega
                <ArrowForwardIosIcon style={{ fontSize: 12 }} />
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                onClick={() => setGuaranteeModal(true)}
                style={{
                  fontWeight: "700",
                  color: "rgb(43, 120, 198)",
                  fontSize: 12,
                  cursor: "pointer",
                }}
              >
                100% garantía de satisfacción
                <ArrowForwardIosIcon style={{ fontSize: 12 }} />
              </span>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: 1,
              backgroundColor: "lightgray",
            }}
          />

          <DeliveryModal
            open={deliveryModal}
            onClose={() => setDeliveryModal(false)}
            store={store}
          />

          <GuaranteeModal
            open={guaranteeModal}
            onClose={() => setGuaranteeModal(false)}
          />
        </div>
      )}
    </>
  );
}

import {Grid} from "@mui/material";
import AllCategoryComponent from "../../AllCategoryComponent";
import Products from "../../Products";
import useScreenSize from "../../../hooks/useScreenSize";

export default function AllProductsOrGroupProducts({isGrouped, products, store, subCategories = []}) {

  const {isMobile} = useScreenSize()

  return (
    <>
      {!isGrouped ? (
        <Grid>
          <AllCategoryComponent
            productData1={products}
            store={store}
            subCategories={subCategories}
          />
        </Grid>
      ) : (
        <Grid style={{height: "100%"}}>
          {products.map((p, i) => (
            <Products
              key={i}
              name={p.name}
              products={p.products}
              store={store}
              isMobile={isMobile}
            />
          ))}
        </Grid>
      )}
    </>
  )
}

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, IconButton } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { ArrowBackTwoTone } from "@material-ui/icons";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "../axios";
import moment from "moment/moment";
import io from "socket.io-client";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { commafy } from "../utils/price";
import { isMobile } from "react-device-detect";
import Popover from "@mui/material/Popover";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const UserOrderDetails = () => {
  const location = useLocation();
  const params = location?.state?.row;
  const [order, setOrder] = useState();
  const navigation = useNavigate();
  const [isMobile, setIsMobile] = useState(getMobile());
  const [anchorEl, setAnchorEl] = useState(null);

  function getMobile() {
    const body = document.querySelector("body");
    const width = parseFloat(window.getComputedStyle(body).width);
    if (width > 900) {
      return false;
    } else {
      return true;
    }
  }

  window.addEventListener("resize", () => {
    setIsMobile(getMobile());
  });

  const [img, setImg] = useState(null);
  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        setImg(event.target.files[0]);
      };
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const notify = (e) => toast(e);

  const handleUpdate = async () => {
    await axios.put(
      `/api/v1/orders/paymentProof/${params?._id}`,
      {
        pop: img,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );
    notify("Image uploaded successfully");
  };
  useEffect(() => {
    async function getOrder() {
      axios
        .get(`/api/v1/allOrdersDetail/${params._id}`)
        .then((res) => {
          setOrder(res.data.allOrdersDetail);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    const socket = io(
      "https://urchin-app-49cxg.ondigitalocean.app/api/socket",
      {
        transports: ["websocket"],
      }
    ).connect();
    socket.on("newThought", (thought) => {
      getOrder();
    });

    getOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-extend-native
  Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
  };

  if (!order) return null;
  return (
    <>
      <Toaster />

      <Grid xs={12} style={Styles.box}>
        <ArrowBackTwoTone
          style={{
            color: "gray",
            fontSize: 22,
            marginLeft: "5px",
            marginTop: "5px",
          }}
          item
          onClick={() => navigation("/UserAllOrders")}
        />
        <h1 style={{ fontSize: 12, marginTop: "8px" }}>Atras</h1>
      </Grid>

      <div className="md:px-[5rem] px-2 mt-4">
        <div className="w-full mb-4">
          <h1 style={{ fontWeight: "bold", fontSize: 20, margin: "5px" }}>
            Resumen de la Orden {order?.pin ? `- PIN (${order.pin})` : ""}
          </h1>
          <div style={Styles.tableHeading}></div>
        </div>

        <Grid container xd={12} md={12}>
          <Grid item xs="12">
            <div className="bg-white rounded p-2 shadow">
              <h4 className="text-md text-gray-600 m-0 mb-4">
                Detalles de la compra
              </h4>
              <div className="grid grid-cols-3 gap-1 md:gap-4 md:px-5">
                <div className="flex justify-center">
                  <p style={styles.titleIcon}>
                    <DateRangeIcon />
                  </p>
                  <div>
                    <p style={styles.titleTxt}> Fecha compra </p>
                    <p style={styles.hightLightTxt}>
                      {moment(order?.purchaseDate).format(
                        "DD-MM-YYYY, HH-mm-ss A"
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <p style={styles.titleIcon}>
                    <EventAvailableIcon />
                  </p>
                  <div>
                    <p style={styles.titleTxt}> Fecha de entrega </p>
                    <p style={styles.hightLightTxt}>
                      {moment(
                        new Date(order?.purchaseDate).addHours(
                          order?.deliveryTime?.deliveryTime
                        )
                      ).format("DD-MM-YYYY, HH-mm-ss A")}
                    </p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <p style={styles.titleIcon}>
                    <ShoppingCartCheckoutIcon />
                  </p>
                  <div>
                    <p style={styles.titleTxt}> Estado </p>
                    {order && (
                      <p style={styles.hightLightTxt}>{order?.status}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className="grid grid-cols-12 gap-3 my-3">
          <div className="col-span-12 md:col-span-5">
            <div className="bg-white rounded p-2 h-full shadow">
              <h4 className="text-md text-gray-600 m-0 mb-4">
                Información de Entrega
              </h4>
              <div className="px-10 flex flex-col gap-4">
                <div className="flex">
                  <div className="w-1/2 text-sm">Nombre</div>
                  <div className="w-1/2 text-sm">{params.buyerName}</div>
                </div>
                <div className="flex">
                  <div className="w-1/2 text-sm">Telefono</div>
                  <div className="w-1/2 text-sm">{params.cellPhone}</div>
                </div>
                <div className="flex">
                  <div className="w-1/2 text-sm">Código Postal</div>
                  <div className="w-1/2 text-sm">
                    {order?.deliveryAddress?.zipCode}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-1/2 text-sm">Direccion</div>
                  <div className="w-1/2 text-sm">{order?.shipTo}</div>
                </div>
                <div className="flex">
                  <div className="w-1/2 text-sm">Otros Datos</div>
                  <div className="w-1/2 text-sm">
                    {order?.deliveryAddress?.addressOptional}
                  </div>
                </div>
                {order?.leaveAtDoor && (
                  <div className="flex">
                    <div className="w-1/2 text-sm">
                      Instrucciones al repartidor
                    </div>
                    <div className="w-1/2 text-sm">{order?.shipTo}</div>
                  </div>
                )}
                {order?.deliveryAddress?.instruction && (
                  <div className="flex">
                    <div className="w-1/2 text-sm">Más instrucciones</div>
                    <div className="w-1/2 text-sm">
                      <p className="m-0">
                        {order?.deliveryAddress?.instruction}
                      </p>
                      <p className="text-[#ad6d49]">
                        {moment(
                          new Date(order?.purchaseDate).addHours(
                            order?.deliveryTime?.deliveryTime
                          )
                        ).format("DD-MM-YYYY, HH-mm-ss A")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-7">
            <div className="bg-white rounded p-2 h-full shadow">
              <Accordion
                style={{
                  boxShadow: "none",
                  borderBottom: "1px solid black",
                }}
              >
                <AccordionSummary
                  style={{
                    padding: 0,
                  }}
                >
                  <h4 className="text-md text-gray-600 m-0">
                    Detalles del Pago{" "}
                  </h4>
                  <AttachFileIcon
                    style={{
                      fontSize: "20px",
                      marginLeft: "3px",
                      color: "#4C9F6F",
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={Styles.styleImg}>
                      <img
                        style={{ width: 350, height: 320 }}
                        src={order?.pop}
                        alt={"img"}
                      />
                    </Box>
                  </Modal>

                  <>
                    <>
                      {order?.pop && (
                        <h2 style={{ fontSize: "11px", fontWeight: "bold" }}>
                          Comprobante de pago{" "}
                        </h2>
                      )}

                      {!order?.pop && (
                        <h2 style={{ fontSize: "11px", fontWeight: "bold" }}>
                          Subir comprobante{" "}
                        </h2>
                      )}
                    </>
                    <div style={Styles.row}>
                      {order?.paymentType?.type !== "Pagar en efectivo" && (
                        <>
                          <div style={Styles.box}>
                            {order?.pop && (
                              <img
                                alt={"alt"}
                                onClick={() => setOpen(true)}
                                src={order?.pop}
                                style={{
                                  width: 120,
                                  height: 100,
                                  padding: "3px",
                                }}
                              />
                            )}

                            <input
                              type="file"
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={handleChangeImage}
                              id="contained-button-file"
                            />
                            <label htmlFor="contained-button-file">
                              <IconButton>
                                <AddPhotoAlternateIcon
                                  style={{ fontSize: "36px" }}
                                />
                              </IconButton>
                            </label>
                            <button
                              onClick={handleUpdate}
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#003D28",
                                padding: 5,
                                color: "white",
                                height: "28px",
                                marginTop: "5px",
                              }}
                            >
                              <p style={{ fontSize: "11px" }}>Enviar</p>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                </AccordionDetails>
              </Accordion>

              <div className="p-10 flex flex-col gap-4">
                <div className="flex">
                  <div className="w-1/2 text-sm">Metodo</div>
                  <div className="w-1/2 text-sm text-[#ad6d49]">
                    {" "}
                    {order?.paymentType?.type
                      ? order?.paymentType?.type
                      : "Puntos Basi"}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-1/2 text-sm">Subtotal</div>
                  <div className="w-1/2 text-sm text-[#ad6d49]">
                    ₡{commafy(order?.subtotal)}
                  </div>
                </div>
                {order?.pointsApplied > 0 && (
                  <div className="flex">
                    <div className="w-1/2 text-sm">Puntos aplicados</div>
                    <div className="w-1/2 text-sm text-[#ad6d49]">
                      {order?.pointsApplied}
                    </div>
                  </div>
                )}
                <div className="flex">
                  <div className="w-1/2 text-sm">Delivery</div>
                  <div className="w-1/2 text-sm text-[#ad6d49]">
                    ₡{order?.deliveryTime.price}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-1/2 text-sm">Tarifas</div>
                  <div className="w-1/2 text-sm text-[#ad6d49]">
                    ₡{(order.serviceFee ?? 100) + (order.smallOrderFee ?? 0)}
                    <InfoOutlinedIcon
                      style={{ fontSize: 14 }}
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                      fontSize="small"
                      className="ml-1 mb-1 cursor-pointer text-gray-500"
                    />
                  </div>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <div className="p-2 text-sm">
                      <div className="flex justify-between gap-4">
                        <span>Tasa de servicio</span>
                        <span>₡{order.serviceFee ?? 100}</span>
                      </div>
                      {!!order.smallOrderFee && (
                        <div className="flex justify-between gap-4">
                          <span>Tasa por pedido pequeño</span>
                          <span>₡{order.smallOrderFee}</span>
                        </div>
                      )}
                    </div>
                  </Popover>
                </div>
                <div className="flex">
                  <div className="w-1/2 text-sm">Total</div>
                  <div className="w-1/2 text-sm text-[#ad6d49]">
                    ₡{commafy(order?.totalPrice)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Grid
          justifyContent="center"
          className="!mt-7"
          container
          xd={12}
          md={12}
        >
          <Grid item xs={12}>
            <div className="bg-white rounded p-2 shadow">
              <div style={Styles.containerTable}>
                {!isMobile && (
                  <MDBTable responsive style={Styles.mdTable}>
                    <MDBTableHead>
                      <tr>
                        <th scope="col" style={Styles.tittle}>
                          Información del Producto
                        </th>
                        <th scope="col" style={Styles.tittle}>
                          Cantidad
                        </th>
                        <th scope="col" style={Styles.tittle}>
                          Peso
                        </th>
                        <th scope="col" style={Styles.tittle}>
                          Precio
                        </th>
                        <th scope="col" style={Styles.tittle}>
                          Total
                        </th>
                      </tr>
                    </MDBTableHead>

                    {order?.productDetails?.map((row) => {
                      return (
                        <MDBTableBody>
                          <tr
                            style={{
                              marginTop: "10px",
                              marginButton: "10px",
                              padding: "5px",
                            }}
                          >
                            <th scope="row" style={styles.tableTxt}>
                              <div style={Styles.box}>
                                <img
                                  style={{ width: "13%", height: "13%" }}
                                  src={row.images}
                                  alt="img"
                                />
                                <p
                                  style={{
                                    marginTop: "8px",
                                    paddingLeft: "8px",
                                  }}
                                >
                                  {row.name}
                                </p>
                              </div>
                            </th>

                            <td style={styles.tableTxt}>
                              <div>{row.qty}</div>
                            </td>

                            <td style={styles.tableTxt}>
                              <div>{row.size}</div>
                            </td>

                            <td style={styles.tableTxt}>
                              <div> ₡{commafy(row.basePrice)} </div>
                            </td>

                            <td style={styles.tableTxt}>
                              <div>₡{commafy(row.price)}</div>
                            </td>
                          </tr>
                        </MDBTableBody>
                      );
                    })}
                  </MDBTable>
                )}

                {isMobile && (
                  <MDBTable responsive style={Styles.mdTableMobile}>
                    <MDBTableHead>
                      <tr>
                        <th scope="col" style={Styles.tittleMobile}>
                          Descripción
                        </th>
                        <th scope="col" style={Styles.tittleMobile}>
                          X
                        </th>
                        <th scope="col" style={Styles.tittleMobile}>
                          Total
                        </th>
                      </tr>
                    </MDBTableHead>
                    {order?.productDetails?.map((row) => {
                      return (
                        <MDBTableBody>
                          <tr
                            style={{
                              marginTop: "10px",
                              marginButton: "10px",
                              padding: "5px",
                            }}
                          >
                            <th scope="row" style={styles.tableTxt}>
                              <div style={Styles.box}>
                                <img
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    marginLeft: "-35px",
                                  }}
                                  src={row.images}
                                  alt="img"
                                />
                                <p
                                  style={{
                                    paddingLeft: "3px",
                                    textAlign: "left",
                                  }}
                                >
                                  {row.name}
                                </p>
                              </div>
                            </th>

                            <td
                              style={{ textAlign: "center", fontSize: "12px" }}
                            >
                              <div>x{row.qty}</div>
                            </td>

                            <td>
                              <div
                                style={{ textAlign: "right", fontSize: "11px" }}
                              >
                                {" "}
                                ₡{commafy(row.basePrice)}{" "}
                              </div>
                              <div
                                style={{
                                  textAlign: "right",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                ₡{commafy(row.price)}
                              </div>
                            </td>
                          </tr>
                        </MDBTableBody>
                      );
                    })}
                  </MDBTable>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default UserOrderDetails;
const styles = {
  card: {
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "5px",
  },
  titleTxt: {
    fontSize: 13,
    fontWeight: "bold",
    color: "black",
    padding: "2px",
    margin: "2px",
    textAlign: "left",
  },
  tableTxt: {
    fontSize: 13,
    color: "black",
    padding: "2px",
    margin: "auto",
    textAlign: "left",
  },
  titleIcon: {
    color: "#529f6f",
    marginRight: "5px",
    fontSize: 15,
  },
  valueTxt: {
    fontSize: 13,
    color: "#222",
  },
  hightLightTxt: {
    fontSize: 13,
    color: "#AD6D49",
    margin: "1px",
  },
  buyerName: {
    color: "AD6D49",
    fontSize: 13,
    padding: "1px",
  },
  buyerText: {
    color: "AD6D49",
    fontSize: 13,
    paddingBottom: "1px",
  },
  titleBuyer: {
    fontSize: 13,
    fontWeight: "bold",
    color: "black",
    paddingBottom: "1px",
  },
};

const Styles = {
  tableHeading: { background: "#529f6f", height: "1.5px", width: "100%" },
  container: {
    background: "white",
    width: "98%",
    borderRadius: 8,
    borderWidth: 2,
    borderColor: "#f2f2f4",
    padding: "5px",
    margin: "5px",
  },
  container2: {
    background: "white",
    borderRadius: 8,
    borderWidth: 2,
    borderColor: "#f2f2f4",
    marginLeft: "8px",
    marginTop: "5px",
    paddingBottom: "10px",
  },
  containerTable: {
    overflowY: "hidden",
    overflowX: "auto",
    display: "grid",
    justifyItems: "center",
  },

  containerClient: {
    height: "316px",
    overflowY: "hidden",
  },

  containerDates: {
    height: "210px",
    overflowY: "hidden",
  },
  containerDates2: {
    display: "grid",
    alignContent: "center",
  },
  containerPay: {
    height: "316px",
    overflowY: "hidden",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "35%",
    flex: " 0 0 35%",
  },
  col3: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "65%",
    flex: " 0 0 65%",
  },

  tittle: {
    color: "#4B566B",
    fontSize: 15,
    fontWeight: "bold",
    marginLeft: "10px",
    marginTop: "25px",
    marginBottom: "15px",
    padding: "2px",
    textAlign: "left",
  },
  tittleMobile: {
    color: "#4B566B",
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: "5px",
    marginTop: "20px",
    marginBottom: "15px",
    textAlign: "Left",
  },
  mdTable: {
    background: "white",
    padding: "4px",
    borderSpacing: "45px 10px",
    borderCollapse: "separate",
  },
  mdTableMobile: {
    background: "white",
    padding: "2px",
    borderSpacing: "30px 5px",
    borderCollapse: "separate",
  },
  box: {
    display: "flex",
    alignItems: "stretch",
  },
  box2: {
    display: "flex",
    alignItems: "stretch",
    alignSelf: "flex-end",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    padding: 40,
    justifyContent: "space-between",
    alignItems: "center",
  },
  saveButtom: {
    fontSize: 14,
    color: "white",
    backgroundColor: "#013d29",
    padding: "14px 22px",
    borderRadius: 10,
    cursor: "pointer",
    border: "none",
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
    lineHeight: "21px",
  },
  styleImg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 300 : 500,
    height: isMobile ? 300 : 500,
    bgcolor: "background.paper",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

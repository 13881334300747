import React, { useContext, useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Avatar, Box, Drawer } from "@mui/material";
import { useStateValue } from "../../../ContextApi/StateProvider";
import { isMobile } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
//@ts-ignore
import LocContext from "../../../context/Locationcontext";
import Colors from "../../../constants/Colors";
import { commafy } from "../../../utils/price";
//@ts-ignore
import { getCartTotal } from "../../../ContextApi/reducer.ts";
import DeleteIcon from "@mui/icons-material/Delete";
//@ts-ignore
import Counter from "../../../components/Counter";
import { DealPrice } from "../../../components/DealPrice";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import SignIn from "../../../screens/Authentication/SignIn/SignIn";
import Modal from "react-responsive-modal";

export default function CartDrawer({ store }: any) {
  const navigation = useNavigate();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const userLocation = useContext<any>(LocContext);
  const [{ cart }, dispatch] = useStateValue();

  const removeFromBasket = (id: any) => {
    dispatch({
      type: "REMOVE_FROM_CART",
      id: id,
    });
  };

  const TotalValue = getCartTotal(cart);

  const roundOfValue = parseFloat(TotalValue.toFixed(2));

  const onCheckout = () => {
    if (user) {
      navigation("/UserCheckOut", {
        state: { storeData: store, totalPrice: roundOfValue },
      });
    } else {
      setOpen(false);
      setOpenSignInModal(true);
    }
  };

  return (
    <>
      <div onClick={() => setOpen(true)}>
        <div
          style={{
            backgroundColor:
              (cart?.length || -1) > 0 ? "lightgreen" : "#F6F7F8",
            display: "flex",
            justifyContent: "center",
            borderRadius: 24,
            paddingTop: isMobile ? 6 : 8,
            paddingBottom: isMobile ? 6 : 8,
            paddingLeft: isMobile ? 15 : 20,
            paddingRight: isMobile ? 15 : 20,
            cursor: "pointer",
          }}
        >
          <ShoppingCartIcon
            style={{ color: "black", fontSize: isMobile ? 16 : undefined }}
          />
          <div style={{ color: "black", fontSize: isMobile ? 13 : undefined }}>
            {cart?.length}
          </div>
        </div>
      </div>
      <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
        <Box sx={{ width: isMobile ? 380 : 500 }} role="presentation">
          {cart && cart?.length <= 0 ? (
            <div style={{ height: isMobile ? "80vh" : "100vh" }}>
              <div style={Styles.emptyPersonalCartHeader}>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(false)}
                />
                <div style={Styles.personalTextContainer as any}>
                  <div style={Styles.storeAddress}>
                    Estas comprando en +{userLocation?.loc?.area}
                  </div>
                </div>
                <div />
              </div>
              <div style={Styles.emptyPersonalCartBody}>
                <div style={Styles.shopNowContainer as any}>
                  <img
                    alt="emptyCart"
                    style={{ width: 230, height: 150 }}
                    src={require("../../../assets/emptycart.png")}
                  />
                  <div style={Styles.personalText1}>
                    Su carrito personal está vacío{" "}
                  </div>
                  <div
                    onClick={() => setOpen(false)}
                    style={Styles.personalText2}
                  >
                    Compra ahora
                  </div>
                </div>
              </div>
              <div style={Styles.emptyCheckOut}>
                <div />
                <div>
                  <div>Finalizar compra</div>
                </div>
                <div style={Styles.emptyPrice}>
                  <div>₡0.00</div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ height: isMobile ? "80vh" : "100vh" }}>
              <div
                style={{
                  height: "9vh",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 10,
                  width: "100%",
                  position: "sticky",
                  borderBottomWidth: 1,
                }}
              >
                <CloseIcon
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  onClick={() => setOpen(false)}
                />
                <div style={Styles.personalTextContainer}>
                  <div style={Styles.storeName}>Tu Carrito</div>
                  <div style={Styles.storeAddress}>
                    Estas comprando en +{userLocation?.loc?.area}
                  </div>
                </div>
                <div />
              </div>
              <div
                style={{
                  backgroundColor: "#F6F7F8",
                  height: isMobile ? "70vh" : "77vh",
                  overflowY: "scroll",
                  scrollBehavior: "smooth",
                  whiteSpace: "nowrap",
                }}
              >
                <div style={Styles.storeInfoContainer}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      {store?.image ? (
                        <img
                          style={{
                            width: 55,
                            height: 55,
                            marginTop: 5,
                            marginBottom: 5,
                            borderRadius: "50%",
                            borderWidth: 1,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                          src={store?.image}
                          alt=""
                        />
                      ) : (
                        <Avatar
                          style={{
                            width: 55,
                            height: 55,
                            marginTop: 5,
                            marginBottom: 5,
                            borderWidth: 1,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                        />
                      )}
                    </div>
                    <div style={{ marginLeft: 5 }}>
                      <div style={Styles.storeTitle}>{store.storeName}</div>
                      <div style={Styles.storeDeliveryDate}>{store?.date}</div>
                      <div style={Styles.storeGuarantee}>
                        100% garantía de satisfacción{" "}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ fontWeight: "700", fontSize: 13 }}>
                      ₡{commafy(roundOfValue)}
                    </div>
                  </div>
                </div>
                {/* <div style={Styles.firstDeliveryContainer}>
              <div style={Styles.firstDeliveryText}>
                Your first delivery is free!
              </div>
            </div> */}
                {cart?.map((item: any) => {
                  return (
                    <div key={item.pId} style={Styles.product}>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div>
                          <img
                            alt="productImg"
                            style={Styles.productImg}
                            src={item?.images}
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: 5,
                          }}
                        >
                          <div style={Styles.productTitle}>{item?.name}</div>
                          <div
                            style={{
                              fontSize: 11,
                              // fontWeight: "600",
                              color: "#AAACB1",
                            }}
                          >
                            {item?.size}
                          </div>

                          <div style={Styles.productDeliverDate}>
                            {item?.createdAt}
                          </div>

                          <div
                            onClick={() => removeFromBasket(item?.id)}
                            style={Styles.productRemove}
                          >
                            <DeleteIcon
                              style={{ fontSize: 18, paddingTop: 1 }}
                            />
                            <div
                              style={{
                                color: "rgb(114, 118, 126",
                                fontWeight: "600",
                                fontSize: 12,
                              }}
                            >
                              Eliminar
                            </div>
                          </div>
                          <Counter
                            counterStyle={{
                              display: "flex",
                              backgroundColor: "#52AE11",
                              justifyContent: "space-between",
                              borderRadius: 18,
                              padding: 4,
                              color: "white",
                              width: 120,
                            }}
                            id={item?.id}
                            price={item?.price}
                            date={item?.date}
                            img={item?.images}
                            title={item?.name}
                            qty={item?.qty}
                            stock={item?.stock}
                            basePrice={item?.basePrice}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            width: 70,
                            display: "flex",
                            flexDirection: "row-reverse",
                            fontSize: 12,
                            marginRight: 3,
                          }}
                        >
                          <DealPrice
                            price={parseFloat(item?.price).toFixed(2)}
                            deal={item?.deal}
                            dealStatus={item?.dealStatus}
                            compact
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                onClick={() => onCheckout()}
                style={Styles.personalCartFooter}
              >
                <div />
                <div>
                  <div
                    style={{
                      fontSize: 17,
                      color: "white",
                      fontWeight: "600",
                      marginRight: -30,
                    }}
                  >
                    Finalizar compra{" "}
                  </div>
                </div>
                <div style={Styles.cartTotalPrice}>
                  ₡{commafy(roundOfValue)}
                </div>
              </div>
            </div>
          )}
        </Box>
      </Drawer>
      <Modal
        open={openSignInModal}
        onClose={() => setOpenSignInModal(false)}
        center
        styles={{
          modal: {
            width: isMobile ? undefined : 500,
          },
        }}
      >
        <SignIn modal />
      </Modal>
    </>
  );
}

const Styles = {
  mobileLoginText: {
    display: "flex",
    backgroundColor: "#52AE11",
    borderRadius: 24,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 13,
    cursor: "pointer",
    color: "white",
    fontWeight: "600",
  },
  mobileCartContainer: {
    backgroundColor: Colors.lightgray,
    display: "flex",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },
  gridContainer: {
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "white",
    top: 0,
    position: "fixed",
    zIndex: 100,
    borderBottomWidth: 1,
  },
  menuContainer: {
    display: "flex",
    justifyContent: "center",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputText: {
    width: "100%",
    height: 45,
    borderRadius: 5,
    fontSize: 13,
    fontWeight: "600",
    paddingInlineStart: 10,
  },
  inputText2: {
    height: 45,
    borderRadius: 10,
    backgroundColor: "#F1F1F1",
    fontSize: 13,
    fontWeight: "600",
    paddingInlineStart: 10,
    width: "100%",
  },
  addressContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  colorWhite: { color: "black", fontSize: 16, marginTop: -2 },
  webcolorWhite: { color: "black", fontSize: 18, marginTop: -5 },

  webCartContainer: {
    backgroundColor: Colors.lightgray,
    display: "flex",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },

  webLoginText: {
    display: "flex",
    backgroundColor: "#52AE11",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 10,
    cursor: "pointer",
    color: "white",
    marginLeft: -100,
    position: "absolute",
  },
  emptyPersonalCartBody: {
    backgroundColor: "#F6F7F8",
    height: "78vh",
    display: "flex",
    justifyContent: "center",
  },
  personalTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyPersonalCartHeader: {
    height: "11vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  personalText1: { color: "#72767E", fontSize: 17, fontWeight: "600" },
  personalText2: {
    color: "#58B42F",
    fontSize: 17,
    fontWeight: "600",
    cursor: "pointer",
  },
  emptyCheckOut: {
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F6F7F8",
    margin: 5,
    alignItems: "center",
    borderRadius: 10,
  },
  emptyPrice: {
    backgroundColor: "lightgray ",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
  },

  personalCartHeader: {
    height: "10vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    width: "100%",
    position: "sticky",
    borderBottomWidth: 1,
  },
  personalCartBody: {
    backgroundColor: "#F6F7F8",
    height: "77vh",
    overflowY: "scroll",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
  },
  personalCartFooter: {
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#52AE11",
    margin: 5,
    alignItems: "center",
    borderRadius: 10,
    cursor: "pointer",
    position: "absolute",
    width: "96%",
    // bottom: 0,
  },
  storeName: {
    fontSize: 14,
    fontWeight: "600",
    color: "black",
    marginLeft: -35,
  },
  storeAddress: {
    fontSize: 12,
    fontWeight: "600",
    color: "gray",
    marginLeft: -35,
  },
  shopNowContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 50,
  },
  cartTotalPrice: {
    backgroundColor: "#418B24",
    color: "white",
    fontWeight: "600",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
  },
  storeInfoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    backgroundColor: "white",
    padding: 5,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    marginBottom: 10,
  },
  storeImg: { width: 70, height: 70, borderRadius: 50 },
  storeTitle: { fontWeight: "600", color: "black", fontSize: 14 },
  storeDeliveryDate: {
    color: "rgb(10, 173, 10)",
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 2,
  },
  storeGuarantee: {
    fontWeight: "600",
    color: "rgb(43, 120, 198)",
    fontSize: 12,

    cursor: "pointer",
    marginLeft: 2,
  },
  firstDeliveryContainer: {
    display: "flex",
    position: "relative",
    backgroundColor: "rgb(250, 241, 229)",
    marginTop: 10,
  },
  firstDeliveryText: {
    fontSize: 14,
    fontWeight: "600",
    color: " rgb(117, 0, 70)",
    padding: 8,
  },
  product: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    padding: 5,
  },
  productImg: { width: 80, height: 55 },
  productTitle: {
    color: "rgb(52, 53, 56)",
    width: 230,
    whiteSpace: "pre-line",
    fontSize: 13,
  },
  productDeliverDate: {
    color: "rgb(10, 173, 10)",
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 2,
  },
  productRemove: {
    cursor: "pointer",
    marginLeft: 2,
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  customOverlay: {
    // background: "rgba(36, 123, 160, 0.7)"
    borderRadius: 10,
  },
  customModal: {
    borderRadius: 10,
    width: 340,
  },
  customModalSignIn: {
    borderRadius: 10,
    minWidth: "30%",
  },
} as any;

import React from "react";
import { Modal } from "react-responsive-modal";

const GuaranteeModal = ({ open, onClose }: any) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{
        overlay: Styles.customOverlay,
        modal: {
          ...Styles.customModal,
          // width: isMobile ? 340 : 500,
        },
        root: Styles.customRoot,
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            style={{
              width: 80,
              height: 80,
              padding: 10,
            }}
            src={require("../../../assets/guaranteeIcon.png")}
            alt="guaranteeIcon"
          />
          <div
            style={{
              fontWeight: "700",
              fontSize: 18,
            }}
          >
            100% garantía de satisfacción{" "}
          </div>
        </div>
        <div style={{ padding: 10, color: "#343538" }}>
          Haz tu pedido con tranquilidad. Si experimentas alguno de los
          siguientes problemas, eres elegible para un reembolso o crédito:
        </div>
      </div>
      <div style={{ padding: 20 }}>
        <ol style={{ listStyleType: "inherit" }}>
          <li style={{ marginLeft: 20, color: "#343538" }}>
            Daño o producto faltante
          </li>
          <li style={{ marginLeft: 20, color: "#343538" }}>
            Reemplazos deficientes de artículos
          </li>
          <li style={{ marginLeft: 20, color: "#343538" }}>Llegada tardía</li>
          <li style={{ marginLeft: 20, color: "#343538" }}>
            Servicio no profesional
          </li>
        </ol>
      </div>
      <div
        onClick={onClose}
        style={{
          backgroundColor: "#63CE46",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          margin: 10,
          borderRadius: 10,
          cursor: "pointer",
        }}
      >
        Entendido{" "}
      </div>
    </Modal>
  );
};

export default GuaranteeModal;

const Styles = {
  customOverlay: {
    // background: "rgba(36, 123, 160, 0.7)"
    borderRadius: 10,
  },
  customModal: {
    borderRadius: 10,
  },
  customRoot: {
    zIndex: 10000000,
  },
};

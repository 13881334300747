export const commafy = (num: string | number, truncate?: number) => {
  if (num === 0) return `0`;
  if (num === undefined) return `0`;
  if (num === null) return `0`;
  let str = num?.toString().split(".");
  if (str[0].length >= 4) {
    str[0] = str[0]?.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  if (str[1] && str[1].length >= 4) {
    str[1] = str[1]?.replace(/(\d{3})/g, "$1 ");
  }

  if (truncate && str[1]) {
    str[1] = str[1].slice(0, truncate);
  }

  return str.join(".");
};

import React, { useContext } from "react";
import Modal from "react-responsive-modal";
import SignIn from "../../../screens/Authentication/SignIn/SignIn";
import AuthContext from "../../../context/AuthContext";

export default function SignInModal({ isMobile }: { isMobile: boolean }) {
  const { user } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);

  if (user) {
    return null;
  }

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        style={
          isMobile
            ? {
                display: "flex",
                backgroundColor: "#52AE11",
                borderRadius: 24,
                paddingTop: 6,
                paddingBottom: 6,
                paddingLeft: 15,
                paddingRight: 15,
                fontSize: 13,
                cursor: "pointer",
                color: "white",
                fontWeight: "600",
              }
            : {
                display: "flex",
                backgroundColor: "#52AE11",
                borderRadius: 24,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                marginRight: 10,
                cursor: "pointer",
                color: "white",
                marginLeft: -100,
                position: "absolute",
              }
        }
      >
        Entrar
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        styles={{
          modal: {
            width: isMobile ? undefined : 500,
          },
        }}
      >
        <SignIn modal />
      </Modal>
    </>
  );
}

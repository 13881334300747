import { Box, Drawer } from "@mui/material";
import DrawerContent from "./DrawerContent";

const drawerWidth = 240;

export default function MiniFrontDrawer({
  store,
  isMobile,
  drawerMarginClass,
}) {
  return (
    <Box component="nav" aria-label="mailbox folders">
      {store && (
        <Drawer
          BackdropProps={{ invisible: true }}
          variant={isMobile ? "temporary" : "persistent"}
          PaperProps={{
            sx: {
              height: "100%",
              ...(!isMobile
                ? {
                    marginTop: "66px",
                  }
                : {}),
            },
          }}
          ModalProps={{
            keepMounted: true,
          }}
          classes={drawerMarginClass}
          className={!isMobile ? "mainDrawer" : ""}
          sx={{
            ...(isMobile
              ? {
                  marginTop: 90,
                  position: "absolute",
                  top: 90,
                  display: { xs: "block", sm: "block" },
                }
              : {
                  position: "fixed",
                  marginTop: "66px",
                  overflowY: "hidden",
                  width: 240,
                  display: { xs: "none", sm: "block" },
                }),
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open={true}
        >
          <DrawerContent store={store} isMobile={isMobile} />
        </Drawer>
      )}
    </Box>
  );
}

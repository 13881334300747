import { Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextField from "@mui/material/TextField";
import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LoyaltyRoundedIcon from "@mui/icons-material/LoyaltyRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import "react-phone-number-input/style.css";
import { Checkbox } from "@mui/material";
import { CreditCard } from "@material-ui/icons";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./User.css";
import Popover from "@mui/material/Popover";

import axios from "../axios";
import { getCartTotal } from "../ContextApi/reducer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../ContextApi/StateProvider";
import { useLocation } from "react-router-dom";
import UserDeliveryTab from "./UserDeliveryTab";
import UserPaymentTab from "./UserPaymentTab";
import toast, { Toaster } from "react-hot-toast";

import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import Colors from "../constants/Colors";
import AuthContext from "../context/AuthContext";
import { commafy } from "../utils/price";
import { useContext } from "react";
import { checkOutStyle } from "../components/style/checkoutStyle";
import { makeStyles } from "@material-ui/core/styles";
import { useBalance } from "../context/BalanceContext";
import TeamPurchaseProductCard from "./TeamPurchaseProductCard";
import { isAfter, parseISO } from "date-fns";
import { onlyDecimalNumbers } from "../utils/numbers";
import UserAddress from "./UserAddress";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const orderConfirmStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
};

function UserCheckOut() {
  let formData = new FormData();
  const [progress, setProgress] = useState(0);
  const [{ cart }, dispatch] = useStateValue();
  const vendorId = cart[0]?.vendorId;
  const [vendorData, setVendorData] = useState();
  useEffect(() => {
    const getVendorData = () => {
      if (!vendorId) return;

      axios
        .get(`/api/v1/vendor/${vendorId}`)
        .then((res) => {
          setVendorData(res.data.vendorId);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getVendorData();
  }, [vendorId]);

  const navigation = useNavigate();
  const location = useLocation();
  const notify = (e) => toast(e);

  const storeName = location?.state?.storeData?.storeName;
  const cellPhone = location?.state?.storeData?.cellPhone;
  const image = location?.state?.storeData?.image;
  const hiddenFileInput = React.useRef(null);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [form, setForm] = useState();
  const [formStepper, setFormStepper] = useState(0);
  const TotalValue = getCartTotal(cart);
  const totalCartPrice = parseFloat(TotalValue.toFixed(2));

  const [address, setAddress] = useState();

  const auth = useContext(AuthContext);
  const { points, fetchBalance } = useBalance();
  const [uploadImageDisable, setUploadImageDisable] = useState(false);
  const classes = useStyles();
  const [stepAddress, setStepAddress] = useState(false);
  const [stepDelivery, setStepDelivery] = useState(false);
  const [stepPayment, setStepPayment] = useState(false);
  const [stepProof, setStepProof] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? "simple-popover" : undefined;

  const initialValues = {
    address: "",
    addressOptional: "",
    businessName: "",
    district: "",
    zipCode: "",
    instruction: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [storeImage, setStoreImage] = useState(null);
  const [img, setImg] = useState(null);
  const [addressVal, setAddressVal] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name === "address") setAddressVal(false);
  };

  const increaseStepper = () => {
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  const saveUserAddress = async () => {
    setFormErrors(validate(formValues));
    axios
      .post(`/api/v1/user/saveUserAddress/${auth?.user?._id}`, {
        address: formValues,
      })
      .then((res) => {
        setAddressVal(false);
      });

    setIsSubmit(true);
    setStepAddress(true);
  };

  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      formData.append("image", event.target.files[0]);
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        setImg(event.target.files[0]);
        setStoreImage(e.target.result);
        setUploadImageDisable(true);
        notify("Image uploaded successfully");
      };
    }
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setFormStepper(formStepper + 1);
    }
  }, [formErrors]);

  useEffect(() => {
    async function getAddress() {
      axios.get(`/api/v1/user/${auth?.user?._id}`).then((res) => {
        setAddress(res.data);
        setAddressVal(false);
        if (res.data.userId.hasOwnProperty("address")) {
          let a = res.data.userId?.address;
          setFormValues({
            address: a?.address,
            district: a?.district,
            zipCode: a?.zipCode,
            businessName: a?.businessName,
            addressOptional: a?.addressOptional,
          });
        }
      });
    }
    if (auth?.user) {
      getAddress();
    }
  }, [auth]);

  const validate = (values) => {
    const errors = {};
    if (!values.address) {
      errors.address = "address is required";
    }
    if (!values.zipCode) {
      errors.zipCode = "zipCode is required";
    }
    return errors;
  };

  const completeFormStep = () => {
    setFormStepper(formStepper + 1);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const [activeTabData, setActiveTabData] = useState({});
  const [activeTab, setActiveTabs] = useState(activeTabData);
  const [activePaymentData, setActivePaymentData] = useState({});
  const [activePayment, setActivePayment] = useState(activePaymentData);
  const [serviceFee, setServiceFee] = useState(0);
  const [smallOrderFee, setSmallOrderFee] = useState(0);

  useEffect(() => {
    axios.get(`/api/v1/admin/settings`).then((res) => {
      const settings = res.data.settings;
      if (settings.serviceFeeEnabled) {
        setServiceFee(settings.serviceFee);
      }
      if (
        settings.smallOrderFeeEnabled &&
        totalCartPrice <= settings.minOrderSubtotal
      ) {
        setSmallOrderFee(settings.smallOrderFee);
      }
    });
  }, []);
  const deliveryFee = Number(activeTabData?.price);
  const SumTotal = totalCartPrice + deliveryFee + serviceFee + smallOrderFee;
  const finalValue = parseFloat(SumTotal.toFixed(1));
  const [myResponce, setMyResponce] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState();
  const [check, setCheck] = React.useState(false);
  const [deliverDisable, setDeliverDisable] = useState(true);
  const [paymentDisable, setPaymentDisable] = useState(true);
  const [paymentInstruction, setPaymentInstruction] = useState();
  const [cashOnDelivery, setCashOnDelivery] = useState(true);
  const [pointsApplied, setPointsApplied] = useState(0);
  const [tempValue, setTempValue] = useState("0");
  const [value, setValue] = useState(0);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  const handleChangePoints = (e) => {
    setTempValue(e.target?.value);
    const value = parseFloat(e.target?.value) || 0;
    setValue(value);
    if (
      value >= 0 &&
      value <= parseFloat(points) &&
      value <= parseFloat(totalCartPrice)
    ) {
      setPointsApplied(value);
    }
  };

  const paymentTypeData = (e) => {
    setActivePaymentData(e);
    if (e.text === "Transferencia bancaria") {
      setCashOnDelivery(true);
      setPaymentInstruction("");
      setPaymentInstruction(
        "Envía una transferencia del monto de la compra y luego adjunta el comprobante en el campo abajo.Cuentas de Banco:Cuenta de Banco A XXXXXXXXXXXXXCuenta de Banco B XXXXXXXXXXXXXCuenta de Banco C XXXXXXXXXXXXX"
      );
    } else if (e.text === "SINPE Móvil") {
      setCashOnDelivery(true);
      setPaymentInstruction("");
      setPaymentInstruction(
        "Envía el monto de la compra al número ####-#### y luego adjunta una imagen con el comprobante de pago en el campo abajo"
      );
    } else {
      setCashOnDelivery(false);
    }
  };
  const handleToggle = () => {
    setCheck(!check);
  };
  const [submitDisable, setSubmitDisable] = useState(false);
  const checkOutSubmit = () => {
    setSubmitDisable(!submitDisable);
    setPaymentDisable(true);

    if (img) {
      axios
        .post(
          "/api/v1/allOrdersDetail/new",
          {
            buyerId: auth.user._id,
            vendorId: vendorId, //remove
            productDetails: JSON.stringify(cart),
            totalPrice: finalValue - pointsApplied,
            subtotal: totalCartPrice,
            storeName: storeName,
            cellPhone: cellPhone,
            vendorStoreImg: image,
            deliveryTime: JSON.stringify({
              price: activeTabData.price,
              deliveryTime: activeTabData.deliverTime,
              type: activeTabData.activeTab,
            }),
            pop: img,
            paymentType: JSON.stringify({
              type: activePaymentData.text,
            }),
            shipBy: "null",
            deliveryAddress: JSON.stringify(formValues),
            shipTo: JSON.stringify(formValues.address),
            buyerName: auth.user.userName,
            leaveAtDoor: check,
            status: "Confirmando Pedido",
            pointsApplied,
            serviceFee,
            smallOrderFee,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
            onUploadProgress: (data) => {
              //Set the progress value to show the progress bar
              setProgress(Math.round((100 * data.loaded) / data.total));
            },
          }
        )
        .then((res) => {
          setMyResponce(res.data.success);
          setOrder(res.data.allOrdersDetail);
          setOpen(true);
          setProgress(0);
        })
        .catch((err) => {});
    } else {
      axios
        .post("/api/v1/allOrdersDetail/new", {
          buyerId: auth.user._id,
          vendorId: vendorId, //remove
          productDetails: JSON.stringify(cart),
          totalPrice: finalValue - pointsApplied,
          subtotal: totalCartPrice,
          storeName: storeName,
          cellPhone: cellPhone,
          vendorStoreImg: image,
          deliveryTime: JSON.stringify({
            price: activeTabData.price,
            deliveryTime: activeTabData.deliverTime,
            type: activeTabData.activeTab,
          }),
          paymentType: JSON.stringify({
            type: activePaymentData.text,
          }),
          shipBy: "null",
          deliveryAddress: JSON.stringify(formValues),
          shipTo: JSON.stringify(formValues.address),
          buyerName: auth.user.userName,
          leaveAtDoor: check,
          status: "Confirmando Pedido",
          pointsApplied,
          dealApplied: "",
          serviceFee,
          smallOrderFee,
        })
        .then((res) => {
          setMyResponce(res.data.success);
          setOrder(res.data.allOrdersDetail);
          setOpen(true);
        })
        .catch((err) => {});
    }
  };
  const handleClose = () => {
    setOpen(false);
    navigation(-1);
    dispatch({
      type: "RESET_CART",
    });
  };
  const handleRemovePromo = useCallback(
    (id) => {
      const found = cart.find((f) => f.id === id);
      prompt(
        `"${found.name}" ya no se encuentra disponible con la promoción seleccionada, será eliminado del carrito.`,
        "Entendido"
      );
      if (cart.length === 1) {
        dispatch({
          type: "RESET_CART",
        });
        window.history.back();
      } else {
        dispatch({
          type: "REMOVE_FROM_CART",
          id,
        });
      }
    },
    [cart, dispatch]
  );

  return (
    <Grid
      container
      md={12}
      xs={12}
      style={{ backgroundColor: "#F7F7F7", minHeight: "100vh" }}
    >
      <Toaster />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={orderConfirmStyle}>
          <div style={checkOutStyle.order}>
            <img
              alt="basimart"
              style={{ margin: -10, width: 200, height: 100 }}
              src={require("../assets/basi.png")}
            />
            <div
              style={{ width: "100%", height: 1, backgroundColor: "lightgray" }}
            />{" "}
            <div style={checkOutStyle.subtitle}>Recibimos tu pedido de</div>
            <div style={checkOutStyle.subtitle}>
              {cart?.length > 1
                ? `${cart.length} artículo `
                : `${cart.length} artículos `}
            </div>
            <div
              onClick={() => {
                dispatch({ type: "RESET_CART" });
                navigation(-1, { state: { row: order } });
              }}
              style={checkOutStyle.continue}
            >
              {"Seguir comprando"}
              <ArrowForwardIosIcon style={{ fontSize: 12 }} />
            </div>
            <div
              onClick={() => {
                dispatch({ type: "RESET_CART" });
                navigation("/UserOrderDetails", {
                  state: { row: order },
                });
              }}
              style={checkOutStyle.view}
            >
              {"Ver estado del pedido"}
              <ArrowForwardIosIcon style={{ fontSize: 13 }} />
            </div>
          </div>
        </Box>
      </Modal>
      <Grid item style={checkOutStyle.grid} xs={12} md={12}>
        <img
          onClick={() => navigation("/")}
          style={{ width: 220, height: 100, margin: -20 }}
          src={require("../assets/basicheckout.png")}
          alt="alt"
        />
      </Grid>

      <Box sx={{ display: { xs: "none", md: "flex" }, width: "100%" }}>
        <Grid
          style={checkOutStyle.gridCenter}
          container
          xs={12}
          md={12}
          lg={12}
        >
          <Grid
            md={6}
            xs={10}
            style={{ backgroundColor: "white", margin: 2 }}
            item
          >
            <div style={checkOutStyle.divCenter}></div>
            <div>
              <div>
                {formStepper === 0 ? (
                  <>
                    <UserAddress
                      checkOutStyle={checkOutStyle}
                      formValues={formValues}
                      formErrors={formErrors}
                      setFormValues={setFormValues}
                      saveUserAddress={saveUserAddress}
                    />
                  </>
                ) : (
                  <div
                    style={checkOutStyle.step}
                    onClick={() => setFormStepper(0)}
                  >
                    <PlaceIcon style={checkOutStyle.placeIconGray} />
                    <div style={checkOutStyle.placeIconGray2}>
                      Añadir dirección de entrega
                    </div>
                  </div>
                )}
              </div>
              <div>
                {formStepper === 1 ? (
                  <>
                    <div style={{ padding: 20, borderBottomWidth: 1 }}>
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <ScheduleIcon style={checkOutStyle.placeIcon} />
                          <div style={{ fontSize: 15, fontWeight: "600" }}>
                            Elige el tiempo de entrega
                          </div>
                        </div>
                        <UserDeliveryTab
                          setDeliverDisable={setDeliverDisable}
                          data={vendorData}
                          activeTab={activeTab}
                          setActiveTabs={setActiveTabs}
                          setActiveTabData={setActiveTabData}
                          MobileUi={false}
                        />
                      </div>
                      <div style={{ marginBottom: 15 }}>
                        <button
                          disabled={deliverDisable}
                          style={{
                            backgroundColor: deliverDisable
                              ? "lightgray"
                              : "#52AE11",
                            color: deliverDisable ? "black" : "white",
                            ...checkOutStyle.delivery,
                          }}
                          onClick={completeFormStep}
                        >
                          Continuar
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      borderBottomWidth: 1,
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (stepAddress) setFormStepper(1);
                    }}
                  >
                    <ScheduleIcon style={checkOutStyle.placeIconGray} />
                    <div style={checkOutStyle.placeIconGray2}>
                      Tiempos de entrega
                    </div>
                  </div>
                )}
              </div>
              <div>
                {formStepper === 2 ? (
                  <div style={{ padding: 20, borderBottomWidth: 1 }}>
                    <div style={checkOutStyle.stepper}>
                      <AirportShuttleIcon style={checkOutStyle.placeIcon} />
                      <div style={{ fontSize: 15, fontWeight: "600" }}>
                        Instrucciones de entrega
                      </div>
                    </div>
                    <div>
                      <TextField
                        type="text"
                        id="instruction"
                        disabled={false}
                        name="instruction"
                        onChange={handleChange}
                        defaultValue={formValues.instruction}
                        values={formValues.instruction}
                        style={checkOutStyle.textFieldGreen}
                        label="Alguna indicación especial para la entrega"
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        {...label}
                        defaultChecked={check}
                        onClick={handleToggle}
                      />
                      <div>La entrega es en condominio o comunidad cerrada</div>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                      <div
                        style={checkOutStyle.continueButton}
                        onClick={() => {
                          setStepDelivery(true);
                          increaseStepper();
                          if (points <= 0) setFormStepper(3);
                        }}
                      >
                        Continuar
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={checkOutStyle.step}
                    onClick={() => {
                      if (stepAddress && stepDelivery) setFormStepper(2);
                    }}
                  >
                    <AirportShuttleIcon style={checkOutStyle.placeIconGray} />
                    <div style={checkOutStyle.placeIconGray2}>
                      Instrucciones de entrega
                    </div>
                  </div>
                )}
              </div>

              {points > 0 && (
                <div>
                  {formStepper === 3 ? (
                    <>
                      <Box
                        marginBottom={2}
                        padding={2}
                        backgroundColor={"white"}
                        borderRadius={3}
                      >
                        <div style={checkOutStyle.stepper}>
                          <LoyaltyRoundedIcon style={checkOutStyle.placeIcon} />
                          <div style={{ fontSize: 15, fontWeight: "600" }}>
                            Puntos Basi
                          </div>
                        </div>

                        <Typography
                          variant="body2"
                          gutterBottom
                          marginBottom={2.5}
                        >
                          Puedes utilizar hasta{" "}
                          <strong>
                            {commafy(Math.min(points, totalCartPrice), 1)}
                          </strong>{" "}
                          puntos
                        </Typography>
                        <TextField
                          variant="standard"
                          label="Puntos a aplicar"
                          value={tempValue}
                          type="decimal"
                          className={classes.input}
                          inputProps={{
                            inputMode: "decimal",
                            pattern: /^\d+\.\d{0,2}$/,
                            onKeyDown: onlyDecimalNumbers,
                          }}
                          onChange={handleChangePoints}
                          helperText={
                            value < 0 ||
                            value > Math.min(points, totalCartPrice)
                              ? "Monto invalido"
                              : ""
                          }
                        />
                        {parseInt(pointsApplied) < parseInt(totalCartPrice) && (
                          <>
                            <button
                              onClick={increaseStepper}
                              style={{
                                marginTop: "15px",
                                ...checkOutStyle.continueButton,
                                backgroundColor:
                                  value < 0 ||
                                  value > Math.min(points, totalCartPrice)
                                    ? "lightgray"
                                    : "#52AE11",
                                color:
                                  value < 0 ||
                                  value > Math.min(points, totalCartPrice)
                                    ? "black"
                                    : "white",
                              }}
                              disabled={
                                value < 0 ||
                                value > Math.min(points, totalCartPrice)
                              }
                            >
                              Continuar
                            </button>
                          </>
                        )}

                        {parseInt(pointsApplied) ===
                          parseInt(totalCartPrice) && (
                          <>
                            <button
                              onClick={checkOutSubmit}
                              style={{
                                marginTop: "15px",
                                ...checkOutStyle.continueButton,
                              }}
                            >
                              Continuar
                            </button>
                          </>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      <div
                        style={checkOutStyle.step}
                        onClick={() => {
                          if (stepAddress && stepDelivery) setFormStepper(3);
                        }}
                      >
                        <LoyaltyRoundedIcon
                          style={checkOutStyle.placeIconGray}
                        />
                        <div style={checkOutStyle.placeIconGray2}>
                          Puntos Basi
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {pointsApplied < totalCartPrice && (
                <>
                  <div>
                    {formStepper === 4 ? (
                      <div style={{ padding: 20, borderBottomWidth: 1 }}>
                        <div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CreditCard style={checkOutStyle.placeIcon} />
                            <div style={{ fontSize: 15, fontWeight: "600" }}>
                              Selecciona el método de pago
                            </div>
                          </div>

                          <UserPaymentTab
                            data={vendorData}
                            setDeliverDisable={setDeliverDisable}
                            setPaymentDisable={setPaymentDisable}
                            activeTab={activePayment}
                            setActiveTabs={setActivePayment}
                            setActiveTabData={paymentTypeData}
                            MobileUi={false}
                            totalCartPrice={totalCartPrice}
                            pointsApplied={pointsApplied}
                          />
                        </div>

                        <div style={{ marginBottom: 15 }}>
                          <button
                            disabled={paymentDisable}
                            style={{
                              backgroundColor: paymentDisable
                                ? "lightgray"
                                : "#52AE11",
                              color: paymentDisable ? "black" : "white",
                              ...checkOutStyle.delivery,
                            }}
                            onClick={
                              cashOnDelivery ? increaseStepper : checkOutSubmit
                            }
                          >
                            Continuar
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={checkOutStyle.step}
                        onClick={() => {
                          if (stepAddress && stepDelivery) setFormStepper(4);
                        }}
                      >
                        <CreditCard style={checkOutStyle.placeIconGray} />
                        <div style={checkOutStyle.placeIconGray2}>Pago</div>
                      </div>
                    )}
                  </div>
                  <div>
                    {formStepper === 5 && cashOnDelivery ? (
                      <div style={{ padding: 20, borderBottomWidth: 1 }}>
                        <div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <CreditCard style={checkOutStyle.placeIcon} />
                            <div style={{ fontSize: 15, fontWeight: "600" }}>
                              Adjunta el comprobante de pago
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid
                              container
                              md={12}
                              xs={12}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid item md={12} xs={12}>
                                <div style={checkOutStyle.payInstruction}>
                                  <Typography
                                    style={{
                                      fontFamily: "sans-serif",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Instrucciones
                                  </Typography>
                                  {paymentInstruction && (
                                    <p style={{ fontSize: 14 }}>
                                      {paymentInstruction}
                                    </p>
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                item
                                md={12}
                                xs={12}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                }}
                              >
                                <Grid
                                  container
                                  md={4}
                                  lg={4}
                                  xs={4}
                                  sm={4}
                                  xl={4}
                                >
                                  {storeImage && (
                                    <img
                                      src={storeImage}
                                      style={checkOutStyle.storeImage}
                                      alt="alt"
                                    />
                                  )}
                                </Grid>
                                <Grid
                                  container
                                  md={4}
                                  lg={4}
                                  xs={4}
                                  sm={4}
                                  xl={4}
                                >
                                  <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    style={{ display: "none" }}
                                    accept="image/*"
                                    onChange={handleChangeImage}
                                    id="contained-button-file"
                                  />
                                  <label
                                    style={{ alignSelf: "center" }}
                                    htmlFor="contained-button-file"
                                  >
                                    <Button
                                      style={{
                                        border: `1px solid ${Colors.buttonGreen}`,
                                        color: "black",
                                        textTransform: "none",
                                      }}
                                      onClick={handleClick}
                                    >
                                      Subir el comprobante
                                    </Button>
                                  </label>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                        {progress > 0 && (
                          <div
                            style={{
                              width: "82.5%",
                              height: "5px",
                              backgroundColor: "#ccc",
                              margin: "10px 0px",
                            }}
                          >
                            <span
                              style={{
                                width: progress + "%",
                                height: "100%",
                                display: "block",
                                backgroundColor: "green",
                              }}
                            ></span>
                          </div>
                        )}

                        <div style={{ marginBottom: 15 }}>
                          {!submitDisable && (
                            <button
                              onClick={() => {
                                if (stepAddress && stepDelivery)
                                  checkOutSubmit();
                              }}
                              disabled={
                                submitDisable ||
                                !(!cashOnDelivery || uploadImageDisable)
                              }
                              style={{
                                backgroundColor:
                                  !cashOnDelivery || uploadImageDisable
                                    ? "#52AE11"
                                    : "#BDBDBD",
                                width: "82.5%",

                                color:
                                  !cashOnDelivery || uploadImageDisable
                                    ? "white"
                                    : "black",
                                ...checkOutStyle.delivery,
                              }}
                            >
                              Continuar
                            </button>
                          )}
                          {submitDisable && (
                            <button
                              onClick={() => {
                                if (stepAddress && stepDelivery)
                                  checkOutSubmit();
                              }}
                              disabled={submitDisable || uploadImageDisable}
                              style={{
                                backgroundColor: "#BDBDBD",
                                color: uploadImageDisable ? "black" : "white",
                                ...checkOutStyle.delivery,
                              }}
                            >
                              Continuar
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        {cashOnDelivery && (
                          <div
                            style={checkOutStyle.step}
                            onClick={() => {
                              if (stepAddress && stepDelivery)
                                setFormStepper(4);
                            }}
                          >
                            <CreditCard style={checkOutStyle.placeIconGray} />
                            <div style={checkOutStyle.placeIconGray2}>
                              Comprobante de pago
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </Grid>

          <Grid
            md={3}
            xs={10}
            style={{ backgroundColor: "white", margin: 2 }}
            item
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: 50, height: 50 }}
                  src={require("../assets/guaranteeIcon.png")}
                />
                <div>
                  <div
                    style={{
                      fontSize: 14,
                      color: "#3C78C6",
                      fontWeight: "500",
                    }}
                  >
                    100% garantía de satisfacción
                  </div>
                  <div
                    style={{
                      width: 220,
                      fontSize: 14,
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    Haz tu pedido con tranquilidad
                    <div class="tooltip">
                      <HelpOutlineIcon
                        style={{ fontSize: 15, marginLeft: 3 }}
                      />
                      <span
                        style={{ left: -20, top: 30, position: "absolute" }}
                        class="tooltiptext"
                      >
                        Haz tu pedido con tranquilidad
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                <div
                  style={{ fontSize: 14, color: "#393939", fontWeight: "700" }}
                >
                  Subtotal del pedido{" "}
                </div>
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{commafy(totalCartPrice)}
                </div>
              </div>
              {(serviceFee || smallOrderFee) > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        color: "#393939",
                        fontWeight: "700",
                      }}
                    >
                      Tarifas
                    </div>
                  </div>
                  <div style={{ color: "#393939", fontSize: 14 }}>
                    <InfoOutlinedIcon
                      aria-describedby={id}
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                      fontSize="small"
                      className="mr-1 cursor-pointer"
                    />
                    ₡{serviceFee + smallOrderFee}
                  </div>
                  <Popover
                    open={popOverOpen}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <div className="p-2 text-sm">
                      {!!serviceFee && (
                        <div className="flex justify-between gap-4">
                          <span>Tasa de servicio</span>
                          <span>₡{serviceFee}</span>
                        </div>
                      )}
                      {!!smallOrderFee && (
                        <div className="flex justify-between gap-4">
                          <span>Tasa por pedido pequeño</span>
                          <span>₡{smallOrderFee}</span>
                        </div>
                      )}
                    </div>
                  </Popover>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              {deliveryFee ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        color: "#393939",
                        fontWeight: "700",
                      }}
                    >
                      Costo de envío
                    </div>
                    <ArrowForwardIosIcon
                      style={{ fontSize: 11, marginLeft: 3 }}
                    />
                  </div>
                  <div style={{ color: "#393939", fontSize: 14 }}>
                    ₡{deliveryFee}
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
            {!!pointsApplied && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: 14,
                      color: "#393939",
                      fontWeight: "700",
                    }}
                  >
                    Puntos aplicados
                  </div>
                  <ArrowForwardIosIcon
                    style={{ fontSize: 11, marginLeft: 3 }}
                  />
                </div>
                <div style={{ color: "#393939", fontSize: 14 }}>
                  -{commafy(pointsApplied)}
                </div>
              </div>
            )}
            <div
              style={{ width: "100%", backgroundColor: "lightgray", height: 1 }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ fontSize: 14, color: "#393939", fontWeight: "700" }}
                >
                  Total
                </div>
              </div>
              {deliveryFee ? (
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{`${commafy(finalValue - pointsApplied, 3)}`}
                </div>
              ) : (
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡
                  {`${commafy(
                    totalCartPrice + serviceFee + smallOrderFee - pointsApplied,
                    3
                  )}`}
                </div>
              )}
            </div>
            {!!cart?.some(
              (s) =>
                s.dealApplied === "team" &&
                s?.deal &&
                isAfter(parseISO(s.deal.expirationDate), Date.now())
            ) && (
              <Box mt={2} p={1}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: "bold", textAlign: "center" }}
                >
                  Productos de compra en equipo
                </Typography>
                <Typography variant="body2" sx={{ textAlign: "center" }} mb={2}>
                  Estas son las instrucciones para hacer la compra en equipo.
                  Estas son las instrucciones para hacer la compra en equipo.
                  Estas son las instrucciones para hacer la compra en equipo.
                </Typography>
                {cart
                  .filter((f) => f.dealApplied === "team")
                  .map((m) => (
                    <TeamPurchaseProductCard
                      key={m.pId}
                      product={m}
                      onRemovePromo={handleRemovePromo}
                    />
                  ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          marginTop: 5,
          marginBottom: 15,
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 80,
          }}
          container
          xs={12}
          md={12}
          lg={12}
        >
          <Grid
            md={6}
            xs={10}
            style={{ backgroundColor: "white", margin: 2 }}
            item
          >
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            ></div>

            <div>
              <div>
                {formStepper === 0 ? (
                  <>
                    <UserAddress
                      checkOutStyle={checkOutStyle}
                      formValues={formValues}
                      formErrors={formErrors}
                      setFormValues={setFormValues}
                      saveUserAddress={saveUserAddress}
                      isMobile={true}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottomWidth: 1,
                      padding: 20,
                      cursor: "pointer",
                    }}
                    onClick={() => setFormStepper(0)}
                  >
                    <PlaceIcon
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        color: "#72767E",
                      }}
                    >
                      Añadir dirección de entrega
                    </div>
                  </div>
                )}
              </div>
              <div>
                {formStepper === 1 ? (
                  <>
                    <div style={{ padding: 20, borderBottomWidth: 1 }}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ScheduleIcon
                            style={{
                              color: "green",
                              fontSize: 26,
                              marginRight: 10,
                            }}
                          />
                          <div
                            style={{
                              fontSize: 15,
                              fontWeight: "600",
                            }}
                          >
                            Elige el tiempo de entrega
                          </div>
                        </div>
                        <UserDeliveryTab
                          setDeliverDisable={setDeliverDisable}
                          data={vendorData}
                          activeTab={activeTab}
                          setActiveTabs={setActiveTabs}
                          setActiveTabData={setActiveTabData}
                          MobileUi={true}
                        />
                      </div>
                      <div style={{ marginBottom: 15 }}>
                        <button
                          disabled={deliverDisable}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            backgroundColor: deliverDisable
                              ? "lightgray"
                              : "#52AE11",
                            alignItems: "center",
                            padding: 8,
                            borderRadius: 5,
                            borderWidth: 1,
                            cursor: "pointer",
                            fontWeight: "600",
                            color: deliverDisable ? "black" : "white",
                          }}
                          onClick={() => {
                            completeFormStep();
                            setStepDelivery(true);
                          }}
                        >
                          Continuar
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      borderBottomWidth: 1,
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (stepAddress) setFormStepper(1);
                    }}
                  >
                    <ScheduleIcon
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        color: "#72767E",
                      }}
                    >
                      Tiempos de entrega
                    </div>
                  </div>
                )}
              </div>
              <div>
                {formStepper === 2 ? (
                  <div style={{ padding: 20, borderBottomWidth: 1 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <AirportShuttleIcon
                        style={{
                          color: "green",
                          fontSize: 30,
                          marginRight: 10,
                        }}
                      />
                      <div style={{ fontSize: 15, fontWeight: "600" }}>
                        Instrucciones de entrega
                      </div>
                    </div>
                    <div>
                      <TextField
                        type="text"
                        id="instruction"
                        disabled={false}
                        name="instruction"
                        onChange={handleChange}
                        defaultValue={formValues.instruction}
                        values={formValues.instruction}
                        style={{
                          borderWidth: 1,
                          color: "green",
                          borderColor: "black",
                          width: "100%",
                          marginBottom: 10,
                        }}
                        label="Alguna indicación especial para la entrega"
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        {...label}
                        defaultChecked={check}
                        onClick={handleToggle}
                      />
                      <div>La entrega es en condominio o comunidad cerrada</div>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: 100,
                          backgroundColor: "#52AE11",
                          alignItems: "center",
                          padding: 8,
                          borderRadius: 10,
                          borderWidth: 1,
                          cursor: "pointer",
                          color: "white",
                        }}
                        onClick={() => {
                          increaseStepper();
                          if (points <= 0) setFormStepper(3);
                        }}
                      >
                        Continuar
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      borderBottomWidth: 1,

                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (stepAddress && stepDelivery) setFormStepper(2);
                    }}
                  >
                    <AirportShuttleIcon
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        color: "#72767E",
                        fontSize: 15,
                        fontWeight: "600",
                      }}
                    >
                      Instrucciones de entrega
                    </div>
                  </div>
                )}
              </div>

              <>
                {points > 0 && (
                  <div>
                    {formStepper === 3 ? (
                      <>
                        <Box
                          marginBottom={2}
                          padding={2}
                          backgroundColor={"white"}
                          borderRadius={3}
                        >
                          <div style={checkOutStyle.stepper}>
                            <LoyaltyRoundedIcon
                              style={checkOutStyle.placeIcon}
                            />
                            <div style={{ fontSize: 15, fontWeight: "600" }}>
                              Puntos Basi
                            </div>
                          </div>

                          <Typography
                            variant="body2"
                            gutterBottom
                            marginBottom={2.5}
                          >
                            Puedes utilizar hasta{" "}
                            <strong>
                              {commafy(Math.min(points, totalCartPrice), 1)}
                            </strong>{" "}
                            puntos
                          </Typography>
                          <TextField
                            variant="standard"
                            label="Puntos a aplicar"
                            type="decimal"
                            className={classes.input}
                            inputProps={{
                              inputMode: "decimal",
                              pattern: /^\d+\.\d{0,2}$/,
                              onKeyDown: onlyDecimalNumbers,
                            }}
                            value={tempValue}
                            onChange={handleChangePoints}
                            helperText={
                              value < 0 ||
                              value > Math.min(points, totalCartPrice)
                                ? "Monto invalido"
                                : ""
                            }
                          />

                          {pointsApplied < totalCartPrice && (
                            <>
                              <button
                                onClick={increaseStepper}
                                style={{
                                  marginTop: "15px",
                                  ...checkOutStyle.continueButton,
                                  backgroundColor:
                                    value < 0 ||
                                    value > Math.min(points, totalCartPrice)
                                      ? "lightgray"
                                      : "#52AE11",
                                  color:
                                    value < 0 ||
                                    value > Math.min(points, totalCartPrice)
                                      ? "black"
                                      : "white",
                                }}
                                disabled={
                                  value < 0 ||
                                  value > Math.min(points, totalCartPrice)
                                }
                              >
                                Continuar
                              </button>
                            </>
                          )}

                          {pointsApplied === totalCartPrice && (
                            <>
                              <button
                                onClick={checkOutSubmit}
                                style={{
                                  marginTop: "15px",
                                  ...checkOutStyle.continueButton,
                                }}
                              >
                                Continuar
                              </button>
                            </>
                          )}
                        </Box>
                      </>
                    ) : (
                      <>
                        <div
                          style={checkOutStyle.step}
                          onClick={() => {
                            if (stepAddress && stepDelivery) setFormStepper(3);
                          }}
                        >
                          <LoyaltyRoundedIcon
                            style={checkOutStyle.placeIconGray}
                          />
                          <div style={checkOutStyle.placeIconGray2}>
                            Puntos Basi
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>

              {pointsApplied < totalCartPrice && (
                <>
                  <div>
                    {formStepper === 4 ? (
                      <div style={{ padding: 20, borderBottomWidth: 1 }}>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <CreditCard
                              style={{
                                color: "green",
                                fontSize: 26,
                                marginRight: 10,
                              }}
                            />
                            <div
                              style={{
                                fontSize: 15,
                                fontWeight: "600",
                              }}
                            >
                              Selecciona el método de pago
                            </div>
                          </div>

                          <UserPaymentTab
                            data={vendorData}
                            setDeliverDisable={setDeliverDisable}
                            setPaymentDisable={setPaymentDisable}
                            activeTab={activePayment}
                            setActiveTabs={setActivePayment}
                            setActiveTabData={paymentTypeData}
                            MobileUi={true}
                            totalCartPrice={totalCartPrice}
                            pointsApplied={pointsApplied}
                          />
                        </div>
                        {cashOnDelivery && (
                          <div style={{ marginBottom: 15 }}>
                            <button
                              disabled={paymentDisable}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                backgroundColor: paymentDisable
                                  ? "lightgray"
                                  : "#52AE11",
                                alignItems: "center",
                                padding: 8,
                                borderRadius: 5,
                                borderWidth: 1,
                                cursor: "pointer",
                                color: paymentDisable ? "black" : "white",
                              }}
                              onClick={() => {
                                setStepPayment(true);
                                cashOnDelivery
                                  ? increaseStepper()
                                  : checkOutSubmit();
                              }}
                            >
                              Continuar
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          padding: 20,
                          borderBottomWidth: 1,

                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (stepAddress && stepDelivery) setFormStepper(4);
                        }}
                      >
                        <CreditCard
                          style={{
                            color: "#72767E",
                            fontSize: 23,
                            marginRight: 10,
                          }}
                        />
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                            color: "#72767E",
                          }}
                        >
                          Pago
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {formStepper === 5 && cashOnDelivery ? (
                      <div style={{ padding: 20, borderBottomWidth: 1 }}>
                        <div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <CreditCard
                              style={{
                                color: "green",
                                fontSize: 26,
                                marginRight: 10,
                              }}
                            />
                            <div
                              style={{
                                fontSize: 15,
                                fontWeight: "600",
                              }}
                            >
                              Adjunta el comprobante de pago
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              container
                              md={12}
                              lg={12}
                              xs={12}
                              sm={12}
                              xl={12}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid
                                item
                                md={12}
                                lg={12}
                                xs={12}
                                sm={12}
                                xl={12}
                                style={{}}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "1px solid #ccc",
                                    borderRadius: "6px",
                                    padding: 15,
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: "sans-serif",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    Instrucciones
                                  </Typography>
                                  {paymentInstruction && (
                                    <p style={{ fontSize: 14 }}>
                                      {paymentInstruction}
                                    </p>
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                item
                                md={12}
                                lg={12}
                                xs={12}
                                sm={12}
                                xl={12}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                }}
                              >
                                <Grid
                                  container
                                  md={4}
                                  lg={4}
                                  xs={4}
                                  sm={4}
                                  xl={4}
                                >
                                  {storeImage && (
                                    <img
                                      src={storeImage}
                                      style={{
                                        display: "flex",
                                        width: 250,
                                        height: 100,
                                        alignSelf: "center",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifySelf: "center",
                                        border: "1px solid #ccc",
                                      }}
                                    />
                                  )}
                                </Grid>
                                <Grid
                                  container
                                  md={4}
                                  lg={4}
                                  xs={4}
                                  sm={4}
                                  xl={4}
                                >
                                  <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    style={{ display: "none" }}
                                    accept="image/*"
                                    onChange={handleChangeImage}
                                    id="contained-button-file"
                                  />
                                  <label
                                    style={{ alignSelf: "center" }}
                                    htmlFor="contained-button-file"
                                  >
                                    <Button
                                      style={{
                                        border: `1px solid ${Colors.buttonGreen}`,
                                        color: "black",
                                        textTransform: "none",
                                      }}
                                      onClick={handleClick}
                                    >
                                      Subir el comprobante
                                    </Button>
                                  </label>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {cashOnDelivery && (
                          <div
                            style={{
                              display: "flex",
                              padding: 20,
                              borderBottomWidth: 1,

                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (stepAddress && stepDelivery)
                                setFormStepper(4);
                            }}
                          >
                            <CreditCard
                              style={{
                                color: "#72767E",
                                fontSize: 23,
                                marginRight: 10,
                              }}
                            />
                            <div
                              style={{
                                fontSize: 15,
                                fontWeight: "600",
                                color: "#72767E",
                              }}
                            >
                              Comprobante de Pago
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </Grid>
          {/* guarantee area */}
          <Grid
            md={2.5}
            xs={10}
            style={{ backgroundColor: "white", margin: 2 }}
            item
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: 50, height: 50 }}
                  src={require("../assets/guaranteeIcon.png")}
                />
                <div>
                  <div
                    style={{
                      fontSize: 14,
                      color: "#3C78C6",
                      fontWeight: "500",
                    }}
                  >
                    100% garantía de satisfacción
                  </div>
                  <div
                    style={{
                      width: 220,
                      fontSize: 14,
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    Haz tu pedido con tranquilidad
                    <div class="tooltip">
                      <HelpOutlineIcon
                        style={{ fontSize: 15, marginLeft: 3 }}
                      />
                      <span
                        style={{ left: -20, top: 30, position: "absolute" }}
                        class="tooltiptext"
                      >
                        Haz tu pedido con tranquilidad
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                <div
                  style={{ fontSize: 14, color: "#393939", fontWeight: "700" }}
                >
                  Subtotal
                </div>
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{commafy(totalCartPrice)}
                </div>
              </div>
              {(serviceFee || smallOrderFee) > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        color: "#393939",
                        fontWeight: "700",
                      }}
                    >
                      Tarifas
                    </div>
                  </div>
                  <div style={{ color: "#393939", fontSize: 14 }}>
                    <InfoOutlinedIcon
                      aria-describedby={id}
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                      fontSize="small"
                      className="mr-1 cursor-pointer"
                    />
                    ₡{serviceFee + smallOrderFee}
                  </div>
                  <Popover
                    open={popOverOpen}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <div className="p-2 text-sm">
                      {!!serviceFee && (
                        <div className="flex justify-between gap-4">
                          <span>Tasa de servicio</span>
                          <span>₡{serviceFee}</span>
                        </div>
                      )}
                      {!!smallOrderFee && (
                        <div className="flex justify-between gap-4">
                          <span>Tasa por pedido pequeño</span>
                          <span>₡{smallOrderFee}</span>
                        </div>
                      )}
                    </div>
                  </Popover>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              {deliveryFee ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        color: "#393939",
                        fontWeight: "700",
                      }}
                    >
                      Costo de envío
                    </div>
                    <ArrowForwardIosIcon
                      style={{ fontSize: 11, marginLeft: 3 }}
                    />
                  </div>
                  <div style={{ color: "#393939", fontSize: 14 }}>
                    ₡{deliveryFee}
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
            <div
              style={{ width: "100%", backgroundColor: "lightgray", height: 1 }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ fontSize: 14, color: "#393939", fontWeight: "700" }}
                >
                  Total
                </div>
              </div>
              {deliveryFee ? (
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{`${commafy(finalValue)}`}
                </div>
              ) : (
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡
                  {`${commafy(
                    totalCartPrice + serviceFee + smallOrderFee - pointsApplied
                  )}`}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            backgroundColor: "white",
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            width: "100%",
            bottom: 0,
          }}
          xs={12}
          md={12}
        >
          {!submitDisable && (
            <button
              onClick={() => {
                if (stepAddress && stepDelivery) checkOutSubmit();
              }}
              disabled={
                submitDisable || !(!cashOnDelivery || uploadImageDisable)
              }
              style={{
                backgroundColor:
                  !cashOnDelivery || uploadImageDisable ? "#52AE11" : "#BDBDBD",
                width: "80%",
                paddingTop: 12,
                paddingBottom: 12,
                alignContent: "center",
                alignItems: "center",
                borderRadius: 8,
                display: "flex",
                justifyContent: "center",
                color:
                  !cashOnDelivery || uploadImageDisable ? "white" : "black",
                cursor: "pointer",
              }}
            >
              Continuar
            </button>
          )}
          {submitDisable && (
            <button
              onClick={() => {
                if (stepAddress && stepDelivery) checkOutSubmit();
              }}
              disabled={submitDisable}
              style={{
                backgroundColor: "#BDBDBD",
                width: "80%",
                paddingTop: 12,
                paddingBottom: 12,
                alignContent: "center",
                alignItems: "center",
                borderRadius: 8,
                display: "flex",
                justifyContent: "center",
                color: uploadImageDisable ? "black" : "white",
                cursor: "pointer",
              }}
            >
              Continuar
            </button>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default UserCheckOut;

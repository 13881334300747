import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ExploreIcon from "@mui/icons-material/Explore";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import TagIcon from "@mui/icons-material/LocalOffer";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import AuthContext from "../../../context/AuthContext";
//@ts-ignore
import DrawerCategories from "./DrawerCategories";
import clsx from "clsx";
import StoreInfo from "../../storeInfo/StoreInfo";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";

type Menu = {
  name: string;
  icon: any;
  path: string;
};

const menus: Menu[] = [
  {
    name: "Explorar",
    icon: ExploreIcon,
    path: "/",
  },
  {
    name: "Bites",
    icon: HistoryToggleOffIcon,
    path: "/stories",
  },
  {
    name: "Tienda",
    icon: ShoppingBagIcon,
    path: "/store",
  },
  {
    name: "Ofertas",
    icon: TagIcon,
    path: "/offers",
  },
  {
    name: "Mi Basi",
    icon: CardGiftcardIcon,
    path: "/my-basi",
  },
];

const DrawerContent = ({ isMobile, store }: any) => {
  const navigation = useNavigate();
  const { user } = useContext(AuthContext);
  const location = useLocation();

  return (
    <div
      className="flex flex-col"
      style={{
        overflowY: "hidden",
        height: isMobile ? "100%" : "calc(100% - 66px)",
      }}
    >
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
            marginLeft: 10,
          }}
          onClick={() => navigation("/")}
        >
          <ArrowBackIosIcon
            style={{
              fontSize: 16,
              paddingTop: 1,
              cursor: "pointer",
            }}
          />
          <div
            style={{
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            Ver Tiendas
          </div>
        </div>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <div
          style={{
            width: "100%",
            height: 1,
            backgroundColor: "lightgray",
            overflowY: "hidden",
          }}
        />
      </Box>

      <StoreInfo store={store} />

      {menus.map((menu, index) => {
        const Icon = menu.icon;
        const path = `/tiendas/${store.storeName}${menu.path}`;
        const isActive =
          menu.path === "/"
            ? location.pathname === `/tiendas/${store.storeName}` ||
              location.pathname === path
            : location.pathname === path;
        if (menu.path === "/my-basi" && !user?._id) {
          return null;
        }
        return (
          <div
            key={index}
            className={clsx(
              "font-bold",
              { "bg-white": !isActive, "bg-[#343538]": isActive },
              "rounded cursor-pointer p-2 mx-1"
            )}
            onClick={() => navigation(path)}
          >
            <div
              className={clsx({
                "text-[#343538]": !isActive,
                "text-white": isActive,
              })}
            >
              <Icon className="text-lg -mt-0.5" /> {menu.name}
            </div>
          </div>
        );
      })}

      <div
        className="mt-3"
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "lightgray",
        }}
      />

      <DrawerCategories isMobile={isMobile} />
    </div>
  );
};

export default DrawerContent;

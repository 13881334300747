import React, { FC, useContext, useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import AuthContext from "../../../context/AuthContext";
import Grid from "@mui/material/Unstable_Grid2";
import { IconButton } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { basiCardStyle } from "../../../components/style/basiCardStyle";
import axios from "../../../axios";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import { isMobile } from "react-device-detect";
import { commafy } from "../../../utils/price";
import { useBalance } from "../../../context/BalanceContext";
import InfoModal from "./InfoModal";

const BasiCard: FC = () => {
  const { user } = useContext(AuthContext);
  const { points, balance, pending, fetchBalance } = useBalance();
  const [add, setAdd] = useState(0);
  const [openAdd, setOpenAdd] = useState(false);
  const [openContinue, setContinue] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [img, setImg] = useState(null);
  const [upload, setUpload] = useState(false);

  const handleOpen = () => {
    setOpenAdd(true);
    setConfirm(add === 0 ? true : false);
  };

  const handleChange = (e: {
    target: { value: React.SetStateAction<any> };
  }) => {
    setAdd(e.target.value);
    setConfirm(false);
  };

  const handleSend = (e: { preventDefault: any; target: any }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    e?.preventDefault();
    setConfirm(false);
    setContinue(false);

    axios
      .post(
        `/api/v1/basiCard/create-transaction/${user?._id}`,
        {
          total: add,
          type: "+",
          description: "adding funds to the card",
          image: img,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then(() => {
        fetchBalance();
        setImg(null);
        setUpload(false);
        setAdd(0);
      });
  };

  const handleChangeImage = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        setImg(event.target.files[0]);
        setUpload(true);
      };
    }
  };

  const handleContinue = (e: { preventDefault: any } | undefined) => {
    setConfirm(false);
    setOpenAdd(false);
    setContinue(true);
    setImg(null);
    setUpload(false);
  };

  const handleClose = () => {
    setOpenAdd(false);
    setConfirm(false);
  };

  const handleCloseContinue = () => {
    setContinue(false);
    setConfirm(false);
    setAdd(0);
  };

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  return (
    <Box className="flex items-center relative">
      <Grid container xs={12} md={12}>
        <Grid xs={12} md={12}>
          <h1>Basi Card</h1>
        </Grid>

        <Grid xs={12} md={12}>
          <div
            style={
              !isMobile
                ? basiCardStyle.cardContainer
                : basiCardStyle.cardContainerMobile
            }
          >
            <Grid container>
              <Grid xs={9} md={9}>
                <p style={basiCardStyle.tittle}>
                  {points > 0 ? commafy((points * 1).toFixed(2)) : 0} Puntos
                </p>
              </Grid>
              <Grid xs={3} md={3}>
                <p style={basiCardStyle.bigTittle}>basi</p>
              </Grid>
            </Grid>

            <div style={{ paddingBottom: "10px" }}></div>

            <Grid container style={{ marginTop: pending > 0 ? "0px" : "10px" }}>
              <Grid xs={9} md={9}>
                <p style={basiCardStyle.tittle}>Balance</p>
                <p style={basiCardStyle.bigTittle}>₡{commafy(balance)}</p>
              </Grid>
              <Grid xs={3} md={3}>
                <Button onClick={handleOpen}>
                  <AddCircleOutlineRoundedIcon style={basiCardStyle.addIcon} />
                </Button>
                {openAdd && (
                  <div>
                    <Dialog
                      PaperProps={{
                        sx: {
                          margin: isMobile ? 2 : undefined,
                        },
                      }}
                      open={openAdd}
                      onClose={handleClose}
                    >
                      <DialogTitle style={{ fontSize: "12px" }}>
                        Agregar fondos
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          component="form"
                          style={
                            !isMobile
                              ? basiCardStyle.add
                              : basiCardStyle.addMobile
                          }
                        >
                          <FormControl fullWidth>
                            <InputLabel id="add" />
                            <Select
                              labelId="demo-simple-select-label"
                              id="add"
                              value={add}
                              label="Agregar"
                              onChange={handleChange}
                            >
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value={5000}
                              >
                                ₡5,000.00
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value={10000}
                              >
                                ₡10,000.00
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value={20000}
                              >
                                ₡20,000.00
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value={50000}
                              >
                                ₡50,000.00
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value={75000}
                              >
                                ₡75,000.00
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value={100000}
                              >
                                ₡100,000.00
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value={150000}
                              >
                                ₡150,000.00
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value={200000}
                              >
                                ₡200,000.00
                              </MenuItem>
                            </Select>
                            <button
                              disabled={confirm}
                              onClick={(e) => handleContinue(e)}
                              style={{
                                backgroundColor: confirm
                                  ? "#757575"
                                  : "#52AE11",
                                ...basiCardStyle.confirmButton,
                                marginTop: !isMobile ? "30px" : "65px",
                              }}
                            >
                              continuar <ArrowForwardRoundedIcon />
                            </button>
                          </FormControl>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </div>
                )}

                {openContinue && (
                  <div>
                    <Dialog
                      PaperProps={{
                        sx: {
                          margin: isMobile ? 2 : undefined,
                        },
                      }}
                      open={openContinue}
                      onClose={handleCloseContinue}
                    >
                      <DialogTitle
                        style={{ fontSize: "13px", fontWeight: "bold" }}
                      >
                        Instrucciones de Pago
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          component="form"
                          style={
                            !isMobile
                              ? basiCardStyle.instructions
                              : basiCardStyle.instructionsMobile
                          }
                        >
                          <FormControl fullWidth>
                            <div>
                              <p style={{ fontSize: "11px" }}>
                                1.Realiza tu pago via <b>SINPE Movil</b> o{" "}
                                <b>Transferencia Bancaria</b>. Envia el monto
                                correspondiente a <b> ₡{add} </b>.
                              </p>

                              <p style={{ fontSize: "11px" }}>
                                -Banco: Cuenta de Banco A XXXXXXXXXXXXX Cuenta
                                de Banco B XXXXXXXXXXXXX
                              </p>

                              <p style={{ fontSize: "11px" }}>
                                2. Adjunta el comprobante en el campo abajo.
                              </p>
                            </div>
                            <div style={basiCardStyle.box}>
                              <input
                                type="file"
                                style={{ display: "none" }}
                                accept="image/*"
                                id="contained-button-file"
                                onChange={handleChangeImage}
                              />
                              <label htmlFor="contained-button-file">
                                <IconButton
                                  component="span"
                                  style={{ marginTop: !isMobile ? "0" : "5px" }}
                                >
                                  <DriveFolderUploadRoundedIcon />
                                </IconButton>
                              </label>
                              <p
                                style={{
                                  fontSize: "11px",
                                  marginTop: !isMobile ? "12px" : "20px",
                                  marginLeft: "3px",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                Subir comprobante
                              </p>
                            </div>
                            {upload && (
                              <button
                                onClick={(e) => handleSend(e)}
                                style={{
                                  backgroundColor: "#52AE11",
                                  ...basiCardStyle.continueButton,
                                  marginTop: !isMobile ? "10px" : "35px",
                                }}
                              >
                                confirmar
                              </button>
                            )}

                            {!upload && (
                              <button
                                disabled={true}
                                style={{
                                  backgroundColor: "#757575",
                                  ...basiCardStyle.continueButton,
                                  marginTop: !isMobile ? "10px" : "35px",
                                }}
                              >
                                confirmar
                              </button>
                            )}
                          </FormControl>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </div>
                )}
              </Grid>
            </Grid>

            {pending > 0 && (
              <>
                <p
                  style={{
                    fontSize: "11px",
                    marginTop: "-10px",
                    paddingLeft: "4px",
                  }}
                >
                  (₡{commafy(pending)} por aprobar)
                </p>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <InfoModal
        title="Basi Card"
        content={`La tarjeta Basi (Basi Card) es una tarjeta digital que puedes utilizar de forma opcional como un método de pago cuando realices tus compras en Basimart y que te da hasta 3% de cashback en Puntos Basi al realizar tus pagos con ella.

        Ofrecemos la Tarjeta Basimart para que te resulte más fácil comprar y para que tu relación con Basi sea más gratificante.

        Basi Card no es una tarjeta de crédito ni débito por lo que no paga comisiones ni intereses. Simplemente carga tu saldo y disfruta de todos sus beneficios`}
      />
    </Box>
  );
};

export default BasiCard;

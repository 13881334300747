import React, { FC, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "../../../../axios";
import AuthContext from "../../../../context/AuthContext";
import { useBalance } from "../../../../context/BalanceContext";
import InfoModal from "../InfoModal";

const DailyCheckIn: FC = () => {
  const authUser = useContext(AuthContext);
  const { fetchBalance } = useBalance();
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [lastCheckIn, setLast] = useState(0);
  const [canCheckInNow, setCanCheckInNow] = useState(false);
  const [total, setTotal] = useState(0);
  const [claim, setClaim] = useState(false);
  const [addedPoints, setAddedPoints] = useState(false);

  useEffect(() => {
    const check = () => {
      axios
        .get(`/api/v1/user/lastCheckIn/${authUser?.user?._id}`)
        .then((res) => {
          setLast(res?.data?.lastCheckIn?.lastCheckIn);
          setCanCheckInNow(res?.data?.lastCheckIn?.canCheckInNow ?? true);
          setTotal(res?.data?.lastCheckIn?.total);
          setClaim(res?.data?.lastCheckIn?.claim);
        });
    };
    check();
  }, [authUser?.user?._id]);

  const handleCheckIn = () => {
    setLoading(true);
    axios
      .get(`/api/v1/user/check-in/${authUser?.user?._id}`)
      .then((res) => {
        if (!authUser?.user) {
          return;
        }

        if (res.data.success) {
          setErrorCode("");
          setLast(res?.data?.lastCheckIn?.lastCheckIn);
          setCanCheckInNow(res?.data?.lastCheckIn?.canCheckInNow);
          setTotal(res?.data?.lastCheckIn?.total);
          setClaim(res?.data?.lastCheckIn?.claim);
          return fetchBalance();
        } else {
          setErrorCode(res.data.code);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClaim = () => {
    setLoading(true);
    axios
      .get(`/api/v1/user/claimCheckIn/${authUser?.user?._id}`)
      .then((res) => {
        if (!authUser?.user) {
          return;
        }

        if (res.data.success) {
          setErrorCode("");
          setLast(res?.data?.lastCheckIn?.lastCheckIn);
          setCanCheckInNow(res?.data?.lastCheckIn?.canCheckInNow);
          setTotal(res?.data?.lastCheckIn?.total);
          setClaim(res?.data?.lastCheckIn?.claim);
          setAddedPoints(res?.data?.addedPoints);
          return fetchBalance();
        } else {
          setErrorCode(res.data.code);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box className="relative">
      <h1 style={{ paddingTop: "5px", paddingLeft: "5px" }}>Check-in</h1>
      <Typography component="p" color="gray" variant="caption">
        Haz check-in 10 días para acumular puntos que luego podrás canjear en
        tus compras.
      </Typography>
      <Box
        className="flex flex-col"
        alignItems="center"
        justifyContent="center"
        padding={2}
      >
        <Typography gutterBottom>Acumulado {total} Puntos</Typography>
        <Box className="flex mb-6" alignItems="center" justifyContent="center">
          {Array(10)
            .fill(0)
            .map((_, i) => {
              return (
                <Box
                  key={i}
                  className="flex flex-col"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="caption">{i + 1}</Typography>
                  <>
                    {i + 1 <= lastCheckIn ? (
                      <>
                        <CheckCircleIcon color="primary" />
                      </>
                    ) : (
                      <CheckCircleOutlineIcon color="disabled" />
                    )}
                  </>
                </Box>
              );
            })}
        </Box>
        {!claim && (
          <>
            <LoadingButton
              variant="outlined"
              loading={loading}
              disabled={!canCheckInNow}
              onClick={handleCheckIn}
            >
              Check-in
            </LoadingButton>
          </>
        )}
        {claim && (
          <>
            <LoadingButton
              variant="contained"
              style={{
                background: "#54ad0f",
                color: "white",
                borderRadius: "5px",
                padding: "3.5px",
                margin: "1px",
              }}
              loading={loading}
              onClick={handleClaim}
            >
              Reclamar
            </LoadingButton>
          </>
        )}
        {addedPoints && (
          <Typography textAlign="center" fontSize="12px" marginTop="12px">
            ¡Has reclamado tu premio! 🎉
          </Typography>
        )}
        {!!errorCode && (
          <Typography
            marginTop={1}
            variant="caption"
            color="red"
            textAlign="center"
          >
            {errorCode === "already_checked_in" && "Ya hiciste check-in hoy"}
            {errorCode === "unknown" && "Ha ocurrido un error"}
          </Typography>
        )}
      </Box>
      <InfoModal
        title="Check-in"
        content={`Con nuestro Check-in diario, te premiamos por visitar Basi. Al iniciar sesión cada día, acumulas puntos Basi, si lo haces durante 10 días seguidos, los puntos se te cargan a tu tarjeta Basi.

        Estos puntos los puedes utilizar para pagar tu compra total o parcial en Basimart y son tu boleto para beneficios exclusivos y descuentos especiales durante el checkout.

        ¡Disfruta de una experiencia más gratificante cada vez que te conectas!
        `}
      />
    </Box>
  );
};

export default DailyCheckIn;

import React, { CSSProperties, FC, ReactNode } from "react";
import { parseISO, isAfter } from "date-fns";
import Countdown from "react-countdown";
import { Typography } from "@mui/material";

type Props = {
  expirationDate: string;
  label?: string;
  className?: string;
  style?: CSSProperties;
  slotStyle?: CSSProperties;
};

export const DealCountdown: FC<Props> = ({
  expirationDate,
  label = "Oferta termina en:",
  className,
  style,
  slotStyle,
}) => {
  if (!isAfter(parseISO(expirationDate), Date.now())) {
    return null;
  }

  return (
    <Countdown
      date={parseISO(expirationDate)}
      renderer={({ days, hours, minutes, seconds }) => {
        const stack: ReactNode[] = [];

        const slot = (value: number, label: string) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <strong
              style={{
                border: "1px solid #c9c9c9",
                borderRadius: 3,
                padding: "0 4px",
                width: 30,
                margin: "0 2px",
                textAlign: "center",
                fontSize: 14,
                ...slotStyle,
              }}
            >
              {String(value).padStart(2, "0")}
            </strong>
            <small style={{ color: "#969696", fontSize: "60%" }}>{label}</small>
          </div>
        );

        if (days) stack.push(slot(days, "D"));
        if (hours || stack.length) stack.push(slot(hours, "H"));
        if (minutes || stack.length) stack.push(slot(minutes, "M"));
        if (seconds || stack.length) stack.push(slot(seconds, "S"));

        return (
          <div className={className} style={style}>
            <Typography variant="caption">{label}</Typography>
            <div style={{ display: "flex" }}>
              {stack.map((m, i) => (
                <>
                  {!!i && <div>:</div>}
                  {m}
                </>
              ))}
            </div>
          </div>
        );
      }}
    />
  );
};

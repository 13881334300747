import React, { FC } from "react";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { DealType, ProductDeal, TeamDealStatus } from "../types";
import { commafy } from "../utils/price";
import { parseISO, isAfter } from "date-fns";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Box,
} from "@mui/material";
import { DealCountdown } from "./DealCountdown";

type Props = {
  price: string;
  deal?: ProductDeal;
  dealStatus?: TeamDealStatus;
  compact?: boolean;
  compareAgainst?: string;
  showDealSelector?: boolean;
  showCountdown?: boolean;
  dealApplied?: DealType | "none";
  onDealChange?: (deal: DealType | "none") => void;
};

export const DealPrice: FC<Props> = ({
  price,
  deal,
  dealStatus,
  compact,
  compareAgainst,
  showDealSelector = false,
  showCountdown = false,
  dealApplied = "none",
  onDealChange,
}) => {
  if (
    !deal ||
    !isAfter(
      parseISO(deal.expirationDate),
      compareAgainst ? parseISO(compareAgainst) : Date.now()
    ) ||
    (deal.type === "team" &&
      dealStatus &&
      dealStatus.teamsQuantity <= dealStatus.currentTeam)
  ) {
    return <>{`₡${commafy(price)}`}</>;
  }

  const percentage =
    typeof deal.value === "number" ? deal.value : deal.value.percentage;

  if (!compact && showDealSelector && ["team"].includes(deal.type)) {
    return (
      <RadioGroup
        value={dealApplied || "none"}
        sx={{ marginLeft: 1.5, marginRight: -2 }}
        onChange={(e) => onDealChange?.(e.target.value as DealType)}
      >
        <FormControlLabel
          value={deal.type}
          control={<Radio />}
          sx={{
            border: "1px solid lightgrey",
            borderBottom: "none",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundColor:
              dealApplied !== deal.type ? "rgb(246, 247, 248)" : "white",
          }}
          label={
            <Box pt={1} pb={1}>
              <Typography variant="body2">Compra en equipo</Typography>
              <Typography>
                {`₡${commafy(Number(price) * (1 - percentage / 100))} `}{" "}
                <Typography variant="caption" color="red">
                  (AHORRA {percentage}%)
                </Typography>
              </Typography>
              {showCountdown && (
                <DealCountdown expirationDate={deal.expirationDate} />
              )}
            </Box>
          }
        />
        <FormControlLabel
          value="none"
          control={<Radio />}
          sx={{
            border: "1px solid lightgrey",
            borderTop: "none",
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            backgroundColor:
              dealApplied !== "none" ? "rgb(246, 247, 248)" : "white",
          }}
          label={
            <Box pt={1} pb={1}>
              <Typography variant="body2">Precio Normal</Typography>
              <Typography>{`₡${commafy(price)}`}</Typography>
            </Box>
          }
        />
      </RadioGroup>
    );
  }

  return (
    <>
      <div>
        {deal.type === "team" && (
          <Diversity3Icon
            sx={{
              fontSize: "inherit",
              marginRight: "4px",
              verticalAlign: "baseline",
            }}
            color="primary"
          />
        )}
        {`₡${commafy(Number(price) * (1 - percentage / 100))} `}
        {!compact && (
          <>
            <small
              style={{
                backgroundColor: "red",
                color: "white",
                borderRadius: 10,
                padding: "2px 5px",
                fontSize: "60%",
              }}
            >
              -{percentage}%
            </small>
            <div style={{ marginTop: -12 }}>
              <small
                style={{ textDecorationLine: "line-through", fontSize: "70%" }}
              >
                {`₡${commafy(price)}`}
              </small>
            </div>
          </>
        )}
      </div>
      {showCountdown && !compact && (
        <DealCountdown expirationDate={deal.expirationDate} />
      )}
    </>
  );
};

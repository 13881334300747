import React, { FC, useCallback, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RWebShare } from "react-web-share";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import useIsMobile from "../../hooks/useIsMobile";
import { Snackbar } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useStateValue } from "../../ContextApi/StateProvider";

type Props = {
  productId: string;
  size?: number;
  style?: React.CSSProperties;
};

export const ShareProduct: FC<Props> = ({ productId, size = 20, style }) => {
  const [{ store }] = useStateValue();
  const [copied, setCopied] = useState(false);
  const isMobile = useIsMobile();
  const location = useLocation();
  const storeName =
    (location?.state as any)?.storeData?.storeName || store?.storeName;

  const handleClose = useCallback(
    (_: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setCopied(false);
    },
    []
  );

  const url = `${window.location.origin}/tiendas/${storeName}?product=${productId}`;

  return isMobile ? (
    <RWebShare
      data={{
        url,
        title: "Compartir via",
      }}
    >
      <button>
        <ShareOutlinedIcon style={style} sx={{ fontSize: size }} />
      </button>
    </RWebShare>
  ) : (
    <>
      <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
        <button>
          <ShareOutlinedIcon style={style} sx={{ fontSize: size }} />
        </button>
      </CopyToClipboard>
      <Snackbar
        open={copied}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Enlace copiado"
      />
    </>
  );
};

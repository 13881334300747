import React, { useEffect, FC } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BoltIcon from "@mui/icons-material/Bolt";
import { commafy } from "../utils/price";
import { Vendor } from "../types";
import { useBalance } from "../context/BalanceContext";

type Props = {
  data?: Vendor;
  totalCartPrice: number;
  pointsApplied: number;
} & HeaderButtonProps;

const UserPaymentTab: FC<Props> = ({ data, ...props }) => {
  const { balance, fetchBalance } = useBalance();

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  return (
    <div>
      {data?.adminPaymentType?.snipeChecked && (
        <HeaderButton
          text="SINPE Móvil"
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setActiveTabData={props.setActiveTabData}
          setDeliverDisable={props.setDeliverDisable}
          setPaymentDisable={props.setPaymentDisable}
          MobileUi={props.MobileUi}
          icon={<BoltIcon />}
        />
      )}
      {data?.adminPaymentType?.transferChecked && (
        <HeaderButton
          text="Transferencia bancaria"
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setActiveTabData={props.setActiveTabData}
          setDeliverDisable={props.setDeliverDisable}
          setPaymentDisable={props.setPaymentDisable}
          MobileUi={props.MobileUi}
          icon={<BoltIcon />}
        />
      )}
      {data?.adminPaymentType?.onDeliveryChecked && (
        <HeaderButton
          text="Pagar en efectivo"
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setActiveTabData={props.setActiveTabData}
          setDeliverDisable={props.setDeliverDisable}
          setPaymentDisable={props.setPaymentDisable}
          MobileUi={props.MobileUi}
          icon={<BoltIcon />}
        />
      )}

      {balance > 0 && (
        <>
          {balance >= props.totalCartPrice - props.pointsApplied && (
            <HeaderButton
              text={`Pagar con Basi Card`}
              text2={`(Balance actual: ₡${commafy(balance)})`}
              activeTab={props.activeTab}
              setActiveTabs={props.setActiveTabs}
              setActiveTabData={props.setActiveTabData}
              setDeliverDisable={props.setDeliverDisable}
              setPaymentDisable={props.setPaymentDisable}
              MobileUi={props.MobileUi}
              icon={<BoltIcon />}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UserPaymentTab;

type HeaderButtonProps = {
  MobileUi: boolean;
  activeTab: string;
  text: string;
  text2?: string;
  icon: React.ReactNode;
  setDeliverDisable: (v: boolean) => void;
  setPaymentDisable: (v: boolean) => void;
  setActiveTabs: (v: string) => void;
  setActiveTabData: (v: { text: string }) => void;
};

const HeaderButton: FC<HeaderButtonProps> = (props) => {
  const handleChange = () => {
    props.setActiveTabs(props.text);
    props.setActiveTabData(props);
    props.setDeliverDisable(false);
    props.setPaymentDisable(false);
  };

  return (
    <>
      <div
        onClick={handleChange}
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: props.MobileUi ? "100%" : "82.5%",
          backgroundColor:
            props.activeTab === props.text ? "lightgray" : "white",
          alignItems: "center",
          padding: 8,
          borderRadius: 5,
          borderWidth: 1,
          cursor: "pointer",
          marginBottom: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 10,
          }}
        >
          {props.icon}
          <div>
            <p>
              {" "}
              <b> {props.text} </b> {props.text2}
            </p>
          </div>
        </div>
        <ArrowForwardIosIcon style={{ fontSize: 15 }} />
      </div>
    </>
  );
};

import { Add } from "@material-ui/icons";
import { useStateValue } from "../ContextApi/StateProvider";
import Counter from "./Counter";
import "./style/Navbar.css";
import { useContext, useState } from "react";
import { addProductInteraction } from "../utils/addProductInteraction";
import AuthContext from "../context/AuthContext";
import { debounce } from "lodash";

const Image = ({ currentItem, handleOpen, storeId, MobileUi }) => {
  const {
    images,
    name,
    date,
    _id,
    pId,
    price,
    stock,
    size,
    description,
    deal,
    qty = 1,
  } = currentItem;

  const [interactionTimeout, setInteractionTimeout] = useState(null);

  const { user } = useContext(AuthContext);
  const [{ cart }, dispatch] = useStateValue();
  const existItem = cart.find((x) => x.id === _id?.concat(storeId));

  const addToCart = () => {
    try {
      dispatch({
        type: "ADD_TO_CART",
        item: {
          _id,
          id: _id.concat(storeId),
          pId,
          name,
          images,
          date,
          price,
          stock,
          size,
          description,
          qty: 1,
          basePrice: price,
          vendorId: storeId,
          deal,
          dealApplied: deal?.type,
          dealStatus: currentItem.dealStatus,
        },
      });
      localStorage.setItem("cart", JSON.stringify(currentItem, null, 4));
    } catch (error) {
      console.error(error);
    }
  };

  const addProductInteractionDebounced = debounce(addProductInteraction, 500);

  const handleMouseOver = () => {
    if (!user?._id) return;
    setInteractionTimeout(
      setTimeout(() => {
        addProductInteractionDebounced(user._id, pId, "hover");
      }, 2000)
    );
  };

  const handleMouseOut = () => {
    clearTimeout(interactionTimeout);
  };

  return (
    <div
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onTouchStart={handleMouseOver}
      onTouchEnd={handleMouseOut}
    >
      {existItem ? (
        <>
          <Counter
            counterStyle={{
              display: "flex",
              backgroundColor: "#52AE11",
              justifyContent: "space-between",
              borderRadius: 18,
              padding: 5,
              color: "white",
              width: MobileUi ? 120 : 130,
              position: "relative",
              top: 10,
              left: MobileUi ? 10 : 40,
            }}
            id={_id.concat(storeId)}
            price={price}
            date={date}
            img={images}
            title={name}
            qty={existItem.qty}
            stock={stock}
            basePrice={price}
          />
          {images && (
            <img
              onClick={() => {
                handleOpen();
                if (user?._id) {
                  addProductInteraction(user._id, pId, "click");
                }
              }}
              style={{
                width: MobileUi ? 130 : 160,
                height: MobileUi ? 120 : 150,
                borderRadius: 10,
                cursor: "pointer",
                marginTop: -16,
              }}
              src={images}
              alt="addToCard"
            />
          )}
        </>
      ) : (
        <>
          {MobileUi ? (
            <div id="outerMob" onClick={addToCart}>
              {images && (
                <div id="innerMob" style={Styles.addStyle}>
                  <Add />
                </div>
              )}
            </div>
          ) : (
            <div id="outer" onClick={addToCart}>
              {images && (
                <div id="inner" style={Styles.addStyle}>
                  <Add style={{ fontSize: 16 }} />
                  Agregar
                </div>
              )}
            </div>
          )}
          {images && (
            <img
              onClick={() => {
                handleOpen();
                if (user?._id) {
                  addProductInteraction(user._id, pId, "click");
                }
              }}
              style={{
                width: MobileUi ? 130 : 160,
                height: MobileUi ? 120 : 150,
                borderRadius: 10,
                cursor: "pointer",
                marginTop: MobileUi ? -5 : "0px",
              }}
              src={images}
              alt="addToCard"
            />
          )}
        </>
      )}
    </div>
  );
};

export default Image;
const Styles = {
  addStyle: {
    color: "white",
    cursor: "pointer",
    fontSize: 14,
    backgroundColor: "#52AE11",
  },
};

import React, { FC, useCallback } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ReactPaginate from "react-paginate";

type Props = {
  count: number;
  pageSize: number;
  onPageChange: (page: number) => void;
};

const Pager: FC<Props> = ({ count, pageSize, onPageChange }) => {
  const handleChange = useCallback(
    ({ selected }: any) => onPageChange(selected),
    [onPageChange]
  );

  return (
    <ReactPaginate
      nextLabel={<KeyboardArrowRightIcon />}
      previousLabel={<KeyboardArrowLeftIcon />}
      onPageChange={handleChange}
      pageRangeDisplayed={pageSize}
      pageCount={Math.ceil(count / pageSize)}
      renderOnZeroPageCount={() => null}
      containerClassName="pagination"
      pageLinkClassName="pagenumDisplay"
      previousLinkClassName="prepage"
      nextLinkClassName="nextpage"
      activeLinkClassName="active"
    />
  );
};

export default Pager;

type NumberOptions = {
  currency?: string;
  style?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  showPlusAtStart?: boolean;
};
export const formatNumber = (number?: number, options?: NumberOptions) => {
  number = number || 0;

  options = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  };
  const formattedNumber = new Intl.NumberFormat("en-US", options).format(
    number
  );

  return (options.showPlusAtStart && number >= 0 ? "+" : "") + formattedNumber;
};

export const onlyNumbers = (e: any) => {
  const key = e.key;
  const keyCode = e.keyCode || e.which;

  // Allow numeric characters (0-9) and the backspace key
  if (!/^[0-9]+$/.test(key) && keyCode !== 8) {
    e.preventDefault();
  }
};

export const onlyDecimalNumbers = (e: any, allowDecimal = 2) => {
  const key = e.key;
  const keyCode = e.keyCode || e.which;

  // Allow numeric characters (0-9), the backspace key, and a single dot for decimal point
  if (keyCode === 8) {
    return;
  }
  if (!/^[0-9.]$/.test(key)) {
    e.preventDefault();
  }

  // Allow only one dot for decimal point
  if (key === "." && e.target.value.includes(".")) {
    e.preventDefault();
  }

  // Allow up to n decimal places, excluding the dot
  const value = e.target.value;
  const hasDot = value.includes(".");
  const decimalPart = hasDot ? value.split(".")[1] || "" : "";
  if (hasDot && decimalPart.length >= allowDecimal) {
    e.preventDefault();
  }
};

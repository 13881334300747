import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import "require-ensure";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// if (!window.Intl) {
//   require.call([], () => {
//     require("intl");
//     ReactDOM.render(
//       <>
//         <App />
//       </>,
//       document.getElementById("root")
//     );
//   });
// } else {
//   ReactDOM.render(
//     <>
//       <App />
//     </>,
//     document.getElementById("root")
//   );
// }

import * as React from "react";
import "../style/Navbar.css";
import { useState } from "react";
import "react-responsive-modal/styles.css";
import Modal from "styled-react-modal";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { DealPrice } from "../DealPrice";
import ShareProduct from "../ShareProduct";
import ModalCounter from "./ModalCounter";

const StyledModal = Modal.styled`
width: 100%;
height: 100%;
align-items: center;
justify-content: center;
background-color: white;      
text-align: center;
// overflow:auto;
position: fixed;
top:50%;
left: 50%;
transform: translate(-50%,-50%);
z-index: 1;
margin: 0
`;

const ProductDetailModal = ({
  open,
  onClose,
  currentItem: product,
  storeId,
}) => {
  const [dealApplied, setDealApplied] = useState(product.deal?.type);
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <StyledModal
        isOpen={open}
        onBackgroundClick={handleClose}
        onEscapeKeydown={handleClose}
      >
        {product && (
          <>
            <div className="bg-white h-full relative">
              <div className="flex p-1.5 border-b justify-between z-50">
                <button onClick={handleClose}>
                  <ArrowBackRoundedIcon />
                </button>
                <button>
                  <ShareProduct productId={product?._id} />
                </button>
              </div>
              <div
                style={{
                  height: "calc(100% - 9rem)",
                }}
                className="pt-1 overflow-y-auto overflow-x-hidden"
              >
                <div className="flex justify-center w-full">
                  <img
                    className="w-64 h-full object-contain"
                    src={product?.images}
                    alt=""
                  />
                </div>
                <div className="m-4 text-left">
                  <h4 className="font-semibold text-xl">{product?.name}</h4>
                  <p className="text-sm text-[#606163]">{product?.size}</p>
                  <div
                    style={{
                      fontWeight: "700",
                      color: "rgb(52, 53, 56)",
                      fontSize: 18,
                    }}
                  >
                    <DealPrice
                      price={product?.price}
                      deal={product?.deal}
                      dealStatus={product?.dealStatus}
                      showDealSelector
                      showCountdown
                      dealApplied={dealApplied}
                      onDealChange={setDealApplied}
                    />
                  </div>
                  <p className="mt-3 text-sm">{product?.description}</p>
                </div>
              </div>
              <div className="fixed bottom-[65px] w-full left-0 z-50">
                <div className="flex mx-2 gap-2">
                  <ModalCounter
                    counterStyle={{
                      display: "flex",
                      backgroundColor: "#F6F7F8",
                      justifyContent: "space-between",
                      borderRadius: 10,
                      padding: 8,
                      color: "white",
                      width: "49%",
                    }}
                    currentItem={product}
                    handleClose={handleClose}
                    storeId={storeId}
                    dealApplied={dealApplied}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </StyledModal>
    </div>
  );
};

export default ProductDetailModal;

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CreateAccount from "./screens/Authentication/SignUp/Index";
import Login from "./screens/Authentication/SignIn/Index";
import ForgetPassword from "./screens/Authentication/ForgetPassword/Index";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
//@ts-ignore
import LocContext from "./context/Locationcontext";
import Home from "./pages/home/Home";
import BaseLayout from "./layouts/baseLayout/BaseLayout";
import StoreLayout from "./layouts/storeLayout/StoreLayout";
import Store from "./pages/store/Store";
import Offers from "./pages/offers/Offers";
import Explorer from "./pages/explorer/Explorer";
import Stories from "./pages/stories/Stories";
import MyBasi from "./pages/myBasi/MyBasi";
import Search from "./pages/search/Search";
//@ts-ignore
import UserAllOrders from "./user/UserAllOrders";
//@ts-ignore
import UserCheckOut from "./user/UserCheckOut";
//@ts-ignore
import UserAccountSetting from "./user/UserAccountSetting";
//@ts-ignore
import Terms from "./components/Terms";
//@ts-ignore
import UserOrderDetails from "./user/UserOrderDetails";

export default function AllRoute() {
  const [user, setUser] = React.useState({});
  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      setUser(currentUser);
    }
  });
  const [loc, setLoc] = useState();

  function getUser() {
    const u = localStorage.getItem("location");
    if (!u) {
      return;
    }
    const us = JSON.parse(u);
    setLoc(us);
  }
  useEffect(() => {
    getUser();
  }, []);

  return (
    <LocContext.Provider value={{ loc, setLoc }}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <BaseLayout>
                <Home />
              </BaseLayout>
            }
          />
          <Route
            path="/tiendas/:storeName"
            element={
              <StoreLayout>
                <Explorer />
              </StoreLayout>
            }
          />
          <Route
            path="/tiendas/:storeName/store"
            element={
              <StoreLayout>
                <Store />
              </StoreLayout>
            }
          />
          <Route
            path="/tiendas/:storeName/stories"
            element={
              <StoreLayout>
                <Stories />
              </StoreLayout>
            }
          />
          <Route
            path="/tiendas/:storeName/offers"
            element={
              <StoreLayout>
                <Offers />
              </StoreLayout>
            }
          />
          <Route
            path="/tiendas/:storeName/search"
            element={
              <StoreLayout>
                <Search />
              </StoreLayout>
            }
          />
          <Route
            path="/tiendas/:storeName/my-basi"
            element={
              <StoreLayout>
                <MyBasi />
              </StoreLayout>
            }
          />

          <Route path="/Terms" element={<Terms />} />

          <Route path="/Login" element={<Login />} />
          <Route path="/CreateAccount" element={<CreateAccount />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />

          <Route path="/UserAllOrders" element={<UserAllOrders />} />
          <Route
            path="/UserAccountSetting"
            element={
              <BaseLayout>
                <UserAccountSetting />
              </BaseLayout>
            }
          />
          <Route path="/UserCheckOut" element={<UserCheckOut />} />
          <Route path="/UserOrderDetails" element={<UserOrderDetails />} />
        </Routes>
      </Router>
    </LocContext.Provider>
  );
}

import { Box, Skeleton, Snackbar, Typography } from "@mui/material";
import React, { FC, useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import axios from "../../../../axios";
import { PriceChop, Product } from "../../../../types";
import AuthContext from "../../../../context/AuthContext";
import ProductCard from "./ProductCard";
// @ts-ignore
import { useStateValue } from "../../../../ContextApi/StateProvider";
// @ts-ignore
import ProductDetailModal from "../../../../components/ProductDetailModal";
import InfoModal from "../InfoModal";

const PriceChopProducts: FC = () => {
  const { storeName } = useParams();
  const [openCopySnack, setOpenCopySnack] = useState(false);
  const [{ priceChops }, dispatch] = useStateValue();
  const authUser = useContext(AuthContext);
  const [products, setProducts] = useState<Product[]>();
  const [storeData, setStoreData] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();

  const handleClose = useCallback(() => setSelectedProduct(undefined), []);
  const handlePriceChopGenerated = useCallback(
    (p: PriceChop) => {
      dispatch({
        type: "RECEIVED_PRICE_CHOPS",
        payload: [...(priceChops || []), p],
      });
    },
    [dispatch, priceChops]
  );

  useEffect(() => {
    axios
      .post("/api/v1/vendor/checkStoreName", { storeName: storeName })
      .then((res) => {
        setStoreData(res.data.store);
      });
    axios
      .get(`/api/v1/products/price-chop/${storeName}`)
      .then(({ data }) =>
        setProducts(data.products.map((m: any) => m.products))
      );
  }, [authUser?.user?.email, storeName]);

  let content = (
    <Box className="flex mt-2">
      <Skeleton
        variant="rectangular"
        width={190}
        height={240}
        style={{ minWidth: 190, borderRadius: 3, marginRight: 6 }}
      />
    </Box>
  );

  if (products?.length) {
    const settings = {
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <KeyboardArrowRightIcon color="primary" />,
      prevArrow: <KeyboardArrowLeftIcon color="primary" />,
    };
    content = (
      <Slider {...settings}>
        {products.map((m) => (
          <ProductCard
            product={m}
            priceChop={priceChops?.find((f) => f.product?.pId === m.pId)}
            onSelect={setSelectedProduct}
            onPriceChopGenerated={handlePriceChopGenerated}
            setOpenCopySnack={setOpenCopySnack}
          />
        ))}
      </Slider>
    );
  } else if (products) {
    content = (
      <Typography color="lightgray" variant="body2">
        En este momento no hay productos
      </Typography>
    );
  }

  return (
    <Box className="relative">
      <h1 style={{ paddingTop: "5px", paddingLeft: "5px" }}>Basi MAX</h1>
      <Typography color="gray">¡Comparte y gana!</Typography>
      <Typography component="p" color="gray" variant="caption">
        Cada persona a la que compartas el enlace debe crear una cuenta para
        ganar.
      </Typography>
      <Box padding={3} paddingTop={1}>
        {content}
      </Box>
      {!!selectedProduct && (
        <ProductDetailModal
          storeId={storeData?._id}
          currentItem={selectedProduct}
          open={!!selectedProduct}
          onClose={handleClose}
        />
      )}
      <InfoModal
        title="Basi MAX"
        content={`Con nuestra función Basi Max, tú eliges los productos que deseas obtener de forma gratuita.

        Una vez seleccionados, comienza un temporizador de 24 horas. Para conseguir el artículo sin costo, simplemente comparte tu enlace con la mayor cantidad de amigos posible. Si se registran con tu enlace antes de que finalice el tiempo, puedes obtener el articulo completamente gratis.
        
        ¡Disfruta de esta oportunidad única para obtener productos gratis al compartir con tu red!`}
      />
      <Snackbar
        open={openCopySnack}
        autoHideDuration={6000}
        onClose={() => setOpenCopySnack(false)}
        message="Copiado"
      />
    </Box>
  );
};

export default PriceChopProducts;

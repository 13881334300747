import React, { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
//@ts-ignore
import AllProductsOrGroupProducts from "../../components/MiniStoreFront/Products/AllProductsOrGroupProducts";
import useIsMounted from "../../hooks/useIsMounted";
//@ts-ignore
import { store } from "../../features/slices/storeSlice";
//@ts-ignore
import { storeProduct } from "../../features/slices/VendorProducts";
import { useParams } from "react-router-dom";
import ScreenLoader from "../../components/screenLoader/ScreenLoader";

export default function Store({ storeData }: any) {
  const { storeName } = useParams();
  //@ts-ignore
  const {
    data: { data },
    isLoading,
  } = useSelector((state: any) => state.storeProduct);
  const isMountedRef = useIsMounted();
  const dispatch = useDispatch();
  // const { storeName } = useParams();

  const dispatchAisle = useCallback(async () => {
    await dispatch(store());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilteredProducts = useCallback(
    async (filterType = "all", filterValue = null) => {
      await dispatch(
        //@ts-ignore
        storeProduct({
          data: {
            vendorId: storeName,
            filterType,
            filterValue,
          },
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (isMountedRef.current) {
      dispatchAisle().then();
      getFilteredProducts().then();
    }
  }, [dispatchAisle, getFilteredProducts, isMountedRef]);

  if (isLoading) {
    return <ScreenLoader />;
  }

  return (
    <Grid item md={10} lg={10} sm={12} xs={12}>
      <Box sx={{ marginTop: 1 }} style={{ marginLeft: 10, marginRight: 10 }}>
        <AllProductsOrGroupProducts
          isGrouped={data.grouped}
          products={data.products}
          subCategories={data?.subCategories}
          store={storeData}
        />

        {data.products.length === 0 && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#63CE46",
                fontSize: 23,
                fontWeight: "600",
              }}
            >
              Oops! No hay productos disponible para el Pasillo/Categoría
              seleccionado
            </div>
          </div>
        )}
      </Box>
    </Grid>
  );
}

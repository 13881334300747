import Box from "@mui/material/Box";
import React, {
  // useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import AuthContext from "../../context/AuthContext";
import { useStateValue } from "../../ContextApi/StateProvider";
//@ts-ignore
import MiniFrontDrawer from "../../components/MiniStoreFront/Drawer/MiniFrontDrawer";
import useScreenSize from "../../hooks/useScreenSize";
import useIsMounted from "../../hooks/useIsMounted";
import StoreHeader from "./components/StoreHeader";
import { Grid } from "@mui/material";
import BottomNavigation from "./components/BottomNavigation";

export default function StoreLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { storeName } = useParams();
  const authUser = useContext(AuthContext);
  const [storeData, setStoreData] = useState();
  const [, dispatchToStore] = useStateValue();
  const headerRef = useRef(null);
  const isMountedRef = useIsMounted();
  const { isMobile } = useScreenSize();
  const [headerHeight, setHeaderHeight] = useState(66);

  useEffect(() => {
    if (!authUser?.user?.email) return;

    axios
      .post("/api/v1/price-chop/valid", {
        userEmail: authUser?.user?.email,
        vendorId: storeName,
      })
      .then(({ data }) =>
        dispatchToStore({
          type: "RECEIVED_PRICE_CHOPS",
          payload: data.priceChops,
        })
      );
  }, [authUser?.user?.email, dispatchToStore, storeName]);

  useEffect(() => {
    if (isMountedRef.current) {
      axios
        .post("/api/v1/vendor/checkStoreName", { storeName: storeName })
        .then((res) => {
          //@ts-ignore
          setStoreData({ item: res.data.store });
          dispatchToStore({
            type: "SET_STORE",
            payload: res.data.store,
          });
        });
    }
  }, [dispatchToStore, isMountedRef, storeName]);

  const updateHeight = () => {
    if (headerRef.current) {
      //@ts-ignore
      setHeaderHeight(headerRef.current.clientHeight);
    }
  };

  return (
    <div className="bg-white overflow-x-hidden">
      <StoreHeader
        //@ts-ignore
        store={storeData?.item}
        isMobile={isMobile}
        headerRef={headerRef}
        updateHeight={updateHeight}
      />

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <MiniFrontDrawer
          //@ts-ignore
          store={storeData?.item}
          isMobile={false}
        />
        <Grid
          container
          spacing={0}
          className="mx-3"
          style={{
            minHeight: "calc(100vh - 64px)",
            marginTop: headerHeight,
            marginBottom: isMobile ? "5rem" : "10px",
            marginLeft: isMobile ? "0px" : "240px",
            // width: isMobile ? "100%" : "calc(100% - 240px)",
          }}
        >
          {React.Children.map(children, (child: any) =>
            React.cloneElement(child, {
              storeData,
              //@ts-ignore
              store: storeData?.item,
            })
          )}
        </Grid>
        {isMobile && (
          <BottomNavigation
            //@ts-ignore
            store={storeData?.item}
          />
        )}
      </Box>
    </div>
  );
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import LoginIcon from "@mui/icons-material/Login";
const DashBoard = (props) => {
  const authUser = React.useContext(AuthContext);
  const user = authUser?.user;
  return (
    <div>
      <HeaderButton
        text="Tiendas"
        btnColor="black"
        textColor="white"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        setActiveTabData={props.setActiveTabData}
        img={require("../assets/shop.png")}
      />
      {user && (
        <HeaderButton
          text="Tus órdenes"
          btnColor="white"
          textColor="black"
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setActiveTabData={props.setActiveTabData}
          img={require("../assets/deals.png")}
        />
      )}
      {user && (
        <HeaderButton
          text="Configuración de tu cuenta"
          btnColor="white"
          textColor="black"
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setActiveTabData={props.setActiveTabData}
          img={require("../assets/retry.png")}
        />
      )}
      {!user && (
        <HeaderButton
          text="Entrar"
          btnColor="white"
          textColor="black"
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setActiveTabData={props.setActiveTabData}
          icon={<LoginIcon />}
        />
      )}
    </div>
  );
};

export default DashBoard;

const HeaderButton = (props) => {
  const navigation = useNavigate();
  const handleChange = (props) => {
    props.setActiveTabs(props.text);
    props.setActiveTabData(props);
    if (props.text === "Tiendas") {
      navigation("/");

      props.setActiveTabData(props);
    } else if (props.text === "Tus órdenes") {
      navigation("/UserAllOrders");
      props.setActiveTabs(props.text);
      props.setActiveTabData(props);
    } else if (props.text === "Entrar") {
      navigation("/Login");
      props.setActiveTabs(props.text);
      props.setActiveTabData(props);
    } else {
      navigation("/UserAccountSetting");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: props.activeTab === props.text ? "#343538" : "white",
        marginLeft: 9,
        marginRight: 9,
        paddingLeft: 15,
        paddingTop: 9,
        paddingBottom: 9,
        borderRadius: 6,
        marginBottom: 2,
        width: "90%",
        cursor: "pointer",
      }}
      onClick={() => {
        handleChange(props);
      }}
    >
      <div style={{ marginRight: 5 }}>
        {props.img && (
          <img
            style={{
              width: 20,
              height: 20,
            }}
            src={props.img}
          />
        )}
        <div style={{ marginLeft: -4 }}>{props.icon}</div>
      </div>
      <div
        style={{
          color: props.activeTab === props.text ? "white" : "black",
          fontSize: 14,
          fontWeight: "900",
        }}
      >
        {props.text}
      </div>
    </div>
  );
};

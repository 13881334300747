import { Box, Grid } from "@mui/material";
import * as React from "react";
import Colors from "../../../constants/Colors";
import SignIn from "./SignIn";

const Login = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: "90vh",
        zIndex: 1,
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#4C9F6F",
          height: 60,
          position: "fixed",
          top: 0,
        }}
      >
        <div>
          <img
            style={{ width: 220, height: 100, margin: -20 }}
            src={require("../../../assets/logo1.png")}
          />
        </div>
      </div>
      <SignIn />
    </Grid>
  );
};
export default Login;

import React, { useContext, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
//@ts-ignore
import LocContext from "../../../context/Locationcontext";
import Modal from "react-responsive-modal";
//@ts-ignore
import Location from "../../../components/Location";
//@ts-ignore
import grabSingleLocation from "../../../utils/getSingleLocation";

export default function AddressModal({ store }: any) {
  const userLocation = useContext<any>(LocContext);
  const [open, setOpen] = useState(false);
  const [dialogue, setDialogue] = useState(false);
  const [mapLocation, setMapLocation] = useState();

  const checkVendor = (data: any) => {
    setMapLocation(data);
    let a = [];
    a.push(store);
    const isVendorAvailable = grabSingleLocation(data, a);
    if (isVendorAvailable.length === 0) {
      setDialogue(true);
    } else {
      setDialogue(false);
      handleLocation(data);
    }
  };

  const handleLocation = (data: any) => {
    let l = localStorage.getItem("loaction");
    if (l) {
      localStorage.removeItem("location");
    }

    localStorage.setItem("location", JSON.stringify(data));
    userLocation.setLoc(JSON.stringify(data, null, 4));
    window.location.reload();
  };

  const checkVendorCurrent = (crd: any) => {
    let data = { lng: crd.coords.longitude, lat: crd.coords.latitude };
    let a = [];
    a.push(store);
    const isVendorAvailable = grabSingleLocation(data, a);
    if (isVendorAvailable.length === 0) {
      setDialogue(true);
    } else {
      setDialogue(false);
      fetch(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${data.lat}&longitude=${data.lng}&localityLanguage=en`
      )
        .then((res1) => res1.json())
        .then((res) => {
          let l = localStorage.getItem("location");
          if (l) {
            localStorage.removeItem("location");
            userLocation.setLoc("");
          }
          let data = {
            lng: res.longitude,
            lat: res.latitude,
            area: res.locality,
          };
          localStorage.setItem("location", JSON.stringify(data));
          userLocation.setLoc(JSON.stringify(data, null, 4));
          let k = localStorage.getItem("location");
          window.location.reload();
        });
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(checkVendorCurrent);
          } else if (result.state === "prompt") {
            // navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
          }
        });
    }
  };

  return (
    <>
      <div
        style={{ display: "flex", cursor: "pointer", paddingLeft: 20 }}
        onClick={() => setOpen(true)}
      >
        <div>
          <LocationOnIcon
            style={{ color: "black", fontSize: 18, marginTop: -5 }}
          />
        </div>
        <div style={{ color: "black", fontWeight: "600" }}>
          {userLocation?.loc?.area}
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        styles={{
          overlay: {
            borderRadius: 10,
          },
          modal: {
            borderRadius: 10,
            width: 340,
          },
        }}
      >
        {dialogue && (
          <div style={{ paddingTop: 20, marginBottom: -20 }}>
            <p>{`${store.storeName} is not available near this address`}</p>
          </div>
        )}
        <div style={{ paddingBottom: 40 }}>
          <Location
            handleTab={(e: any) => checkVendor(e)}
            getLocation={getLocation}
          />
        </div>
      </Modal>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import BottomNavigationMUI from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import ExploreIcon from "@mui/icons-material/Explore";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import TagIcon from "@mui/icons-material/LocalOffer";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import SignIn from "../../../screens/Authentication/SignIn/SignIn";
import Modal from "react-responsive-modal";

type Menu = {
  name: string;
  icon: any;
  path: string;
};

const menus: Menu[] = [
  {
    name: "Ofertas",
    icon: TagIcon,
    path: "/offers",
  },
  {
    name: "Tienda",
    icon: ShoppingBagIcon,
    path: "/store",
  },
  {
    name: "Explorar",
    icon: ExploreIcon,
    path: "/",
  },
  {
    name: "Mi Basi",
    icon: CardGiftcardIcon,
    path: "/my-basi",
  },
  {
    name: "Buscar",
    icon: SearchIcon,
    path: "/search",
  },
];

const BottomNavigation = ({ store }: any) => {
  const location = useLocation();
  const navigation = useNavigate();
  const [value, setValue] = useState(0);
  const { user } = useContext(AuthContext);
  const [loginModal, setLoginModal] = useState(false);

  const onChange = (tab: number) => {
    const menu = menus[tab];
    if (menu.path === "/my-basi" && !user) {
      setLoginModal(true);
      return;
    }
    const path = `/tiendas/${store.storeName}${menu.path}`;
    navigation(path);
    setValue(tab);
  };

  useEffect(() => {
    const activeTab = menus.findIndex((menu) => {
      const path = `/tiendas/${store?.storeName}${menu.path}`;
      return menu.path === "/"
        ? location.pathname === `/tiendas/${store?.storeName}` ||
            location.pathname === path
        : location.pathname === path;
    });
    if (activeTab > -1) {
      setValue(activeTab);
    }
  }, [location.pathname, store?.storeName, user]);

  return (
    <>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
        elevation={3}
      >
        <BottomNavigationMUI
          showLabels
          value={value}
          onChange={(_, tab) => onChange(tab)}
          sx={{
            padding: 0,
            margin: 0,
            minWidth: "66px",
          }}
        >
          {menus.map((menu, index) => {
            const Icon = menu.icon;
            return (
              <BottomNavigationAction
                key={index}
                label={<span className="text-[0.7rem]">{menu.name}</span>}
                icon={<Icon />}
                style={{
                  minWidth: 66,
                  fontSize: 10,
                }}
              />
            );
          })}
        </BottomNavigationMUI>
      </Paper>
      <Modal open={loginModal} onClose={() => setLoginModal(false)} center>
        <SignIn modal />
      </Modal>
    </>
  );
};

export default BottomNavigation;

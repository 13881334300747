import { createContext } from "react";
import { User } from "../types";

type Context = {
  user?: User;
  setUser: (user: User) => void;
};

const AuthContext = createContext<Context>({ setUser: () => {} });

export default AuthContext;

import React, { FC, useCallback, useContext, useEffect } from "react";
import { Box, Dialog, DialogTitle } from "@mui/material";
// @ts-ignore
import LoadingIcon from "../../../../assets/Basimart_Loader.gif";
import { PriceChop, Product } from "../../../../types";
import axios from "../../../../axios";
import { useParams } from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";

type Props = {
  product?: Product;
  onClose: (priceChop?: PriceChop) => void;
};

const GenerateLinkModal: FC<Props> = ({ product, onClose }) => {
  const { storeName } = useParams();
  const authUser = useContext(AuthContext);
  const handleClose = useCallback(
    (_: string, reason: string) => {
      if (reason && reason === "backdropClick") return;
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    if (!product) return;

    axios
      .post("/api/v1/price-chop/new", {
        product,
        userEmail: authUser?.user?.email,
        vendorId: storeName,
      })
      .then(({ data }) => onClose(data.priceChop));
  }, [authUser?.user?.email, onClose, product, storeName]);

  return (
    <Dialog onClose={handleClose} open={!!product}>
      <DialogTitle>Generando enlace</DialogTitle>
      <Box className="flex m-2" alignItems="center" justifyContent="center">
        <img src={LoadingIcon} height={500} width={350} alt="loader" />
      </Box>
    </Dialog>
  );
};

export default GenerateLinkModal;

import * as React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import "../style/Navbar.css";
import Dropdown from "../Dropdown";
import { useState } from "react";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useStateValue } from "../../ContextApi/StateProvider";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { DealPrice } from "../DealPrice";
import ShareProduct from "../ShareProduct";

const ProductDetailModal = ({ open, onClose, currentItem, storeId }) => {
  const [{ cart }, dispatch] = useStateValue();
  const existItem = cart.find(
    (item) => item?.id === currentItem?._id.concat(storeId)
  );

  const [qty, setQty] = useState(1);
  const [disabled, setDisabled] = useState();
  const [copied, setCopied] = useState(false);
  const [selected, setSelected] = useState(1);
  const [dropDownHide, setDropDownHide] = useState(true);
  const [hide, setHide] = useState(false);
  const [dealApplied, setDealApplied] = useState(currentItem.deal?.type);

  const handleClose = () => {
    onClose();
    setDropDownHide(true);
    setCopied(false);
    setSelected(1);
  };

  const handleChange = (e) => {
    setQty(e.target.value);
    if (e.target.value <= stock && e.target.value > 0) {
      setDisabled(false);
    } else if (e.target.value < 0 || e.target.value === 0) {
      setDisabled(true);
    } else {
      setDisabled(true);
    }
  };

  const handleChangeDropDown = (e) => {
    setSelected(e);
    let num = Number(e);
    if (num <= stock && num > 0) {
      setDisabled(false);
    } else if (num < 0 || num === 0 || num > stock) {
      setDisabled(true);
    }
    setQty(num);
  };

  const stock = Number(currentItem?.stock);
  const addToCart = () => {
    try {
      if (qty <= stock && qty > 0) {
        dispatch({
          type: "ADD_TO_CART",
          item: {
            _id: currentItem._id,
            pId: currentItem.pId,
            id: currentItem._id.concat(storeId),
            name: currentItem.name,
            images: currentItem.images,
            date: currentItem.date,
            price: currentItem.price * Number(qty),
            stock: currentItem.stock,
            size: currentItem.size,
            description: currentItem.description,
            basePrice: currentItem.price,
            qty: Number(qty),
            vendorId: storeId,
            deal:
              dealApplied === currentItem?.deal?.type
                ? currentItem?.deal
                : undefined,
            dealApplied,
            dealStatus: currentItem.dealStatus,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        center
        styles={{ overlay: Styles.customOverlay, modal: Styles.customModal }}
      >
        {currentItem && (
          <Grid style={{ display: "flex" }} container md={12} xs={12}>
            <Grid xs={12} md={3.5}>
              <div className="img-wrapper">
                <img
                  style={Styles.image}
                  src={currentItem?.images}
                  alt=""
                  className="hover-zoom"
                />
              </div>
            </Grid>

            <Grid
              container
              md={8}
              xs={12}
              style={Styles.productDetailContainer}
            >
              <Grid md={6} xs={12}>
                <div style={Styles.textHeading}>{currentItem?.name}</div>
                <div style={Styles.quantity}>{currentItem?.size}</div>
                <div style={Styles.pricePerOz}>{currentItem?.description}</div>
              </Grid>

              <Box sx={{ display: { xs: "none", md: "flex" }, width: "100%" }}>
                <Grid md={12} xs={12}>
                  <div
                    style={{
                      borderWidth: 1,
                      padding: 20,
                      paddingTop: 30,
                      paddingBlock: 30,
                      marginTop: 35,
                      borderRadius: 9,
                    }}
                  >
                    <div style={Styles.price}>
                      <DealPrice
                        price={currentItem.price}
                        deal={currentItem.deal}
                        dealStatus={currentItem.dealStatus}
                        showDealSelector
                        showCountdown
                        dealApplied={dealApplied}
                        onDealChange={setDealApplied}
                      />
                    </div>
                    {selected === "Custom Amount" ? (
                      <div>
                        <input
                          min="1"
                          onChange={handleChange}
                          placeholder="Enter an amount"
                          type="number"
                          style={Styles.inputField}
                        />
                        <button
                          disabled={disabled}
                          onClick={addToCart}
                          style={{
                            backgroundColor: disabled ? "#F6F7F8" : "#52AE11",
                            display: "flex",
                            width: "100%",
                            fontSize: 18,
                            justifyContent: "center",
                            color: disabled ? "lightblack" : "white",
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderRadius: 13,
                            cursor: "pointer",
                            marginTop: 10,
                          }}
                        >
                          Agregar al carrito
                        </button>
                      </div>
                    ) : (
                      <div className="App">
                        {/* custom dropdown menu */}
                        {dropDownHide ? (
                          <Dropdown
                            selected={selected}
                            setSelected={handleChangeDropDown}
                            setDropDownHide={setDropDownHide}
                            setHide={setHide}
                            hide={hide}
                            currentItem={currentItem}
                            storeId={storeId}
                            qty={qty}
                            existItem={existItem}
                          />
                        ) : (
                          <div>
                            <input
                              min="1"
                              onChange={handleChange}
                              placeholder="Enter an amount"
                              type="number"
                              style={Styles.inputField}
                            />
                            <button
                              disabled={disabled}
                              onClick={addToCart}
                              style={{
                                backgroundColor: disabled
                                  ? "#F6F7F8"
                                  : "#52AE11",
                                display: "flex",
                                width: "100%",
                                fontSize: 18,
                                justifyContent: "center",
                                color: disabled ? "lightblack" : "white",
                                paddingTop: 10,
                                paddingBottom: 10,
                                borderRadius: 13,
                                cursor: "pointer",
                              }}
                            >
                              Agregar al carrito
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    <div style={Styles.pricePerOz}>
                      {currentItem?.stock > 1 && "Disponible"}
                    </div>
                    {!existItem && selected !== "Custom Amount" && (
                      <button
                        disabled={disabled}
                        onClick={addToCart}
                        style={{
                          backgroundColor: disabled ? "#F6F7F8" : "#52AE11",
                          display: "flex",
                          width: "100%",
                          fontSize: 18,
                          justifyContent: "center",
                          color: disabled ? "lightblack" : "white",
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderRadius: 13,
                          cursor: "pointer",
                        }}
                      >
                        Agregar al carrito
                      </button>
                    )}
                    <div style={{ marginTop: 30 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <div style={Styles.row}>
                            <div style={Styles.col}>
                              <div style={Styles.row}>
                                <div style={Styles.storeGuarantee}>
                                  100% garantía de satisfacción
                                </div>

                                <div className="tooltipModal">
                                  <HelpOutlineIcon
                                    style={{
                                      fontSize: 12,
                                      marginLeft: 3,
                                      display: "flex",
                                    }}
                                  />
                                  <span className="tooltiptextModal">
                                    Haz tu pedido con tranquilidad. Si
                                    experimentas alguno de los siguientes
                                    problemas, eres elegible para un reembolso o
                                    crédito: Daño o producto faltante Reemplazos
                                    deficientes de artículos Llegada tardía
                                    Servicio no profesional
                                  </span>
                                </div>
                              </div>

                              <div style={Styles.row}>
                                <div style={Styles.againGuarantee}>
                                  Haz tu pedido con tranquilidad.
                                </div>
                              </div>
                            </div>

                            <div style={Styles.col}>
                              <ShareProduct productId={currentItem._id} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </Modal>
    </div>
  );
};
export default React.memo(ProductDetailModal);

const Styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
  },
  image: {
    minWidth: 250,
    minHeight: 300,
    maxHeight: 1000,
    paddingTop: 40,
    paddingBottom: 40,
  },
  productDetailContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  textHeading: { fontSize: 18, fontWeight: "700" },
  productCategory: { fontSize: 14, fontWeight: "600" },
  quantity: { fontSize: 15, fontWeight: "600", color: "gray" },
  price: { fontSize: 18, fontWeight: "700", marginBottom: 30 },
  pricePerOz: {
    fontSize: 14,
    fontWeight: "600",
    marginBottom: 10,
  },
  inputField: {
    width: "100%",
    borderRadius: 13,
    marginRight: 10,
    borderWidth: 1,
    fontSize: 14,
    padding: 5,
    paddingTop: 12,
    paddingBottom: 12,
  },
  addToCartBtn: {
    display: "flex",
    backgroundColor: "green",
    width: "100%",
    fontSize: 20,
    justifyContent: "center",
    color: "white",
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 13,
    cursor: "pointer",
  },
  storeGuarantee: {
    fontWeight: "600",
    cursor: "pointer",
    fontSize: 12,
    cursor: "pointer",
  },
  againGuarantee: {
    fontWeight: "normal",
    color: "rgb(114, 118, 126)",
    fontSize: 13,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
  },
  customOverlay: {
    borderRadius: 10,
  },
  customModal: {
    borderRadius: 10,
    minWidth: "90%",
    maxWidth: "120%",
    maxHeight: "100%",
  },
};

import React from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
// import AllRoute from "./components/AllRoute";
import { useState } from "react";
import reducer, { initialState } from "./ContextApi/reducer";
import { useEffect } from "react";
import AuthContext from "./context/AuthContext";
import { Provider } from "react-redux";
//@ts-ignore
import { store } from "./features/store/store";
import { StateProvider } from "./ContextApi/StateProvider";
import { getAuth } from "firebase/auth";
import axios from "./axios";
import { BalanceProvider } from "./context/BalanceContext";
import Router from "./router";
import { User } from "./types";

function App() {
  let auth = getAuth();
  const [user, setUser] = useState<User>();
  const [cartValues, setCartValues] = useState();
  function getUser() {
    const u = localStorage.getItem("user");

    if (u == null || u === "" || u === "undefined") {
      return;
    }
    const us = JSON.parse(u);
    setUser(us);
  }
  useEffect(() => {
    getUser();
  }, [auth.currentUser]);
  useEffect(() => {
    if (!user?._id) return;
    axios
      .get(`/api/v1/user/${user?._id}`)
      .then((res) => {
        setUser(res.data.userId);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [setUser, user?._id]);
  function getCart() {
    const u = localStorage.getItem("cartItem");
    if (u == null || u === "" || u === "undefined") {
      //@ts-ignore
      setCartValues(initialState);
      return;
    }
    const us = JSON.parse(u);
    setCartValues({ ...us });
  }
  useEffect(() => {
    getCart();
  }, []);

  const theme = createTheme({
    palette: {
      primary: { main: "#52ae11" },
    },

    typography: {
      fontFamily: [
        "Montserrat",
        "Nunito",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
    },
    //@ts-ignore
    button: {
      fontFamily: [
        "Montserrat",
        "Nunito",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });
  return (
    <>
      {cartValues && (
        <StateProvider initialState={cartValues} reducer={reducer}>
          <Provider store={store}>
            <AuthContext.Provider value={{ user, setUser }}>
              <BalanceProvider>
                <ThemeProvider theme={theme}>
                  {/* <AllRoute /> */}
                  <Router />
                </ThemeProvider>
              </BalanceProvider>
            </AuthContext.Provider>
          </Provider>
        </StateProvider>
      )}
    </>
  );
}

export default App;

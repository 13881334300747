import React, {useState} from "react";
import List from "@mui/material/List";

import Collapse from "@mui/material/Collapse";
import {storeProduct} from "../features/slices/VendorProducts";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

function sentenceCase(str) {
  if (str === null || str === "") return false;
  else str = str?.toString();

  return str?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase();
  });
}

function SubCategoriesComponent({item}) {

  const [openList, setOpenList] = useState(false);

  const filters = useSelector((state) => state.storeProduct.filters);

  const dispatch = useDispatch();
  const {storeName} = useParams();

  const getFilteredProducts = async (filterType = "all", filterValue = null) => {
    await dispatch(
      storeProduct({
        data: {
          vendorId: storeName,
          filterType,
          filterValue,
          groupByCategory: filterType === 'aisle'
        },
      })
    );
  };

  const onFilter = (type, value) => {
    if (type === 'aisle') {
      setOpenList(!openList);
    }
    getFilteredProducts(type, value);
  }

  const hasFilter = (type, value) => {
    return filters.filterType === type && filters.filterValue === value;
  }

  return (
    <>
      {item && (
        <div style={{paddingBlock: 0}} key={item.id}>
          <div onClick={() => onFilter('aisle', item.aisle)}>
            <div
              style={{
                marginTop: 5,
                marginLeft: 5,
                marginRight: 5,
                padding: 10,
                borderRadius: 10,
                cursor: "pointer",
                color: hasFilter('aisle', item.aisle) ? "white" : "#343538",
                backgroundColor: hasFilter('aisle', item.aisle) ? "#343538" : "white",
                fontSize: 14,
                fontWeight: "600",
              }}
            >
              {sentenceCase(item.aisle)}
            </div>
          </div>
          <Collapse key={item.id} in={openList} timeout="auto" unmountOnExit>
            {item?.category?.map((category, i) => (
              <List
                key={item.id + '-' + i}
                className="myList"
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  borderRadius: 10,
                  cursor: "pointer",
                  backgroundColor: hasFilter('category', category.label) ? "#343538" : "white",
                }}
                disablePadding
              >
                <div
                  style={{
                    marginLeft: 25,
                    padding: 10,
                  }}
                  onClick={() => onFilter('category', category.label)}
                >
                  <div
                    style={{
                      color: hasFilter('category', category.label) ? "white" : "#343538",
                      fontSize: 14,
                      fontWeight: "normal",
                    }}
                  >
                    {sentenceCase(category.label)}
                  </div>
                </div>
              </List>
            ))}
          </Collapse>
        </div>
      )}
    </>
  );
}

export default SubCategoriesComponent;

import React from "react";
import PlaceIcon from "@mui/icons-material/Place";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
//@ts-ignore
import provinces from "../constants/provinces.json";
const getUniqueObjectsByKey = (arrayOfObjects: any[], key: string) => {
  const uniqueObjects = [];
  const uniqueKeys = new Set();

  for (const obj of arrayOfObjects) {
    const objKey = obj[key];

    if (!uniqueKeys.has(objKey)) {
      uniqueKeys.add(objKey);
      uniqueObjects.push(obj);
    }
  }

  return uniqueObjects;
};

export default function UserAddress({
  checkOutStyle,
  formValues,
  formErrors,
  setFormValues,
  saveUserAddress,
  isMobile = false,
}: any) {
  const btnDisabled =
    !formValues?.address ||
    !formValues?.addressOptional ||
    !formValues?.businessName ||
    !formValues?.district ||
    !formValues?.zipCode;

  return (
    <div style={{ padding: 20, borderBottomWidth: 1 }}>
      <div style={checkOutStyle.div2Center} className="mb-2">
        <PlaceIcon style={checkOutStyle.placeIcon} />
        <div style={{ fontSize: 15, fontWeight: "600" }}>
          Añadir dirección de entrega
        </div>
      </div>
      <Autocomplete
        disablePortal
        value={formValues?.addressOptional}
        options={getUniqueObjectsByKey(provinces, "province").map((f) => ({
          label: f.province,
          value: f.province,
        }))}
        onChange={(e, v) => {
          setFormValues((prev: any) => ({
            ...prev,
            addressOptional: v?.value,
            businessName: "",
            district: "",
            zipCode: "",
          }));
        }}
        style={{
          ...checkOutStyle.textField,
          width: isMobile ? "100%" : checkOutStyle.textField.width,
        }}
        renderInput={(params) => <TextField {...params} label="Provincia" />}
      />
      <Autocomplete
        disablePortal
        value={formValues?.businessName}
        options={
          formValues?.addressOptional
            ? getUniqueObjectsByKey(
                provinces.filter(
                  (f: any) => f.province === formValues.addressOptional
                ),
                "canton"
              ).map((f) => ({
                label: f.canton,
                value: f.canton,
              }))
            : []
        }
        onChange={(e, v) => {
          setFormValues((prev: any) => ({
            ...prev,
            businessName: v?.value,
            district: "",
            zipCode: "",
          }));
        }}
        style={{
          ...checkOutStyle.textField,
          width: isMobile ? "100%" : checkOutStyle.textField.width,
        }}
        renderInput={(params) => <TextField {...params} label="Cantón" />}
      />
      <Autocomplete
        disablePortal
        value={formValues?.district}
        options={
          formValues?.businessName
            ? getUniqueObjectsByKey(
                provinces
                  .filter((f: any) => f.province === formValues.addressOptional)
                  .filter((f: any) => f.canton === formValues.businessName),
                "district"
              ).map((f) => ({
                label: f.district,
                value: f.district,
              }))
            : []
        }
        onChange={(e, v) => {
          const postalCode = provinces
            .filter((f: any) => f.province === formValues.addressOptional)
            .filter((f: any) => f.canton === formValues.businessName)
            .filter((f: any) => f.district === v?.value)?.[0]?.postal_code;

          setFormValues((prev: any) => ({
            ...prev,
            district: v?.value,
            zipCode: postalCode,
          }));
        }}
        style={{
          ...checkOutStyle.textField,
          width: isMobile ? "100%" : checkOutStyle.textField.width,
        }}
        renderInput={(params) => <TextField {...params} label="Distrito" />}
      />
      <TextField
        type="text"
        id="address"
        name="address"
        value={formValues?.address}
        disabled={false}
        defaultValue={""}
        onChange={(e) => {
          setFormValues((prev: any) => ({
            ...prev,
            address: e.target.value,
          }));
        }}
        style={{
          ...checkOutStyle.textField,
          width: isMobile ? "100%" : checkOutStyle.textField.width,
        }}
        label="Dirección Exacta"
      />
      <div style={checkOutStyle.textError}>{formErrors.address}</div>
      <TextField
        key={formValues?.zipCode}
        type="number"
        onChange={setFormValues}
        disabled={true}
        id="zipCode"
        name="zipCode"
        defaultValue={formValues?.zipCode}
        style={{
          ...checkOutStyle.textField,
          width: isMobile ? "100%" : checkOutStyle.textField.width,
        }}
        label="Código Postal"
      />

      <button
        disabled={btnDisabled}
        onClick={saveUserAddress}
        style={{
          ...checkOutStyle.continueButton,
          backgroundColor: btnDisabled ? "lightgray" : "#52AE11",
          color: btnDisabled ? "black" : "white",
        }}
      >
        Continuar
      </button>
    </div>
  );
}

import { Box, Button, Divider } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { Dispatch, useCallback, useLayoutEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { auth } from "../../../firebase";
import {
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { TextField } from "formik-material-ui";
import axios from "../../../axios";
import { MuiPhone } from "../../../components/MuiPhone";

type SignUpStepProps = {
  setStep: Dispatch<React.SetStateAction<number>>;
  setData: Dispatch<React.SetStateAction<SignUpData>>;
  data: SignUpData;
};

type SignUpData = {
  phone?: string;
  password?: string;
  name?: string;
  firebaseUser?: any;
  verificationCB?: any;
};

export default function SignUp() {
  const [step, setStep] = useState<number>(1);
  const [data, setData] = useState<SignUpData>({});

  const props = {
    data,
    setData,
    setStep,
  };

  const steps = {
    1: <Step1 {...props} />,
    2: <Step2 {...props} />,
    3: <Step3 {...props} />,
    4: <Step4 {...props} />,
  } as Record<number, JSX.Element>;

  if (!steps[step]) {
    return null;
  }
  return steps[step];
}

const Step1 = ({ setStep, setData }: SignUpStepProps) => {
  const validationSchema = Yup.object().shape({
    phone: Yup.string().required("Se requiere teléfono"),
  });

  const [captcha, setCaptcha] = useState<RecaptchaVerifier>();

  useLayoutEffect(() => {
    if (!document.getElementById("captcha")) return;
    const c = new RecaptchaVerifier(
      "captcha",
      {
        size: "invisible",
      },
      auth
    );
    setCaptcha(c);

    return () => c.clear();
  }, []);

  const sendOTP = useCallback(
    async (values, actions) => {
      try {
        const r = await fetchSignInMethodsForEmail(
          auth,
          convertPhoneToEmail(values.phone)
        );
        if (r?.length) {
          throw new Error("auth/email-in-use");
        }
        const verificationCB = await signInWithPhoneNumber(
          auth,
          values.phone,
          captcha as any
        );
        setData({ verificationCB, phone: values.phone });
        setStep(2);
      } catch (error: any) {
        console.log(error);
        if (error.message) {
          actions.setFieldError("phone", "El teléfono ya está en uso");
        }
      }
    },
    [setData, setStep, captcha]
  );

  return (
    <div className="w-[94%] md:w-[35%]">
      <Toaster />
      <Box marginTop={8} className="rounded px-2 py-4" sx={{ boxShadow: 3 }}>
        <h1 className="font-extrabold text-xl mb-2">Regístrate</h1>
        <p className="tw-gray-400 text-xs mb-4">
          Ingresa tu número de teléfono para comenzar.
        </p>
        <Formik
          initialValues={{
            phone: "",
          }}
          validationSchema={validationSchema}
          onSubmit={sendOTP}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                name="phone"
                component={MuiPhone}
                placeholder="Número de Teléfono"
                size="small"
                className="!w-full !mt-4"
              />
              <div id="captcha"></div>
              <Button
                disabled={isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="!rounded !text-white mt-4"
              >
                {"Continuar"}
              </Button>
            </Form>
          )}
        </Formik>
        <Divider orientation="horizontal" flexItem />
        <div className="text-center mt-3">
          <p className="text-sm">¿Ya tienes una cuenta?</p>
          <Link to="/Login" className="text-xs text-[#52ae11]">
            Ingresar
          </Link>
        </div>
      </Box>
    </div>
  );
};

const Step2 = ({ data, setStep, setData }: SignUpStepProps) => {
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .min(6, "El código debe tener 6 dígitos.")
      .max(6, "El código debe tener 6 dígitos.")
      .required("Se requiere código"),
  });

  const validateOTP = useCallback(
    async (values, actions) => {
      try {
        const result = await data.verificationCB.confirm(values.otp);
        setData((prev) => ({ ...prev, firebaseUser: result.user }));
        setStep(3);
      } catch (error: any) {
        console.log(error);
        switch (error.code || error.message) {
          case "auth/invalid-verification-code":
            actions.setFieldError("otp", "Código no es válido");
            break;
          case "auth/code-expired":
            actions.setFieldError("otp", "Código esta caducado");
            break;
          default:
            // toast.error(t(result));
            break;
        }
      }
    },
    [setData, setStep, data]
  );

  return (
    <div className="w-[94%] md:w-[35%]">
      <Toaster />
      <Box marginTop={8} className="rounded px-2 py-4" sx={{ boxShadow: 3 }}>
        <h1 className="font-extrabold text-xl mb-2">Ingresa el código</h1>
        <p className="tw-gray-400 text-xs mb-4">
          Introduce el código que recibiste en tu telefono
        </p>
        <Formik
          initialValues={{
            otp: "",
          }}
          validationSchema={validationSchema}
          onSubmit={validateOTP}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                name="otp"
                placeholder="Código OTP"
                component={TextField}
                className="!w-full"
                size="small"
              ></Field>
              <div id="sign-in-button"></div>
              <Button
                disabled={isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="!rounded !text-white mt-4"
              >
                {"Continuar"}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

const Step3 = ({ setStep, setData }: SignUpStepProps) => {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "La contraseña debe tener al menos 8 caracteres")
      .required("Se requiere contraseña"),
  });

  const onContinue = useCallback(
    async (values) => {
      try {
        setData((prev) => ({ ...prev, password: values.password }));
        setStep(4);
      } catch (error) {
        console.log(error);
      }
    },
    [setData, setStep]
  );

  return (
    <div className="w-[94%] md:w-[35%]">
      <Toaster />
      <Box marginTop={8} className="rounded px-2 py-4" sx={{ boxShadow: 3 }}>
        <h1 className="font-extrabold text-xl mb-2">Crea una contraseña</h1>
        <p className="tw-gray-400 text-xs mb-4">
          Introduce al menos 8 caracteres.
        </p>
        <Formik
          initialValues={{
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onContinue}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                name="password"
                placeholder="Contraseña"
                type="password"
                component={TextField}
                className="!w-full"
                size="small"
              ></Field>
              <div id="sign-in-button"></div>
              <Button
                disabled={isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="!rounded !text-white mt-4"
              >
                {"Continuar"}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

const Step4 = ({ data }: SignUpStepProps) => {
  const navigation = useNavigate();
  const [params] = useSearchParams();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Tu nombre completo es requerido"),
  });

  const onSubmit = useCallback(
    async (values) => {
      try {
        const creds = {
          userName: values.name,
          email: convertPhoneToEmail(data.phone || ""),
          password: data?.password || "",
          priceChop: params.get("priceChop"),
        };

        const firebaseUser = await createUserWithEmailAndPassword(
          auth,
          creds.email,
          creds.password
        );

        const response = await axios.post("/api/v1/user/register", creds, {
          headers: {
            Authorization: "Bearer " + (await firebaseUser.user.getIdToken()),
          },
        });

        localStorage.setItem(
          "user",
          JSON.stringify(response.data.user, null, 4)
        );
        navigation("/");
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    },
    [data, navigation, params]
  );

  return (
    <div className="w-[94%] md:w-[35%]">
      <Toaster />
      <Box marginTop={8} className="rounded px-2 py-4" sx={{ boxShadow: 3 }}>
        <h1 className="font-extrabold text-xl mb-2">Casi listos</h1>
        <p className="tw-gray-400 text-xs mb-4">Cuéntanos un poco de ti</p>
        <Formik
          initialValues={{
            name: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                name="name"
                placeholder="Tu nombre completo"
                component={TextField}
                className="!w-full"
                size="small"
              ></Field>
              <div id="sign-in-button"></div>
              <Button
                disabled={isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="!rounded !text-white mt-4"
              >
                {"Continuar"}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

const convertPhoneToEmail = (phone: string) => {
  return phone?.replace(" ", "").replace("-", "") + "@basimart.com";
};

export const productsStyle = {
  select: {
    marginLeft: 30,
    fontSize: 18,
    fontWeight: "600",
    marginTop: 40,
    marginBottom: 10,
  },
  hasSub: {
    marginLeft: 20,
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  subLabel: {
    marginLeft: 10,
    backgroundColor: "#EFF0F1",
    fontWeight: "600",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 15,
    color: "#434343",
    cursor: "pointer",
  },
  GridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  freshProduct: {
    marginLeft: 30,
    fontSize: 18,
    fontWeight: "600",
    marginTop: 40,
  },
  paginationContainer: { display: "flex", alignItems: "center" },
  title: {
    // width: 190,
    fontSize: 14,
    color: "rgb(52, 53, 56)",
  },
  size: {
    width: 190,
    fontSize: 13,
    color: "#606163",
  },
};

import React, { useEffect, useState, useRef } from "react";

import { Grid } from "@mui/material";
import axios from "../axios";
import Skeleton from "@mui/material/Skeleton";

import Image from "./ImageComponent";
import ProductDetailModal from "./ProductDetailModal";
import { DealPrice } from "./DealPrice";
import Pager from "./Pager";
import "./style/Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { storeProduct } from "../features/slices/VendorProducts";
import { useParams } from "react-router-dom";

const Products = (props) => {
  const { store, name, products, isMobile } = props;

  const dispatch = useDispatch();
  const { storeName } = useParams();

  const [productData, setProductData] = useState(products);

  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [currentItems, setCurrentItems] = useState([]);
  const [modalItem, setModalItem] = useState();
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);
  const listInnerRef = useRef();
  const [loading, setLoading] = useState(true);
  const filters = useSelector((state) => state.storeProduct.filters);

  let itemsPerPage = 5;
  let limit = 5;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    if (productData?.length >= 1) {
      setCurrentItems(productData?.slice(itemOffset, endOffset));
      setPageCount(Math?.ceil(productData.length / itemsPerPage));
      setShow(true);
    }
  }, [itemOffset, itemsPerPage, productData?.length, productData]);

  function sentenceCase(str) {
    if (str === null || str === "") return false;
    else str = str?.toString();

    return str?.replace(/\w\S*/g, function (txt) {
      return txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase();
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageClick = async (event) => {
    if (event > count) {
      const response = await axios.post("/api/v1/product/getVendorProduct", {
        data: {
          vendorId: storeName,
          filterType: filters.filterType === "all" ? "aisle" : "category",
          filterValue: name,
          skip: pageNumber * limit,
          limit: limit,
        },
      });
      setCount(count + 1);
      setPageNumber(pageNumber + 1);

      if (response?.data?.products?.length !== 0) {
        let newOne = [].concat(productData, response.data.products);
        setProductData(newOne);

        const newOffset = (event * itemsPerPage) % newOne.length;

        setItemOffset(newOffset);
      } else {
        const newOffset = (event * itemsPerPage) % productData.length;

        setItemOffset(newOffset);
      }
    } else {
      const newOffset = (event * itemsPerPage) % productData.length;

      setItemOffset(newOffset);
    }
  };

  const setModalOpen = (value) => {
    setModalItem(value);
    setOpen(true);
  };

  const onScroll = async () => {
    if (listInnerRef?.current && productData?.length >= itemsPerPage) {
      const { scrollLeft, scrollWidth, clientWidth } = listInnerRef?.current;
      if (scrollLeft + clientWidth === scrollWidth) {
        setLoading(false);
        const response = await axios.post("/api/v1/product/getVendorProduct", {
          data: {
            vendorId: storeName,
            filterType: filters.filterType === "all" ? "aisle" : "category",
            filterValue: name,
            skip: pageNumber * limit,
            limit: limit,
          },
        });
        setLoading(true);
        setCount(count + 1);
        setPageNumber(pageNumber + 1);
        if (response?.data?.products?.length !== 0) {
          let newOne = [].concat(productData, response?.data?.products);
          setProductData(newOne);
        }
      }
    }
  };

  const handleViewMore = async () => {
    await dispatch(
      storeProduct({
        data: {
          vendorId: storeName,
          filterType: filters.filterType === "all" ? "aisle" : "category",
          filterValue: name,
          groupByCategory: filters.filterType === "all",
        },
      })
    );
  };

  return (
    <Grid container xs={12} md={12} lg={12}>
      <Grid md={11.5} xs={11.5} item style={Styles.GridContainer}>
        {currentItems && (
          <div style={{ fontSize: 18, fontWeight: "600" }}>
            {sentenceCase(name)}
          </div>
        )}
        {productData?.length >= itemsPerPage && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                marginRight: isMobile ? 0 : 15,
                cursor: "pointer",
                color: "rgb(82, 174, 17)",
              }}
              onClick={handleViewMore}
            >
              Ver Más{" ›"}
            </div>
            {!isMobile && (
              <Pager
                count={productData?.length + 1}
                pageSize={itemsPerPage}
                onPageChange={handlePageClick}
              />
            )}
          </div>
        )}
      </Grid>
      <Grid
        style={{
          display: "flex",
        }}
        container
      >
        {productData ? (
          <>
            <>
              {isMobile ? (
                <div
                  className="scrollbar-hide"
                  id="slider"
                  style={Styles.scrollStyles}
                  onScroll={() => onScroll()}
                  ref={listInnerRef}
                >
                  {loading ? (
                    <>
                      {productData?.map((currentItem) => {
                        return (
                          <div
                            style={Styles.scrollChild}
                            key={currentItem?._id}
                          >
                            <div>
                              <Image
                                isMobile={isMobile}
                                handleOpen={() => setModalOpen(currentItem)}
                                currentItem={currentItem}
                                storeId={store?.item?._id}
                              />
                              <div style={{ height: 120 }}>
                                <div
                                  style={{
                                    fontWeight: "700",
                                    color: "rgb(52, 53, 56)",
                                    fontSize: 18,
                                  }}
                                >
                                  <DealPrice
                                    price={currentItem?.price}
                                    deal={currentItem?.deal}
                                    dealStatus={currentItem?.dealStatus}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: 150,
                                    fontSize: 13,
                                    color: "rgb(52, 53, 56)",
                                    whiteSpace: "pre-line",
                                  }}
                                >
                                  {currentItem?.name}
                                </div>
                                <div
                                  style={{
                                    width: 150,
                                    fontSize: 12,
                                    color: "#606163",
                                  }}
                                >
                                  {currentItem?.size}
                                </div>
                              </div>
                            </div>
                            {/* <div ref={ref} /> */}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {" "}
                      {[1, 2, 3]?.map((id) => {
                        return (
                          <div style={Styles.scrollChildSkelton} key={id}>
                            <Skeleton
                              variant="rectangular"
                              width={150}
                              height={118}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={10}
                              style={{ marginTop: 10 }}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={100}
                              height={20}
                              style={{ marginTop: 10 }}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              ) : (
                <>
                  {currentItems?.map((currentItem) => {
                    return (
                      <>
                        <Grid
                          key={currentItem?._id}
                          style={{ marginLeft: 30, height: 300, width: "80vw" }}
                          md={2}
                          xs={4.5}
                          item
                        >
                          <div>
                            <Image
                              handleOpen={() => setModalOpen(currentItem)}
                              currentItem={currentItem}
                              storeId={store?.item?._id}
                            />

                            <div
                              style={{
                                fontWeight: "700",
                                color: "rgb(52, 53, 56)",
                                fontSize: 18,
                              }}
                            >
                              <DealPrice
                                price={currentItem?.price}
                                deal={currentItem?.deal}
                                dealStatus={currentItem?.dealStatus}
                              />
                            </div>
                            <div style={Styles.title}>{currentItem?.name}</div>
                            <div style={Styles.size}>{currentItem?.size}</div>
                          </div>
                        </Grid>
                      </>
                    );
                  })}
                </>
              )}
            </>
            <div>
              {!!modalItem && (
                <ProductDetailModal
                  storeId={store?.item?._id}
                  currentItem={modalItem}
                  open={open}
                  onClose={handleClose}
                />
              )}
            </div>
          </>
        ) : (
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default Products;

const Styles = {
  GridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  freshProduct: {
    marginLeft: 30,
    fontSize: 18,
    fontWeight: "600",
  },
  paginationContainer: { display: "flex", alignItems: "center" },
  title: {
    width: 190,
    fontSize: 13,
    color: "rgb(52, 53, 56)",
  },
  size: {
    width: 190,
    fontSize: 11,
    color: "#606163",
  },
  scrollStyles: {
    width: "100vw",
    display: "flex",
    overflowX: "scroll",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
    height: 300,
  },
  scrollChild: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: 3,
    paddingLeft: 5,
  },
  scrollChildSkelton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    cursor: "pointer",
    padding: 3,
    paddingLeft: 5,
    paddingTop: 20,
  },
  modalContent: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

import { isAfter, parseISO } from "date-fns";

export const initialState = {
  cart: [],
  user: {},
  vendorId: {},
  err: false,
};

export const getCartTotal = (cart: any) =>
  cart
    ?.map((item: any) => {
      if (
        !item?.deal ||
        !isAfter(parseISO(item.deal.expirationDate), Date.now())
      ) {
        return Number(item?.price);
      }
      const percentage =
        typeof item.deal.value === "number"
          ? item.deal.value
          : item.deal.value.percentage;

      return Number(item.price) * (1 - percentage / 100);
    })
    ?.reduce((pre: any, curr: any) => pre + curr, 0);
//

const chopPrice = (product: any, priceChops: any) => {
  try {
    const found = priceChops?.find((f: any) => f.pId === product?.pId);
    if (!found)
      return {
        ...product,
        price: Number(product?.basePrice) * Number(product?.qty),
      };

    return {
      ...product,
      price: Number(product?.basePrice) * Number(product?.qty),
    };
  } catch (error) {
    console.error(error);
  }
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD_TO_CART": {
      try {
        const item = action?.item;
        const differentVendor = state?.cart.find(
          (x: any) => x.vendorId !== item?.vendorId
        );
        if (differentVendor) {
          return {
            ...state,
            err: true,
          };
        }

        const existItem = state?.cart?.find((x: any) => x?.id === item?.id);

        if (existItem) {
          const itemId = existItem?.id;
          const basePrice = existItem?.basePrice;
          const qty = action?.item?.qty;

          const cart = state?.cart?.map((curElem: any) => {
            if (curElem?.id === itemId) {
              return chopPrice(
                {
                  ...curElem,
                  basePrice,
                  qty,
                },
                state?.cart
              );
            } else {
              return { ...curElem };
            }
          });

          localStorage.removeItem("cartItem");
          localStorage.setItem("cartItem", JSON.stringify({ cart }, null, 4));

          return {
            ...state,
            cart,
            err: false,
          };
        } else {
          localStorage.setItem(
            "cartItem",
            JSON.stringify({ cart: [...state.cart, action.item] }, null, 4)
          );
          return {
            ...state,
            cart: [...state.cart, action.item],
            err: false,
          };
        }
      } catch (error) {
        console.log(error);
      }
      break;
    }
    case "ERROR_FALSE": {
      return { ...state, err: false };
    }
    case "RECEIVED_PRICE_CHOPS": {
      const cart = state?.cart?.map((curElem: any) =>
        chopPrice(curElem, action.payload)
      );

      localStorage.removeItem("cartItem");
      localStorage.setItem(
        "cartItem",
        JSON.stringify(
          {
            cart,
          },
          null,
          4
        )
      );

      return {
        ...state,
        cart,
        priceChops: action.payload,
      };
    }
    case "ADD_TO_CART_AGAIN": {
      const item = action.item;

      const differentVendor = state.cart.find(
        (x: any) => x.vendorId !== item.vendorId
      );
      if (differentVendor) {
        return {
          ...state,
          err: true,
        };
      }
      const filterItem = state.cart.filter((x: any) => x.id !== item.id);
      const cart = [...filterItem, action.item].map((m) =>
        chopPrice(m, state.priceChops)
      );

      localStorage.setItem("cartItem", JSON.stringify({ cart }, null, 4));

      return {
        ...state,
        cart: [...filterItem, action.item],
      };
    }
    case "REMOVE_FROM_CART": {
      localStorage.removeItem("cartItem");
      localStorage.setItem(
        "cartItem",
        JSON.stringify(
          { cart: state.cart.filter((item: any) => item.id !== action.id) },
          null,
          4
        )
      );
      return {
        ...state,
        cart: state.cart.filter((item: any) => item.id !== action.id),
      };
    }
    case "INCREMENT_QTY": {
      try {
        const itemId = action.payload;
        const basePrice = Number(action.basePrice);
        const cart = state?.cart?.map((curElem: any) => {
          if (curElem?.id === itemId) {
            return chopPrice(
              {
                ...curElem,
                basePrice,
                qty: Number(curElem.qty) + 1,
              },
              state?.cart
            );
          } else {
            return { ...curElem };
          }
        });
        localStorage.removeItem("cartItem");
        localStorage.setItem(
          "cartItem",
          JSON.stringify(
            {
              cart,
            },
            null,
            4
          )
        );
        return {
          ...state,
          cart,
        };
      } catch (error) {
        console.error(error);
        break;
      }
    }
    case "RESET_CART": {
      localStorage.removeItem("cartItem");
      return {
        cart: [],
      };
    }
    case "DECREMENT_QTY": {
      const itemId = action.payload;
      const cart = state.cart.map((curElem: any) => {
        if (curElem?.id === itemId) {
          return chopPrice(
            {
              ...curElem,
              qty: Number(curElem.qty - 1),
            },
            state?.priceChops
          );
        } else {
          return { ...curElem };
        }
      });
      localStorage.removeItem("cartItem");
      localStorage.setItem(
        "cartItem",
        JSON.stringify(
          {
            cart,
          },
          null,
          4
        )
      );
      return {
        ...state,
        cart,
      };
    }
    case "OVER_WRITE": {
      let currentCart = action.item;
      state.cart.filter((item: any) => item.id !== currentCart.id);
      return {
        ...state,
        cart: [...state.cart, currentCart],
      };
    }
    case "SET_STORE": {
      return { ...state, store: action.payload };
    }
    case "SET_STORE_SEARCH": {
      return { ...state, searchStore: action.payload };
    }

    default:
      return state;
  }
};
export default reducer;

import { Box } from "@mui/material";
import React from "react";
//@ts-ignore
import NavbarComponent from "../../components/NavbarComponent";
import useScreenSize from "../../hooks/useScreenSize";

export default function BaseLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isMobile } = useScreenSize();
  return (
    <div>
      <Box sx={{ display: { md: "flex" } }} style={{ marginBottom: "2rem" }}>
        <NavbarComponent
          storeData={undefined}
          data={undefined}
          cat={undefined}
          anchorEl={undefined}
          mobileMoreAnchorEl={undefined}
          handleMenuClose={undefined}
          handleMobileMenuOpen={undefined}
          onMobileUi={isMobile}
          handleMobileMenuClose={undefined}
        />
      </Box>
      {children}
    </div>
  );
}

import { Box, Button, Divider } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useCallback } from "react";
import { Toaster } from "react-hot-toast";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { auth } from "../../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { TextField } from "formik-material-ui";
import axios from "../../../axios";
import { MuiPhone } from "../../../components/MuiPhone";
import clsx from "clsx";

export default function SignIn({ modal }: { modal?: boolean }) {
  const validationSchema = Yup.object().shape({
    phone: Yup.string().required("Se requiere teléfono"),
    password: Yup.string()
      .min(8, "La contraseña debe tener al menos 8 caracteres")
      .required("Se requiere contraseña"),
  });
  const navigation = useNavigate();

  const onLogin = useCallback(
    async (values, actions) => {
      try {
        const result = await signInWithEmailAndPassword(
          auth,
          convertPhoneToEmail(values.phone),
          values.password
        );
        const { data } = await axios.post(
          "/api/v1/user/login",
          {},
          {
            headers: {
              Authorization: "Bearer " + (await result.user.getIdToken()),
            },
          }
        );
        localStorage.setItem("user", JSON.stringify(data.user, null, 4));
        let path = "/";
        const currentStore = localStorage.getItem("currentStore");
        if (currentStore) {
          path = `/tiendas/${currentStore}`;
        }
        navigation(path);
        window.location.reload();
      } catch (error: any) {
        console.log(error);
        switch (error.code || error.message) {
          case "auth/wrong-password":
            actions.setFieldError(
              "password",
              "Contraseña incorrecta intente nuevamente"
            );
            break;
          case "auth/user-disabled":
            actions.setFieldError("phone", "La usuario está deshabilitada");
            break;
          case "auth/invalid-email":
            actions.setFieldError("phone", "El teléfono no es válido");
            break;
          case "auth/user-not-found":
            actions.setFieldError("phone", "Usuario no encontrado");
            break;
          case "auth/multi-factor-auth-required":
            actions.setFieldError("phone", "Se requiere mfa");
            break;
          default:
            // toast.error(t(result));
            break;
        }
      }
    },
    [navigation]
  );

  return (
    <div
      className={clsx({
        "w-full": modal,
        "w-[94%] md:w-[35%]": !modal,
      })}
    >
      <Toaster />
      <Box
        marginTop={modal ? undefined : 8}
        className="rounded px-2 py-4"
        sx={{ boxShadow: modal ? undefined : 3 }}
      >
        <h1 className="font-extrabold text-xl mb-2">Ingresar</h1>
        <p className="tw-gray-400 text-xs mb-4">
          Ingresa tu número de teléfono para comenzar.
        </p>
        <Formik
          autocomplete="off"
          initialValues={{
            phone: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onLogin}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                name="phone"
                component={MuiPhone}
                placeholder="Número de Teléfono "
                size="small"
                className="!w-full !mt-4"
              />
              <Field
                name="password"
                placeholder="Contraseña"
                type="password"
                component={TextField}
                size="small"
                className="!w-full !mt-4"
              ></Field>
              <div className="flex items-center mt-2 gap-2">
                <p className="text-sm">¿Olvidaste tu contraseña?</p>
                <Link to="/ForgetPassword" className="text-xs text-[#52ae11]">
                  Restablecer
                </Link>
              </div>
              <Button
                disabled={isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                className="!rounded !text-white mt-4"
              >
                {"Continuar"}
              </Button>
            </Form>
          )}
        </Formik>
        <Divider orientation="horizontal" flexItem />
        <div className="text-center mt-3">
          <p className="text-sm">¿No tienes una cuenta?</p>
          <Link to="/CreateAccount" className="text-xs text-[#52ae11]">
            Regístrate
          </Link>
        </div>
      </Box>
    </div>
  );
}

const convertPhoneToEmail = (phone: string) => {
  return phone?.replace(" ", "").replace("-", "") + "@basimart.com";
};

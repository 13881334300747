import React, { useState } from "react";
import Modal from "react-responsive-modal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useIsMobile from "../../../hooks/useIsMobile";

export default function InfoModal({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();

  return (
    <>
      <button onClick={() => setOpen(true)} className="absolute top-0 right-3">
        <InfoOutlinedIcon color="action" />
      </button>
      <Modal
        styles={{
          modal: {
            width: isMobile ? undefined : 500,
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        center
      >
        <h2 className="text-2xl font-extrabold mb-4">{title}</h2>
        <div className="whitespace-pre-line text-sm mb-4">{content}</div>
        <p className="text-center text-xs px-2">
          Aplican{" "}
          <a href="/" className="text-[#52AE11]">
            términos y condiciones.
          </a>{" "}
          Conoce más{" "}
          <a href="/" className="text-[#52AE11]">
            aquí
          </a>
        </p>
      </Modal>
    </>
  );
}

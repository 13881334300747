import { Box, Grid } from "@mui/material";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import Colors from "../../../constants/Colors";
import MainMenuDrawer from "./MainMenuDrawer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AddressModal from "./AddressModal";
import SignInModal from "./SignInModal";
import CartDrawer from "./CartDrawer";
import MoreIcon from "@mui/icons-material/MoreVert";
import Stories from "../../../pages/stories/Stories";
import StoreInfo from "../../../components/storeInfo/StoreInfo";

export default function StoreHeader({
  store,
  isMobile,
  headerRef,
  updateHeight,
}: any) {
  const navigation = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      {!isMobile && (
        <Grid
          ref={headerRef}
          container
          columns={{ xs: 12, sm: 12, md: 12 }}
          style={Styles.gridContainer as any}
        >
          <Grid style={Styles.menuContainer} item md={0.4} xs={1} xl={2}>
            <MainMenuDrawer />
          </Grid>

          <Grid style={Styles.imgContainer} item md={1.4} xs={2.5} xl={2}>
            <img
              onClick={() => {
                navigation("/");
              }}
              alt="basimart"
              style={{ margin: -30, height: 90 }}
              src={require("../../../assets/basi.png")}
            />
          </Grid>

          <Grid item md={0.7} xs={2.5} xl={2}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => navigation("/")}
            >
              <ArrowBackIosIcon
                style={{
                  fontSize: 12,
                  paddingTop: 1,
                  color: "rgb(114, 118, 126)",
                  cursor: "pointer",
                }}
              />
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "600",
                  cursor: "pointer",
                  color: "rgb(114, 118, 126)",
                }}
              >
                Ver tiendas{" "}
              </div>
            </div>
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F1F1F1",
              borderRadius: 10,
            }}
            item
            md={6}
            xs={4}
            xl={4}
          >
            <input
              className="input"
              type="text"
              id="inputID"
              onChange={(e) => {
                setSearchParams({
                  q: e.target.value,
                });
              }}
              value={searchParams.get("q") || ""}
              style={Styles.inputText2}
              placeholder="Encuentra todos los productos del super"
              onClick={() => {
                const path = "/tiendas/" + store?.storeName + "/search";
                if (!location.pathname.startsWith(path)) {
                  navigation(path);
                }
              }}
            />
            <SearchIcon style={{ paddingRight: 5 }} />
          </Grid>
          <Grid style={Styles.addressContainer} item md={2.5} xs={3} xl={2}>
            {store && <AddressModal store={store} />}
          </Grid>
          <Grid style={{ display: "flex" }} md={0.5} xs={1} item>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <SignInModal isMobile={isMobile} />
              {store && <CartDrawer store={store} />}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <div aria-haspopup="true" color="inherit">
                <MoreIcon />
              </div>
            </Box>
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <Grid
          ref={headerRef}
          style={{
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "white",
            zIndex: 1,
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: 5,
            paddingBottom: 5,
          }}
          container
          xs={12}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 20,
            }}
            item
            xs={4}
          >
            <MainMenuDrawer>
              <StoreInfo store={store} />
            </MainMenuDrawer>
          </Grid>

          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={2}
          >
            <img
              style={{ width: 50, height: 50, borderRadius: "50%" }}
              src={require("../../../assets/basi-short-logo.png")}
              alt=""
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: 20,
            }}
            item
            xs={4}
          >
            {store && <CartDrawer store={store} />}
          </Grid>
          <Grid item xs={12} paddingLeft={2} paddingRight={2}>
            {isMobile && <Stories updateHeight={updateHeight} store={store} />}
          </Grid>
          {location.pathname.startsWith(
            "/tiendas/" + store?.storeName + "/search"
          ) && (
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F1F1F1",
                borderRadius: 10,
              }}
              item
              xs={12}
            >
              <input
                className="input"
                type="text"
                id="inputID"
                onChange={(e) => {
                  setSearchParams({
                    q: e.target.value,
                  });
                }}
                value={searchParams.get("q") || ""}
                style={Styles.inputText2}
                placeholder="Encuentra todos los productos del super"
              />
              <SearchIcon style={{ paddingRight: 5 }} />
            </Grid>
          )}

          <Grid
            xs={12}
            style={{
              margin: 0,
              padding: 0,
              width: "100",
              backgroundColor: "lightgray",
              height: 0.5,
            }}
          />
        </Grid>
      )}
    </>
  );
}

const Styles = {
  mobileLoginText: {
    display: "flex",
    backgroundColor: "#52AE11",
    borderRadius: 24,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 13,
    cursor: "pointer",
    color: "white",
    fontWeight: "600",
  },
  mobileCartContainer: {
    backgroundColor: Colors.lightgray,
    display: "flex",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },
  gridContainer: {
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "white",
    top: 0,
    position: "fixed",
    zIndex: 100,
    borderBottomWidth: 1,
  },
  menuContainer: {
    display: "flex",
    justifyContent: "center",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputText: {
    width: "100%",
    height: 45,
    borderRadius: 5,
    fontSize: 13,
    fontWeight: "600",
    paddingInlineStart: 10,
  },
  inputText2: {
    height: 45,
    borderRadius: 10,
    backgroundColor: "#F1F1F1",
    fontSize: 13,
    fontWeight: "600",
    paddingInlineStart: 10,
    width: "100%",
  },
  addressContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  colorWhite: { color: "black", fontSize: 16, marginTop: -2 },
  webcolorWhite: { color: "black", fontSize: 18, marginTop: -5 },

  webCartContainer: {
    backgroundColor: Colors.lightgray,
    display: "flex",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },

  webLoginText: {
    display: "flex",
    backgroundColor: "#52AE11",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 10,
    cursor: "pointer",
    color: "white",
    marginLeft: -100,
    position: "absolute",
  },
  emptyPersonalCartBody: {
    backgroundColor: "#F6F7F8",
    height: "78vh",
    display: "flex",
    justifyContent: "center",
  },
  personalTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyPersonalCartHeader: {
    height: "11vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  personalText1: { color: "#72767E", fontSize: 17, fontWeight: "600" },
  personalText2: {
    color: "#58B42F",
    fontSize: 17,
    fontWeight: "600",
    cursor: "pointer",
  },
  emptyCheckOut: {
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F6F7F8",
    margin: 5,
    alignItems: "center",
    borderRadius: 10,
  },
  emptyPrice: {
    backgroundColor: "lightgray ",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
  },

  personalCartHeader: {
    height: "10vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    width: "100%",
    position: "sticky",
    borderBottomWidth: 1,
  },
  personalCartBody: {
    backgroundColor: "#F6F7F8",
    height: "77vh",
    overflowY: "scroll",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
  },
  personalCartFooter: {
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#52AE11",
    margin: 5,
    alignItems: "center",
    borderRadius: 10,
    cursor: "pointer",
    position: "absolute",
    width: "96%",
    // bottom: 0,
  },
  storeName: {
    fontSize: 14,
    fontWeight: "600",
    color: "black",
    marginLeft: -35,
  },
  storeAddress: {
    fontSize: 12,
    fontWeight: "600",
    color: "gray",
    marginLeft: -35,
  },
  shopNowContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 50,
  },
  cartTotalPrice: {
    backgroundColor: "#418B24",
    color: "white",
    fontWeight: "600",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
  },
  storeInfoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    backgroundColor: "white",
    padding: 5,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    marginBottom: 10,
  },
  storeImg: { width: 70, height: 70, borderRadius: 50 },
  storeTitle: { fontWeight: "600", color: "black", fontSize: 14 },
  storeDeliveryDate: {
    color: "rgb(10, 173, 10)",
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 2,
  },
  storeGuarantee: {
    fontWeight: "600",
    color: "rgb(43, 120, 198)",
    fontSize: 12,

    cursor: "pointer",
    marginLeft: 2,
  },
  firstDeliveryContainer: {
    display: "flex",
    position: "relative",
    backgroundColor: "rgb(250, 241, 229)",
    marginTop: 10,
  },
  firstDeliveryText: {
    fontSize: 14,
    fontWeight: "600",
    color: " rgb(117, 0, 70)",
    padding: 8,
  },
  product: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    padding: 5,
  },
  productImg: { width: 80, height: 55 },
  productTitle: {
    color: "rgb(52, 53, 56)",
    width: 230,
    whiteSpace: "pre-line",
    fontSize: 13,
  },
  productDeliverDate: {
    color: "rgb(10, 173, 10)",
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 2,
  },
  productRemove: {
    cursor: "pointer",
    marginLeft: 2,
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  customOverlay: {
    // background: "rgba(36, 123, 160, 0.7)"
    borderRadius: 10,
  },
  customModal: {
    borderRadius: 10,
    width: 340,
  },
  customModalSignIn: {
    borderRadius: 10,
    minWidth: "30%",
  },
};

import { Box, Grid } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";

import AuthContext from "../../context/AuthContext";
import { Product } from "../../types";
import ProductDetailModal from "../../components/ProductDetailModal";
import useScreenSize from "../../hooks/useScreenSize";
//@ts-ignore
import { productsStyle } from "../../components/style/productsStyle";
//@ts-ignore
import Image from "../../components/ImageComponent";
import axios from "../../axios";
import ScreenLoader from "../../components/screenLoader/ScreenLoader";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Explorer({ store }: any) {
  const { isMobile } = useScreenSize();
  const { user } = useContext(AuthContext);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [params] = useSearchParams();
  const viewProductId = params.get("product");
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();

  const getProducts = useCallback(
    async (skip = 0) => {
      if (!store) return;

      try {
        const { data } = await axios.post("/api/v1/products/explore", {
          userId: user?._id,
          vendorId: store?.storeName,
          skip,
          limit: 18,
        });
        if (!data?.products?.length) {
          setHasMore(false);
          return;
        }
        setProducts((prev) => [...prev, ...data.products]);
        setPage((prev) => prev + 1);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    },
    [store, user?._id]
  );

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (!viewProductId) return;
    axios
      .post(`/api/v1/vendor/product`, {
        data: {
          storeName: store?.storeName,
          product: viewProductId,
        },
      })
      .then(({ data }) => {
        setSelectedProduct(data.product);
      });
  }, [viewProductId, store?.storeName]);

  if (!store) return null;

  if (loading) {
    return <ScreenLoader />;
  }

  return (
    <Grid item md={10} lg={10} sm={12} xs={12}>
      <Box sx={{ marginTop: 1 }} style={{ marginLeft: 10, marginRight: 10 }}>
        <InfiniteScroll
          style={{ overflowX: "hidden" }}
          dataLength={products?.length} //This is important field to render the next data
          next={() => {
            getProducts(page * 18);
          }}
          hasMore={hasMore}
          loader={
            <div style={{ marginTop: 4, textAlign: "center" }}>
              <h4>Cargando...</h4>
            </div>
          }
        >
          <Grid
            style={{
              display: "flex",
            }}
            columnSpacing={{ xs: 1 }}
            container
          >
            {products?.map((product, index) => {
              return (
                <Grid md={2} sm={8} xs={6} item>
                  <Image
                    currentItem={product}
                    storeId={store._id}
                    handleOpen={() => setSelectedProduct(product)}
                    MobileUi={isMobile}
                  />
                  {product?.price && (
                    <div style={{ fontWeight: "600" }}>₡{product?.price}</div>
                  )}
                  <div style={productsStyle.title}>{product?.name}</div>
                  <div style={productsStyle.size}>{product?.size}</div>
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      </Box>
      {selectedProduct && (
        <ProductDetailModal
          storeId={store?._id}
          currentItem={selectedProduct}
          open={true}
          onClose={() => setSelectedProduct(undefined)}
        />
      )}
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import { commafy } from "../utils/price";
import Rating from "@mui/material/Rating";
import axios from "../axios";

import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import { Button } from "react-bootstrap";

export default function OrderTableComponent({ buyer }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [isMobile, setIsMobile] = useState(getMobile());

  function getMobile() {
    const body = document.querySelector("body");
    const width = parseFloat(window.getComputedStyle(body).width);
    if (width > 900) {
      return false;
    } else {
      return true;
    }
  }

  window.addEventListener("resize", () => {
    setIsMobile(getMobile());
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const navigate = useNavigate();
  const navigateToDetail = (row) => {
    navigate("/UserOrderDetails", {
      state: {
        row: row,
      },
    });
  };

  function handleStatus(status) {
    if (status === "Confirmando Pedido") return "Confirmando";
    if (status === "Pedido Confirmado") return "Confirmado";
    if (status === "Procesando Pedido") return "Procesando";
    if (status === "Pedido Entregado") return "Entregado";
    if (status === "Cancelada") return "Cancelado";
    return status;
  }

  const [value, setValue] = React.useState(0);
  const [rate, setRate] = useState(false);

  const handleRate = (newValue, id, vendor) => {
    updateRate(newValue, id, vendor);
  };

  const updateRate = async (value, id, vendor) => {
    axios
      .put(`/api/v1/rateOrders/${id}`, { rate: value, vendorId: vendor })
      .then((res) => {
        setValue(res.data.rating);
      });
  };

  const styles = {
    tableHeading: { background: "#529f6f", height: "1.5px", width: "100%" },

    mdTable: {
      background: "white",
      borderSpacing: "45px 10px",
      borderCollapse: "separate",
    },
    mdTableMobile: {
      background: "white",
      borderSpacing: "20px 8px",
      borderCollapse: "separate",
      marginTop: "-65px",
    },

    tableBody: {
      color: "black",
      fontSize: 12,
      textAlign: "center",
      margin: "auto",
    },
    tableTxt: {
      fontSize: 13,
      color: "black",
      padding: "2px",
      margin: "auto",
      textAlign: "center",
      fontWeight: "200",
    },
    valueTxt: {
      fontSize: 12,
      color: "#222",
    },
    hightLightTxt: {
      fontSize: 12,
      color: "#AD6D49",
      margin: 0,
    },

    tittle: {
      color: "#4B566B",
      fontSize: 15,
      fontWeight: "bold",
      paddingLeft: "10px",
      marginTop: "20px",
    },

    tittleMobile: {
      color: "#4B566B",
      fontSize: 15,
      fontWeight: "bold",
      paddingLeft: "3px",
      marginTop: "10px",
    },

    subTittle: {
      color: "#4B566B",
      fontSize: 13,
      fontWeight: "bold",
      paddingLeft: "10px",
    },

    confirmando: {
      width: 76,
      height: 17,
      borderRadius: 5,
      backgroundColor: "#eae7f0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 11,
      padding: 15,
      textAlign: "center",
      fontWeight: "bold",
      color: "#423363",
    },
    container: {
      background: "#f2f2f4",
      width: "100%",
      borderRadius: 8,
      borderWidth: 2,
      borderColor: "white",
      padding: "10px",
      margin: "5px",
      height: "175px",
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="w-1/2 mb-5">
        <h1 style={{ fontWeight: "bold", fontSize: 20, margin: "5px" }}>
          Tus Órdenes
        </h1>
        <div style={styles.tableHeading}></div>
      </div>

      {!isMobile && (
        <MDBTable responsive style={styles.mdTable}>
          <MDBTableHead>
            <tr>
              <th>{"Orden Nro"}</th>
              <th>{"Fecha"}</th>
              <th>{"Logo"}</th>
              <th>{"Tienda"}</th>
              <th>{"Estado"}</th>
              <th>{"Calificación"}</th>
              <th>{"Total"}</th>
            </tr>
          </MDBTableHead>
          {buyer?.buyerOrderDetails
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row) => {
              return (
                <MDBTableBody>
                  <tr
                    style={{
                      marginTop: "10px",
                      marginButton: "10px",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row?._id}
                  >
                    <th
                      scope="row"
                      onClick={() => {
                        navigateToDetail(row);
                      }}
                      style={styles.tableTxt}
                    >
                      {row?._id}
                    </th>
                    <th style={styles.tableTxt}>
                      <div>{new Date(row?.purchaseDate).toLocaleString()}</div>
                    </th>
                    <th>
                      <img
                        style={{ width: 50, height: 50 }}
                        src={row?.vendorStoreImg}
                      />
                    </th>
                    <th style={styles.tableTxt}>{row?.storeName}</th>
                    {row?.status === "Confirmando Pedido" && (
                      <>
                        <th style={styles.tableTxt}>
                          <div
                            style={{
                              width: 90,
                              height: 28,
                              borderRadius: 20,
                              backgroundColor: "#CED7DF",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              padding: 20,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        </th>
                        <th></th>
                      </>
                    )}
                    {row?.status === "Pedido Confirmado" && (
                      <>
                        <th style={styles.tableTxt}>
                          <div
                            style={{
                              width: 90,
                              height: 28,
                              borderRadius: 20,
                              backgroundColor: "#E0F7E9",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              padding: 20,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        </th>
                        <th></th>
                      </>
                    )}
                    {row?.status === "Procesando Pedido" && (
                      <>
                        <th style={styles.tableTxt}>
                          <div
                            style={{
                              width: 90,
                              height: 28,
                              borderRadius: 20,
                              backgroundColor: "#E0F7E9",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              color: "#65D068",
                              padding: 20,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        </th>
                        <th></th>
                      </>
                    )}
                    {row?.status === "Pedido Entregado" && (
                      <>
                        <th style={styles.tableTxt}>
                          <div
                            style={{
                              width: 90,
                              height: 28,
                              borderRadius: 20,
                              backgroundColor: "#E0F7E9",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              color: "#65D068",
                              padding: 8,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        </th>
                        <th
                          style={{
                            cursor: row?.rate ? "not-allowed" : "pointer",
                          }}
                        >
                          <div>
                            {row?.rate === false && (
                              <Rating
                                name="simple-controlled"
                                size="small"
                                value={value}
                                onChange={(event, newValue) => {
                                  handleRate(newValue, row?._id, row?.vendorId);
                                }}
                              />
                            )}

                            {row?.rate === true && (
                              <Rating
                                name="simple-controlled"
                                size="small"
                                value={row?.rating}
                                readOnly
                              />
                            )}
                          </div>
                        </th>
                      </>
                    )}
                    {row?.status === "Cancelada" && (
                      <>
                        <th style={styles.tableTxt}>
                          <div
                            style={{
                              width: 90,
                              height: 28,
                              borderRadius: 20,
                              backgroundColor: "#F9E1E6",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              color: "#E74460",
                              padding: 8,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        </th>
                        <th></th>
                      </>
                    )}
                    <th style={styles.tableTxt}>₡{commafy(row?.totalPrice)}</th>
                  </tr>
                </MDBTableBody>
              );
            })}
        </MDBTable>
      )}

      {isMobile && (
        <>
          {buyer?.buyerOrderDetails
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row) => {
              return (
                <div style={styles.container}>
                  <Grid container xs={12}>
                    <Grid xs={8}>
                      Orden Id #
                      <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {row?._id}{" "}
                      </p>
                    </Grid>

                    <Grid xs={4}>
                      <>
                        {row?.status === "Confirmando Pedido" && (
                          <div
                            style={{
                              width: 90,
                              height: 24,
                              borderRadius: 5,
                              backgroundColor: "#CED7DF",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              padding: 13,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        )}

                        {row?.status === "Pedido Confirmado" && (
                          <div
                            style={{
                              width: 90,
                              height: 24,
                              borderRadius: 5,
                              backgroundColor: "#E0F7E9",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                              padding: 13,
                              textAlign: "center",
                            }}
                          >
                            {handleStatus(row?.status)}
                          </div>
                        )}

                        {row?.status === "Procesando Pedido" && (
                          <th>
                            <div
                              style={{
                                width: 90,
                                height: 24,
                                borderRadius: 5,
                                backgroundColor: "#E0F7E9",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 11,
                                color: "#65D068",
                                padding: 13,
                                textAlign: "center",
                              }}
                            >
                              {handleStatus(row?.status)}
                            </div>
                          </th>
                        )}

                        {row?.status === "Pedido Entregado" && (
                          <th>
                            <div
                              style={{
                                width: 90,
                                height: 24,
                                borderRadius: 5,
                                backgroundColor: "#E0F7E9",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 11,
                                color: "#65D068",
                                padding: 13,
                                textAlign: "center",
                              }}
                            >
                              {handleStatus(row?.status)}
                            </div>
                          </th>
                        )}

                        {row?.status === "Cancelada" && (
                          <th>
                            <div
                              style={{
                                width: 90,
                                height: 24,
                                borderRadius: 5,
                                backgroundColor: "#F9E1E6",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 11,
                                color: "#E74460",
                              }}
                            >
                              {handleStatus(row?.status)}
                            </div>
                          </th>
                        )}
                      </>
                    </Grid>
                  </Grid>

                  <Grid container xs={12}>
                    <p style={{ fontSize: 11, paddingTop: "16px" }}>
                      {" "}
                      Fecha de Compra:
                    </p>
                    <p
                      style={{
                        fontSize: 11,
                        paddingTop: "16px",
                        fontWeight: "bold",
                        paddingLeft: "6px",
                      }}
                    >
                      {new Date(row?.purchaseDate).toLocaleString()}
                    </p>
                  </Grid>

                  <Grid container xs={12}>
                    <Grid
                      xs={6}
                      style={{ display: "flex", alignItems: "stretch" }}
                    >
                      <p style={{ fontSize: 11, paddingTop: "5px" }}>
                        {" "}
                        Tienda:
                      </p>
                      <p
                        style={{
                          fontSize: 11,
                          paddingTop: "5px",
                          fontWeight: "bold",
                          paddingLeft: "6px",
                        }}
                      >
                        {row?.storeName}
                      </p>
                    </Grid>

                    <Grid
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "stretch",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 11,
                          paddingTop: "5px",
                          paddingLeft: "20px",
                        }}
                      >
                        {" "}
                        Total:
                      </p>
                      <p
                        style={{
                          fontSize: 11,
                          paddingTop: "5px",
                          fontWeight: "bold",
                          paddingLeft: "6px",
                        }}
                      >
                        ₡{commafy(row?.totalPrice)}
                      </p>
                    </Grid>
                  </Grid>
                  {row?.status === "Pedido Entregado" && (
                    <Grid container xs={12}>
                      <Grid
                        xs={6}
                        style={{ display: "flex", alignItems: "stretch" }}
                      >
                        <p
                          style={{
                            fontSize: 11,
                            paddingRight: 10,
                          }}
                        >
                          Calificación:
                        </p>
                        {row?.rate === false && (
                          <Rating
                            name="simple-controlled"
                            size="small"
                            value={value}
                            onChange={(event, newValue) => {
                              handleRate(newValue, row?._id, row?.vendorId);
                            }}
                          />
                        )}

                        {row?.rate === true && (
                          <Rating
                            name="simple-controlled"
                            size="small"
                            value={row?.rating}
                            readOnly
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid xs={4}>
                    <Button
                      style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        background: "#003D28",
                        color: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        navigateToDetail(row);
                      }}
                    >
                      {" "}
                      Ver Detalles{" "}
                    </Button>
                  </Grid>
                </div>
              );
            })}
        </>
      )}

      <div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={buyer?.buyerOrderDetails?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

const colors = {
  darkBlue: "#212240",
  skyBlue: "#377ef9",
  lightGreen: "#27634C",
  white: "#FFFFFF",
  lightBlack: "#343538",
  lightgray: "#F6F7F8",
  seeGreen: "#EAF5EB",
  seaGreen: "rgb(69, 206, 69)",
  buttonGreen: "#4c9f6f",
  reaBtnClr: "#52AE11",
};

export default colors;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfigTest = {
  apiKey: "AIzaSyCZly9jWprj-7CQmevZ0VkiJWuywnuAnbc",
  authDomain: "basimart-8e5d7.firebaseapp.com",
  projectId: "basimart-8e5d7",
  storageBucket: "basimart-8e5d7.appspot.com",
  messagingSenderId: "936026815932",
  appId: "1:936026815932:web:78030cf22bb6af5135ffb9",
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const firebaseConfigProduction = {
  apiKey: "AIzaSyAAYF14n_kVL553yyQbUzkGrfcS8r_95Rg",
  authDomain: "basi-production.firebaseapp.com",
  projectId: "basi-production",
  storageBucket: "basi-production.appspot.com",
  messagingSenderId: "1009157347954",
  appId: "1:1009157347954:web:24601f4136fce4176b80eb",
};

const app = initializeApp(firebaseConfigTest);

export const auth = getAuth(app);

export const checkOutStyle = {
    order: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      },
    subtitle: {
        fontSize: 16,
        fontWeight: "700",
        color: "#424242",
        paddingTop: 40,
      },
    continue: {
        backgroundColor: "#3E844D",
        cursor: "pointer",
        fontSize: 13,
        fontWeight: "700",
        color: "white",
        borderRadius: 5,
        padding: 8,
        paddingLeft: 12,
        paddingRight: 12,
        marginTop: 20,
      },
    view: {
        backgroundColor: "#EFA71C",
        cursor: "pointer",
        fontSize: 14,
        fontWeight: "700",
        color: "white",
        marginBottom: 40,
        borderRadius: 5,
        padding: 8,
        paddingLeft: 12,
        paddingRight: 12,
        marginTop: 20,
      },
    grid: {
        backgroundColor: "white",
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          width: "100%",
          zIndex: 1,
    },
    gridCenter: {
        display: "flex",
        justifyContent: "center",
        paddingTop: 80,
      },
    divCenter: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    div2Center: {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        alignSelf: "center",
      },
    placeIcon: {
      color: "green",
      fontSize: 30,
      marginRight: 10,
    },
    textField: {
      width: "80%",
      marginBottom: 10,
      
    },
    textError: {
      color: "red",
      fontWeight: "600",
      fontSize: 14,
    },
    textFieldBorder: {
      borderWidth: 1,
      width: "80%",
      marginBottom: 10,
    },
    textFieldGreen: {
      borderWidth: 1,
      color: "green",
      borderColor: "black",
      width: "80%",
      marginBottom: 10,
    },
    zipCode:{
      borderWidth: 1,
      borderColor: "black",
      width: "27%",
      marginBottom: 10,
    },
    continueButton: {
      cursor: "pointer",
      backgroundColor: "#52AE11",
      width: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 10,
      padding: 10,
      color: "white",
    },
    step: {
      display: "flex",
      alignItems: "center",
      borderBottomWidth: 1,
      padding: 20,
      cursor: "pointer",
    },
    placeIconGray: {
      color: "#72767E",
      fontSize: 23,
      marginRight: 10,
    },
    placeIconGray2: {
      fontSize: 15,
      fontWeight: "600",
      color: "#72767E",
    },
    delivery: {
      display: "flex",
      justifyContent: "center",
      width: "82.5%",    
      alignItems: "center",
      padding: 8,
      borderRadius: 5,
      borderWidth: 1,
      cursor: "pointer",
      fontWeight: "600",
    },
    stepper: {
      display: "flex",
      alignItems: "center",
      marginBottom: 20,
    },
    payInstruction: {
      width: "82.5%",
      height: "100%",
      border: "1px solid #ccc",
      borderRadius: "6px",
      padding: 15,
      justifyContent: "center",
    },
    storeImage: {
      display: "flex",
      width: 250,
      height: 100,
      alignSelf: "center",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      justifySelf: "center",
      border: "1px solid #ccc",
    }
  };
  
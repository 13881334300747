import { Grid } from "@mui/material";
import React from "react";
import Image from "./ImageComponent";
import "./style/Navbar.css";
import ProductDetailModal from "./ProductDetailModal";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "../axios";
import { useSelector } from "react-redux";
import { productsStyle } from "./style/productsStyle";

const pluckUnique = (array, key) => {
  return array
    .map((item) => item[key])
    .filter((value, index, self) => self.indexOf(value) === index);
};

const ALL_SUBCATEGORY = "Todo";

const AllCategoryComponent = ({ store, productData1, subCategories }) => {
  const [productData, setProductData] = useState(productData1);
  const [pageNumber, setPageNumber] = useState(1);
  const [modalItem, setModalItem] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [isMoreAvailable, setIsMoreAvailable] = useState(true);

  const [hasSubCategory, setHasSubCategory] = useState(false);
  const [subCategory, setSubCategory] = useState(ALL_SUBCATEGORY);

  const filters = useSelector((state) => state.storeProduct.filters);

  const isSearch = filters.filterType === "search";

  let limit = 21;

  const setModalOpen = (value) => {
    setModalItem(value);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleClick = async (name) => {
    setHasSubCategory(name !== ALL_SUBCATEGORY);
    setSubCategory(name);

    const response = await axios.post("/api/v1/product/getVendorProduct", {
      data: {
        vendorId: filters.vendorId,
        filterType:
          name === ALL_SUBCATEGORY ? filters.filterType : "subCategory",
        filterValue: name === ALL_SUBCATEGORY ? filters.filterValue : name,
      },
    });

    if (response.data.products !== 0) {
      setProductData(response.data.products);
      setPageNumber(1);
    } else {
      setIsMoreAvailable(false);
    }
  };

  const fetchMore = async () => {
    const response = await axios.post("/api/v1/product/getVendorProduct", {
      data: {
        vendorId: filters.vendorId,
        filterType: hasSubCategory ? "subCategory" : filters.filterType,
        filterValue: hasSubCategory ? subCategory : filters.filterValue,
        skip: pageNumber * limit,
        limit: limit,
      },
    });

    if (response.data.products !== 0) {
      let newOne = [].concat(productData, response.data.products);
      setProductData(newOne);
      setPageNumber(pageNumber + 1);
    } else {
      setIsMoreAvailable(false);
    }
  };

  return (
    <>
      {isSearch ? (
        <div
          style={{
            fontSize: 18,
            fontWeight: "600",
            marginBottom: 10,
          }}
        >
          Resultados para {filters.filterValue && `"${filters.filterValue}"`}
        </div>
      ) : (
        <>
          {/*<div*/}
          {/*  style={{*/}
          {/*    // marginLeft: 30,*/}
          {/*    fontSize: 18,*/}
          {/*    fontWeight: "600",*/}
          {/*    marginTop: 40,*/}
          {/*    marginBottom: 10,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {productData1?.[0]?.aisle}*/}
          {/*</div>*/}

          <div
            style={{
              // marginLeft: 30,
              fontSize: 18,
              fontWeight: "600",
              marginTop: 40,
              marginBottom: 10,
            }}
          >
            {productData1?.[0]?.category}
          </div>

          <div
            style={{
              // marginLeft: 20,
              display: "flex",
              alignItems: "center",
              marginBottom: 10,
              overflow: "auto",
              width: "95vw",
            }}
          >
            {[ALL_SUBCATEGORY].concat(subCategories).map((subCategoryName) => (
              <div
                key={subCategoryName}
                onClick={() => handleClick(subCategoryName)}
                style={{
                  marginRight: 15,
                  backgroundColor: "#EFF0F1",
                  fontWeight: "600",
                  borderColor:
                    subCategory === subCategoryName ? "black" : "white",
                  borderWidth: subCategory === subCategoryName ? 2 : 0,
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 15,
                  paddingRight: 15,
                  borderRadius: 15,
                  color: "#434343",
                  cursor: "pointer",
                  flexShrink: 0,
                }}
              >
                {subCategoryName}
              </div>
            ))}
          </div>
        </>
      )}

      {productData && (
        <>
          <InfiniteScroll
            dataLength={productData?.length} //This is important field to render the next data
            next={fetchMore}
            hasMore={isMoreAvailable}
            loader={null}
            // loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <Grid
              style={{
                display: "flex",
              }}
              columnSpacing={{ xs: 1 }}
              container
            >
              {productData &&
                productData?.map((product, index) => {
                  return (
                    <Grid
                      key={product.name}
                      item
                      // style={{ }}
                      md={2}
                      xs={6}
                    >
                      <Image
                        currentItem={product}
                        storeId={store?.item?._id}
                        handleOpen={() => setModalOpen(product)}
                      />
                      {product.price && (
                        <div style={{ fontWeight: "600" }}>
                          ₡{product.price}
                        </div>
                      )}
                      <div style={Styles.title}>{product.name}</div>
                      <div style={Styles.size}>{product.size}</div>
                    </Grid>
                  );
                })}
            </Grid>
          </InfiniteScroll>
          <div>
            {!!modalItem && (
              <ProductDetailModal
                storeId={store?.item?._id}
                storeName={store?.item?.storeName}
                currentItem={modalItem}
                open={open}
                onClose={handleClose}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AllCategoryComponent;
const Styles = {
  GridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  freshProduct: {
    marginLeft: 30,
    fontSize: 18,
    fontWeight: "600",
    marginTop: 40,
  },
  paginationContainer: { display: "flex", alignItems: "center" },
  title: {
    // width: 190,
    fontSize: 14,
    color: " rgb(52, 53, 56",
  },
  size: {
    // width: 190,
    fontSize: 13,
    color: "#606163",
  },
};

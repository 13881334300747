import React, { FC, useEffect, useState } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { Product, TeamDealStatus } from "../types";
import axios from "../axios";
import { DealCountdown } from "../components/DealCountdown";
import ShareProduct from "../components/ShareProduct";

type Props = {
  product: Product & { id: string };
  onRemovePromo: (product: string) => void;
};

const TeamPurchaseProductCard: FC<Props> = ({ product, onRemovePromo }) => {
  const [status, setStatus] = useState<TeamDealStatus>();

  useEffect(() => {
    if (!product.dealStatus?._id) {
      return;
    }
    axios
      .get(`/api/v1/team-purchase/${product.dealStatus._id}`)
      .then(({ data }) => {
        const d = data.teamPurchase as TeamDealStatus;
        if (d && d.teamsQuantity <= d.currentTeam) {
          onRemovePromo(product.id);
        }
        setStatus(d);
      });
  }, [product.id, onRemovePromo, product.dealStatus?._id]);

  return (
    <Box pt={1} pb={1} mb={1}>
      <Box display="flex" alignItems="center">
        <img
          style={{ width: 56, height: 56, objectFit: "contain" }}
          src={product.images}
          alt="product"
        />
        <Box ml={1}>
          <Typography variant="body2" fontSize={13}>
            {product.name}
          </Typography>
          <Typography variant="body2" fontSize={11} color="rgb(96, 97, 99)">
            {product.size}
          </Typography>
          {!!status && (
            <DealCountdown
              expirationDate={status.expirationDate}
              style={{ fontSize: 11 }}
              slotStyle={{ fontSize: 11 }}
            />
          )}
        </Box>
        <Box alignSelf="flex-start">
          <ShareProduct productId={product._id} size={15} />
        </Box>
      </Box>
      {!!status && status.teamsQuantity > status.currentTeam && (
        <Box pl={1} pr={1}>
          <Typography gutterBottom variant="body2" fontSize={13}>
            Compradores ({status.teams[status.currentTeam].length} de{" "}
            {status.teamSize})
          </Typography>
          <LinearProgress
            variant="determinate"
            value={
              (status.teams[status.currentTeam].length / status.teamSize) * 100
            }
          />
        </Box>
      )}
      {!!status && status.teamsQuantity <= status.currentTeam && (
        <Box pl={1} pr={1}>
          <Typography variant="body2" fontSize={13} color="red">
            Completada!
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TeamPurchaseProductCard;

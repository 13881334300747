import {Suspense, useEffect, useRef, useState} from "react";
import SimpleBar from "simplebar-react";
import List from "@mui/material/List";
import {Skeleton} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import SubCategoriesComponent from '../../SubCategoriesComponent'
import "simplebar/src/simplebar.css";

const DecideDrawerCategoriesScreen = ({isMobile, isLoading, children}) => {

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) setHeight(ref.current?.clientHeight);
    }, 1000);
  }, [isMobile, isLoading]);


  if (isMobile) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          paddingBottom: 20,
          overflowY: "scroll",
        }}
      >
        {children}
      </div>
    )
  }

  return (
    <div ref={ref} style={{flexGrow: 1}}>
      <SimpleBar
        style={{
          maxHeight: height,
          scrollbarColor: "#343538",
        }}
      >
        {children}
      </SimpleBar>
    </div>
  )
}

const DrawerCategories = ({isMobile}) => {

  const {data, isSuccess} = useSelector((state) => state.store.data);
  const {isLoading} = useSelector((state) => state.store);


  return (
    <DecideDrawerCategoriesScreen
      isLoading={isLoading}
      isMobile={isMobile}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {!isLoading && (
          <Suspense fallback={<h1>Loading profile...</h1>}>
            {data?.d.map((item, i) => (
              <SubCategoriesComponent
                key={i}
                item={item}
              />
            ))}
          </Suspense>
        )}
        {isLoading && (
          <>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((e) => (
              <Skeleton
                key={e}
                variant="rectangular"
                width={200}
                height={20}
                style={{marginTop: 10}}
              />
            ))}
          </>
        )}
      </List>
    </DecideDrawerCategoriesScreen>
  )
}

export default DrawerCategories
